import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { BookingWidgetModeKind } from "../../BookingTemplate/BookingTemplateEntities";
import { BookingFormKind, UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import { CalculateIsBookingOnAccount } from "../NewBookingForm";
import DeliveryOptions from "../Parcel/DeliveryOptions";
import { BookingFormStep } from "../FormSteps/BookingFormStep";

/**
 * Possibly renders the Delivery Option selector in the booking form.
 * It only appears in parcel mode and for account bookings.
 */
const MaybeDeliveryOptionsCore: React.FC<ReduxProps> = (props) => {

    // rendering conditions
    if (!props.IsBookingOnAccount) return null;
    if (props.BookingParcelMode !== BookingFormKind.ParcelBooking) return null;

    // intentionally not supported for templates; the user should decide for each booking
    if (props.BookingTemplateMode !== BookingWidgetModeKind.Booking) return null;

    return (
        <BookingFormStep Label={FormStepLabel.DeliveryOptions}>
            <DeliveryOptions />
        </BookingFormStep>
    );
}

// #region Redux

interface ReduxProps {

    /** Whether the user has toggled the "Book on Account" option on. */
    IsBookingOnAccount: boolean;

    /** Whether the user is editing a booking or just a booking template. */
    BookingTemplateMode: BookingWidgetModeKind;

    /** Whether the booking form is in parcel (detailed) or normal (simple) mode. */
    BookingParcelMode: BookingFormKind;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        IsBookingOnAccount: CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile),
        BookingTemplateMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        BookingParcelMode: state.uiLogicControl.BookingForm.ActiveBookingForm,
    };
}

export const MaybeDeliveryOptions = connect(GetPropsFromRedux)(MaybeDeliveryOptionsCore);

// #endregion