import React from 'react';
import { connect } from "react-redux";
import { ApplicationState } from '../../appState';
import "./HeaderLoginRegister.scss";
import { LoginStatusKind } from "../../modules/Authentication/AuthEntities";
import { ContentURL, getContentUrl } from '../../modules/Utils/ContentURL';
import { Dispatch } from '../../modules/Dispatch';
import { SimpleUserProfile } from '../../modules/User/ProfileEntitiesV2';
import CredentialsController from '../../modules/Authentication/Login/CredentialsController';
import { LogEvent } from '../../utils/LogEvent';
import { FeatureFlags } from '../../Config/FeatureFlags';
import { UILayoutMode } from '../../modules/UILogicControl/UILogicControlEntities';
import { ClickLoginButton } from './SiteHeader';
import { DialogKind } from '../../modules/Dialog/DialogEntities';
import { AccountInfo } from '@azure/msal-common';

interface AuthenticationProps {
    LoginStatus: LoginStatusKind;
    UserProfile: SimpleUserProfile | undefined;
    IsProfilePanelShown: boolean;
    UnderMobileMode: boolean;

    /** Logged in account using Azure B2C */
    AccountV2: AccountInfo | null;
}

class HeaderLoginRegister extends React.Component<AuthenticationProps> {

    /**
     * Trigger sign-up popup.
     * Parameter is only used for old Auth0 Lock compatability
     */
    signUp = () => { 
        
        Dispatch.Payment.HideAddPaymentCardScreenAfterSignup();

        if (FeatureFlags.MultiTenantLogin) {
            Dispatch.Dialog.ShowDialog(DialogKind.MultiTenantSelector);
            return;
        }

        LogEvent.RegisterBySignupButton();
        new CredentialsController().DoSignup();
    }

    toggleIsProfilePanelShown = () => {
        if (this.props.IsProfilePanelShown) Dispatch.Auth.HideProfilePanel();
        else Dispatch.Auth.ShowProfilePanel();
    }

    render() {

        if (this.props.UnderMobileMode) return null;

        const userProfile = this.props.UserProfile;
        var panelStyle = "header-login-status-panel", arrow = getContentUrl(ContentURL.images.arrows.arrowDownWhite);
    
        if (this.props.LoginStatus === LoginStatusKind.LoggedOut) {            
            panelStyle += " header-login-status-panel-width-profile header-login-status-panel-bg-color-floral-white";
        }
        else if (this.props.LoginStatus === LoginStatusKind.GetUserProfileInProgress) {
            panelStyle += " header-login-status-panel-width-loading header-login-status-panel-bg-color-brand";
        }
        else if (this.props.LoginStatus === LoginStatusKind.LoggedIn) {
            
            
            arrow = this.props.IsProfilePanelShown ? getContentUrl(ContentURL.images.arrows.arrowUpWhite) : getContentUrl(ContentURL.images.arrows.arrowDownWhite);
            if ((userProfile != null) || FeatureFlags.AuthV2) {
                panelStyle += " header-login-status-panel-width-profile header-login-status-panel-bg-color-brand";
            }
        }

        const logInBtnClass = FeatureFlags.NoSignup ? "login-register-box sign-up-btn" : "login-register-box log-in-btn";

        return (
            <div className={panelStyle}>
                {this.props.LoginStatus === LoginStatusKind.LoggedOut && 
                    <div className="login-status-logout-container">
                        <div className={logInBtnClass} onClick={ClickLoginButton}><span>Log in</span></div>
                        {!FeatureFlags.NoSignup && <div className="login-register-box sign-up-btn" onClick={() => { this.signUp() }}><span>Sign up</span></div>}
                    </div>
                }
                {this.props.LoginStatus === LoginStatusKind.GetUserProfileInProgress && 
                    <div className="login-status-loading-container">
                        <span>Loading profile</span>
                        <img src={getContentUrl(ContentURL.images.Loading)} width="35px" height="auto" />
                    </div>
                }
                {this.props.LoginStatus === LoginStatusKind.LoggedIn && 
                    <div className="login-status-profile-container" onClick={this.toggleIsProfilePanelShown}>
                    <span>{this.GetLoginDisplayName()}</span>
                        <img src={arrow} width="18px" height="auto" />
                    </div>
                }
            </div>  
        );
    }

    GetLoginDisplayName(): string {
        if (this.props.UserProfile) {
            return this.props.UserProfile.DisplayName;
        }
        else if (this.props.AccountV2) {
            return `${this.props.AccountV2.username} (${this.props.AccountV2.name})`;
        }

        return "??";
    }
}

function mapStateToProps(state: ApplicationState): AuthenticationProps {
    return {
        LoginStatus: state.authentication.LoginStatus,
        UserProfile: state.authentication.UserProfile,
        IsProfilePanelShown: state.authentication.IsProfilePanelShown,
        UnderMobileMode: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        AccountV2: state.auth2.Account,
    };
}

export default connect(mapStateToProps)(HeaderLoginRegister);