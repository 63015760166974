import React, { Fragment } from "react";
import { connect } from "react-redux";
import StepControl from "./FormSteps/StepControl";
import BookingDetails from "./BookingDetails";
import { ApplicationState } from "../../appState";
import { LoginStatusKind } from '../Authentication/AuthEntities';
import { BookingFavouriteAddress } from './BookingEntities';
import { FareEstimateSnackbar } from "./Widget/FareEstimateSnackbar";
import { Dispatch } from "../Dispatch";
import { FeatureFlags } from '../../Config/FeatureFlags';
import { SnackbarForPriceGuaranteeTip } from "./Widget/SnackbarForPriceGuaranteeTip";
import { DriverNotesSection } from "./Widget/DriverNotesSection";
import { BookingLocationContactName } from "./Widget/BookingLocationContactName";
import { BookingLocationContactNumber } from "./Widget/BookingLocationContactNumber";
import { ContactKind } from "../BookingTemplate/BookingTemplateEntities";
import { MaybeAccountDetails } from "./OptionalParts/MaybeAccountDetails";
import { MaybeCardSelector } from "./OptionalParts/MaybeCardSelector";
import { FormStepLabel } from "./FormSteps/FormStepLabel";

interface NewBookingFormProps {
    FavouriteAddress: BookingFavouriteAddress;
}

/** Renders part of the booking form inputs on desktop devices. */
class NewBookingForm extends React.Component<NewBookingFormProps> {
    
    componentWillUnmount() {
        Dispatch.UILogicControl.HideFareEstimateMessage();
    }

    render() {                      

        return(
            <Fragment>
                <MaybeAccountDetails />
                
                <StepControl StepKind={FormStepLabel.BookingDetails} /> 
                <BookingDetails />
                
                <StepControl StepKind={FormStepLabel.ContactDetails} /> 
                <BookingLocationContactName ContactType={ContactKind.PickUp} />
                <BookingLocationContactNumber ContactType={ContactKind.PickUp}  />

                <StepControl StepKind={FormStepLabel.DriverInstruction} />
                <DriverNotesSection {...this.props.FavouriteAddress} />
                
                <MaybeCardSelector />
                <FareEstimateSnackbar />
                <SnackbarForPriceGuaranteeTip />
            </Fragment>    
        )
    }
}

function mapStateToProps(state: ApplicationState): NewBookingFormProps {
    return {
        FavouriteAddress: state.booking.FavouriteAddress
    }  
}

/**
 * Calculate if the booking is on account:
 *   1) Logged in;
 *   2) Account toggle is on.
 */
export function CalculateIsBookingOnAccount(loginStatus: LoginStatusKind, isOnAccount: boolean, isMobileDevice: boolean): boolean {

    if (isMobileDevice && !FeatureFlags.MobileBookingOnAccount) return false;

    if (loginStatus !== LoginStatusKind.LoggedIn) return false;

    return isOnAccount;
}

export default connect(mapStateToProps)(NewBookingForm);