import React from "react";
import "./MobileAddFavourites.scss";
import BookingTemplateNameField from '../../BookingTemplate/BookingTemplateNameField';
import ConditionManagerMobile from "../../Condition/UI/BookingConditionManager";
import BookingAddressGroup from "../Widget/BookingAddressGroup";
import BookingPageLoading from "../Widget/BookingPageLoading";
import BookingInputStyling from "../FormSteps/BookingInputStyling";
import BookingButton from "../Widget/BookingButton";
import NotesToDriver from "../Widget/NotesToDriver";
import { BookingLocationContactName } from "../Widget/BookingLocationContactName";
import { BookingLocationContactNumber } from "../Widget/BookingLocationContactNumber";
import { ContactKind } from "../../BookingTemplate/BookingTemplateEntities";
import { ApplicationState } from "../../../appState";
import { connect } from "react-redux";
import { BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import BookingAddressComponent from "../BookingAddressComponent";
import { DriverNotesType } from "../../AddressPoints/Redux/AddressPointsEntities";
import BookingUseCompanyDetails from "../Widget/BookingUseCompanyDetails";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import { MaybeAccountOption } from "../OptionalParts/MaybeAccountOption";
import { MaybeAccountDetails } from "../OptionalParts/MaybeAccountDetails";
import { MaybeParcelModeSelector } from "../OptionalParts/MaybeParcelModeSelector";
import { MaybeCardSelector } from "../OptionalParts/MaybeCardSelector";
import { MaybeDeliveryOptions } from "../OptionalParts/MaybeDeliveryOptions";

/**
 * Wrapper component for mobile version to host either the booking form or a list of the user's bookings (selected by tabs).
 */
const MobileAddFavourites: React.FC<ReduxProps> = (props) => {

    const isParcelMode = props.ActiveBookingForm === BookingFormKind.ParcelBooking;

    return (
        <div className="mobile-add-fav-panel">
            <BookingTemplateNameField />
            <MaybeAccountOption />
            <MaybeParcelModeSelector />
            <MaybeAccountDetails />

            {isParcelMode ? <FormInParcelMode /> : <FormInTaxiMode />}

            <BookingButton />
            <BookingPageLoading />
        </div>
    );
}

/** Core booking form inputs in detailed (parcel) mode */
const FormInParcelMode: React.FC = () => (
    <>
        <BookingInputStyling Title={FormStepLabel.PickupDetails}>
            <BookingAddressComponent isPickup />
            <NotesToDriver NotesType={DriverNotesType.Pickup} />
            <BookingUseCompanyDetails IsPickup />
            <BookingLocationContactName ContactType={ContactKind.PickUp} />
            <BookingLocationContactNumber ContactType={ContactKind.PickUp} />
        </BookingInputStyling>

        <BookingInputStyling Title={FormStepLabel.DropOffDetails} >
            <BookingAddressComponent isPickup={false} />
            <NotesToDriver NotesType={DriverNotesType.Dropoff} />
            <BookingUseCompanyDetails IsPickup={false} />
            <BookingLocationContactName ContactType={ContactKind.DropOff} />
            <BookingLocationContactNumber ContactType={ContactKind.DropOff} />
        </BookingInputStyling>

        <ConditionManagerMobile />
        <MaybeCardSelector />
        <MaybeDeliveryOptions />
    </>
);

/** Core booking form inputs in simple (taxi) mode */
const FormInTaxiMode: React.FC = () => (
    <>
        <BookingInputStyling Title={FormStepLabel.BookingDetails}>
            <BookingAddressGroup />
        </BookingInputStyling>

        <ConditionManagerMobile />

        <BookingInputStyling Title={FormStepLabel.ContactDetails}>
            <BookingLocationContactName ContactType={ContactKind.PickUp} />
            <BookingLocationContactNumber ContactType={ContactKind.PickUp} />
        </BookingInputStyling>

        <BookingInputStyling Title={FormStepLabel.DriverInstruction}>
            <NotesToDriver />
        </BookingInputStyling>

        <MaybeCardSelector />
    </>
);

// #region Redux

interface ReduxProps {
    ActiveBookingForm: BookingFormKind;
}

function mapStateToProps(state: ApplicationState): ReduxProps {
    return {
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
    };
}

export default connect(mapStateToProps)(MobileAddFavourites);

// #endregion