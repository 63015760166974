import appstore from "../../appStore";
import { Dispatch } from '../Dispatch';
import { EnsureTncDataLoaded, IsNewTncConsentNeeded, RetrieveTnc } from "../TncPrivacy/TncPrivacyHelper";
import { TaskKind, TaskStatus } from "../LoginValidation/LoginValidationEntities";
import { DialogKind } from "../Dialog/DialogEntities";
import { IsVerificationNeeded } from "./AuthHelper";
import { VerifyPhoneForProfile } from "../Verification/VerifyPhoneForProfile";
import { RemoveProfileValidationListener } from "../LoginValidation/LoginValidationHelper";
import { Api } from "../../Services/Api";
import { RefreshProfileAfterDataChange } from "./GetUserProfileService";
import { LogEvent } from "../../utils/LogEvent";
import { LogEventInGA } from "../../utils/LogEventsInGoogleAnalytics";
import { IsValidNumber } from "../Utils/PhoneValidation";
import { MyStorage } from "../../Storage";
import { LoadUserConsentStatus } from "../LegalDocuments/LoadUserConsentStatus";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { UserConsentRequest } from "../../Services/LegalDocumentsContracts";
import { LoginStatusKind } from "./AuthEntities";
import { VerificationTrigger } from "../Verification/VerificationEntities";

/**
 * Prerequisite:
 * Successful completion of function LoadUserProfile().
 *
 * Responsibilities:
 *    1) Retrieve and see if there is a new version of terms and conditions;
 *    2) Check whether is profile is newly created, if yes, go function ;
 *    3) Validate information for general logged in users, go function .
 */
export async function ValidateProfile(isNewSignUp: boolean) {

    if (!FeatureFlags.AuthV2 && !appstore.getState().authentication.UserProfile) return;

    // terms and conditions data will be needed for profile verification later
    if (FeatureFlags.LegalDocumentsV2) {
        await LoadUserConsentStatus();
    } else {
        await EnsureTncDataLoaded();
    }    

    // Update some information to Bcc only for newly signed up users
    if (isNewSignUp) await AdjustNewlyCreatedProfile();
        
    // Start to validate profile, generally for both new and old logged in users
    if (!appstore.getState().loginValidation.TimerID) {
        Dispatch.LoginValidation.StartVerification(window.setInterval(() => { ValidateProfileAndInteraction(); }, 1000));
    }
}

/** 
 * Validates the profile of a user who has just logged in:
 *     1) If contact number is null or invalid;
 *     2) If contact number is a valid mobile number, but never gets verified;
 *     3) Terms & conditions acceptance.
 * Order is defined by PO and BA.
 * 
 * Business rule:
 * https://cabcharge.atlassian.net/wiki/spaces/CA/pages/1034584204/Logged-in+users+validation
 *
 * Because each step is an interaction with the user, we need to set a timer to guarantee the order.
 */
async function ValidateProfileAndInteraction() {

    // Step 1 -- Running check
    const state = appstore.getState();
    if (state.loginValidation.IsAsyncTaskRunning) return;

    // Step 2 -- If contact number is null or invalid
    const userProfile = state.authentication.UserProfile!; // this code only runs for logged in users

    let contactNumber = null;

    if (!FeatureFlags.AuthV2) {
        contactNumber = userProfile.ContactPhone;
    }
    else {
        contactNumber = state.user2.UserProfile?.PhoneNumber;
    }

    // this check for the login status is a bit awkward here since this function is only executed for the logged in users. 
    // But there is a problem with the timer. If the user has closed any dialog (phone number, verification etc) or declined the terms and conditions, the system automatically logs out the user. While the user is logging out, the timer still runs and executes this function and triggers the dialog again during or just after the user has logged out.
    // With this check, even if the function is executed during the actual logging out process, the loop stops at this point, because the state is alredy updated to 'LoggedOut'.
    if (state.authentication.LoginStatus !== LoginStatusKind.LoggedIn) return;

    if ((contactNumber != "") && (contactNumber != null) && IsValidNumber(contactNumber)) {
        Dispatch.LoginValidation.CompleteSubTask(TaskKind.NoValidContactNumber);
    }
    else {
        // TODO: I think this should be Signup for both Auth versions. Check the impact if applied for AuthV1.
        if (FeatureFlags.AuthV2) {
            Dispatch.Verification.WasTriggeredBy(VerificationTrigger.Signup);
        }

        Dispatch.Dialog.ShowDialog(DialogKind.ContactDetails);
        Dispatch.LoginValidation.AwaitSubTask(TaskKind.NoValidContactNumber);
        return;
    }

    // Step 3 -- If contact number is a valid mobile number, but has not been verified yet
    // This is only applied to AuthV1. PhoneNumber is not saved in the DB without verifying with AuthV2 (Azure B2C).
    if (!FeatureFlags.AuthV2) {
        if (!IsVerificationNeeded(userProfile)) {
            Dispatch.LoginValidation.CompleteSubTask(TaskKind.MobileVerification);
        }
        else {
            VerifyPhoneForProfile(userProfile, false);
            Dispatch.LoginValidation.AwaitSubTask(TaskKind.MobileVerification);
            return;
        }
    }

    // Step 4 -- Terms & conditions acceptance
    if (FeatureFlags.LegalDocumentsV2) {
        
        if (state.legalDocuments.IsConsentRequired) {

            Dispatch.Dialog.ShowDialog(DialogKind.LegalDocumentsConsent);
            Dispatch.LoginValidation.AwaitSubTask(TaskKind.TncAcceptance);
            return;
        }
        else {
            Dispatch.LoginValidation.CompleteSubTask(TaskKind.TncAcceptance);
        }
    }
    else {
        const { TncInfo } = appstore.getState().tncPrivacy;

        if (TncInfo && IsNewTncConsentNeeded(userProfile, TncInfo)) {
            await RetrieveTnc(true, true, false);

            Dispatch.Dialog.ShowDialog(DialogKind.TncConsent);

            Dispatch.LoginValidation.AwaitSubTask(TaskKind.TncAcceptance);
            return;
        }
        else {
            Dispatch.LoginValidation.CompleteSubTask(TaskKind.TncAcceptance);
        }
    }

    // complete all sub tasks that do not need to run for AuthV2 in order to remove the validation timer.
    if (FeatureFlags.AuthV2) {
        Dispatch.LoginValidation.CompleteSubTask(TaskKind.MobileVerification);
    }

    // Step 5 -- Completion check
    const TaskLookUpTable = state.loginValidation.TaskLookUpTable;

    if (TaskLookUpTable[TaskKind.NoValidContactNumber] === TaskStatus.Completed && TaskLookUpTable[TaskKind.MobileVerification] === TaskStatus.Completed && TaskLookUpTable[TaskKind.TncAcceptance] === TaskStatus.Completed) {
        RemoveProfileValidationListener();
        return;
    }
}

/**
 * The user has just signed up from our UI. Some default values on their user profile should be updated.
 * This process is necessary because we don't have full control over the user creation process - it is done via Auth0 and 13cabs Auth0 API and Booking API.
 * 1) The user has acknowledged the terms and conditions as part of the sign up dialog. Report this to the back end.
 * 2) During the sign up dialog, we do SMS verification of the user's mobile phone. We can safely mark this as verified. 
 */
async function AdjustNewlyCreatedProfile() {

    const appState = appstore.getState();
    let userProfile = appState.authentication.UserProfile;
    if (!userProfile) return;

    // Save user consent
    if (FeatureFlags.LegalDocumentsV2) {
        const latestLegalDocsId = appstore.getState().legalDocuments.LegalDocumentsPack.LegalDocumentsVersion;

        if (latestLegalDocsId) {
            const consentRequest: UserConsentRequest = {
                LegalDocsId: latestLegalDocsId
            }

            var consentResult = await Api.LegalDocuments.UpdateUserConsent(consentRequest);

            if (!consentResult.isSuccess) {
                return;
            }

            Dispatch.LegalDocuments.ConsentStatus({ IsConsentRequired: false, LegalDocumentsToConsent: null });
        }

    } else {
        const tncInfo = appState.tncPrivacy.TncInfo;

        if (tncInfo) {
            const tncId = tncInfo.tncId;
            const result = await Api.User.AddUserTncAgreement(tncId);
            appInsights.trackEvent("User Sign Up", { UserProfile: JSON.stringify(userProfile), TncConsent: result.isSuccess ? "Success" : "Failure" });

            // the above call will have updated the user's profile entity, but we don't need to reload it from the server because we know exactly what field has changed
            if (result.isSuccess) {
                userProfile = {
                    ...userProfile,
                    TncConsentId: tncId,
                };

                Dispatch.Auth.UserProfile(userProfile);
                MyStorage.UserProfileV2.StoreData(userProfile);
            }
        }
    }

    // if the user verified their mobile phone number during the signup process, now we can set it on the user account
    if (IsVerificationNeeded(userProfile)) {

        const smsChallengeId = appState.verification.SmsChallengeId!;

        const result = await Api.SmsVerification.UpdatePhoneNumber(smsChallengeId);

        if (result.isSuccess) {
            await RefreshProfileAfterDataChange();
            Dispatch.Verification.ClearVerificationCode();
            LogEvent.UpdateUserVerificationStatusSuccess(userProfile.UserId);
        }
        else {
            LogEvent.UpdateUserVerificationStatusFail(userProfile.UserId);
        }
    }

    // Google Analytics - Lodge a sign up event
    LogEventInGA.UserSignup(userProfile.UserId);
}