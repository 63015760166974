import { FeatureFlags } from "../../Config/FeatureFlags";
import { Api } from "../../Services/Api";
import { ApiGeoPoint } from "../../Services/BookingEntities";
import { Address, PickupServiceCheckState, ServiceCheckStatus } from "../Booking/BookingEntities";
import { ProcessServiceabilityResult } from "../Booking/CheckCabService";
import { ProcessServiceabilityResultV2 } from "../Booking/CheckCabServiceAvailabilityV2";
import { Dispatch } from "../Dispatch";

/** Set address serviceability status in the store based on the result of API calls to check serviceability. */
export async function SetAddressServiceability(address: Address) {

    Dispatch.AddressPoints.SetAddressValidity({
        ServiceabilityState: {
            status: ServiceCheckStatus.CheckInProgress
        }
    });

    let availabilityResult;

    if (FeatureFlags.BookingApiV2) {
        availabilityResult = await CheckCabServiceabilityV2(address);
    }
    else {
        availabilityResult = await CheckCabServiceabilityV1(address);
    }    

    Dispatch.AddressPoints.SetAddressValidity({
        ServiceabilityState: availabilityResult
    })

}

/** Check serviceability using V1 API (Booking API). */
async function CheckCabServiceabilityV1(address: Address): Promise<PickupServiceCheckState> {
    const result = await Api.Location.CheckCabServiceByPlaceIDV1b(address.PlaceId);
    return ProcessServiceabilityResult(result);
}

/** Check serviceability using V2 API (Global booker API). */
async function CheckCabServiceabilityV2(address: Address): Promise<PickupServiceCheckState> {
    const geoLocation: ApiGeoPoint = {
        Latitude: address.Lat,
        Longitude: address.Long
    }

    const result = await Api.MakeBooking.CheckCabServiceAvailability(geoLocation);
    return ProcessServiceabilityResultV2(result);
}