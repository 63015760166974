import { Chip, Switch, Typography } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { ApplicationState } from '../../appState';
import { FeatureInfo } from '../../Services/FeaturesContracts';
import { CommonDialogDescriptionCore } from '../Dialog/CommonDialogDescription';
import { CommonDialogHeaderCore } from '../Dialog/CommonDialogHeader';
import { DialogKind } from '../Dialog/DialogEntities';
import { BrandedImage, GetBrandedUrl } from '../Utils/BrandedContentUrls';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import "./AvailablePreviews.scss";
import { PreviewFeatures } from './PreviewFeatures';

/** 
 * A list of preview features available for user optin.
 * Displays as a tab in the user's profile page.
 */
const AvailablePreviewsCore: React.FC<ReduxProps> = (props) => {

    const optableFeatures = props.PreviewFeatures.filter(i => i.IsUserOptable);

    return (
        <div className="available-features-main-area">
            <CommonDialogHeaderCore TopmostDialog={DialogKind.AvailableFeatures} />
            <CommonDialogDescriptionCore TopmostDialog={DialogKind.AvailableFeatures} />
            <div className="available-features-content-area">
                <Table className="available-features-title-area" responsive>
                    <thead className="features-heading">
                        <tr>
                            <th className="features-title">Feature</th>
                            <th className="description-title">Description</th>
                            <th className="status-title">Status</th>
                        </tr>
                    </thead>
                    <tbody className="available-features-body-area">
                        {optableFeatures.map(feature => (
                            <FeatureTableRow key={feature.FeatureId} {...feature} />
                        ))}
                    </tbody>
                    <tr><td colSpan={4}><hr className="Dividing-line"></hr></td></tr>
                </Table>
            </div>
        </div>
    );
}

/**
 * A single row in the preview feature opt-in table.
 */
const FeatureTableRow: React.FC<FeatureInfo> = (feature) => {

    const imgUrl = feature.IsEnabled ? GetBrandedUrl(BrandedImage.PickupAddressA) : getContentUrl(ContentURL.images.UserProfile.CircleGrey);

    return (
        <tr>
            <td>
                <div className='feature-name-container'>
                    <div className='feature-name'>
                        <img src={imgUrl} alt="featureIcon" />
                        <Typography style={{ marginLeft: 10 }}>{feature.Name}</Typography>
                    </div>
                    <Chip label="NEW" />
                </div>
            </td>
            <td>
                <Typography>{PreviewFeatures.GetDescription(feature)}</Typography>
            </td>
            <td className="status-body">
                <Switch color="primary" checked={feature.IsEnabled} onChange={() => PreviewFeatures.ToggleOptInState(feature)} />
            </td>
        </tr>
    );
}

// #region Redux

interface ReduxProps {
    PreviewFeatures: FeatureInfo[]
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        PreviewFeatures: state.features.PreviewFeatures
    };
}

export const AvailablePreviews = connect(GetPropsFromRedux)(AvailablePreviewsCore);

// #endregion