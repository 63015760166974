import React, { Fragment } from 'react';
import { TextField } from "@material-ui/core";
import { TextFieldEndIcon } from './TextFieldEndIcon';

export interface CustomTextFieldProps {
    
    /** Name of the input field  */
    Name?: string;

    /** The label text for the input field */
    LabelText: string;

    /** The error message text displayed for invalid input */
    ErrorMessage: string;

    /** The error message style displayed for invalid input */
    ErrorMessageStyle?: string;

    /** True if, the input field is empty */
    IsInputInvalid: boolean;

    /** The input field will be autofocused on component mount */
    AllowAutoFocus?: boolean;

    /** Denotes whether the input field is focussed or not  */
    IsInputFocussed: boolean;

    /** Denotes whether the input field has value or not */
    DoesInputHasValue: boolean;  

    /** Limitation of max length of field */
    FieldMaxLength?: number; 

    /** Callback function to clear the input field */
    onClearEvent: () => void;

    /** Callback function to update the focus state of the input field */
    onFocusEvent: (isFocus: boolean) => void;

    /** Callback function to update the input value in store */
    onBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void;

    /** Callback function of onChange handler the input field */
    onChangeEvent?: () => void;
}

/** Renders material UI textfield control with the provided input props */
export const OutlinedTextField = React.forwardRef<HTMLInputElement, CustomTextFieldProps>((props, ref) => {

    /** The label text displayed on top of the input field for either of the below condition:
     * 1. Input field is focussed
     * 2. Input field has value
     */
    const canShrinkLabel = props.IsInputFocussed || props.DoesInputHasValue;

    const showClearTextIcon = !props.IsInputFocussed && props.DoesInputHasValue;

    return (
            <Fragment>
                <TextField
                    inputRef={ref}
                    fullWidth={true}
                    name={props.Name} 
                    variant="outlined"
                    label={props.LabelText}
                    onBlur={props.onBlurEvent}
                    className="simple-textfield"
                    error={props.IsInputInvalid}
                    autoFocus={props.AllowAutoFocus}
                    onFocus={() => props.onFocusEvent(true)}
                    InputLabelProps={{shrink: canShrinkLabel}}
                    onChange={props.onChangeEvent ? props.onChangeEvent : () => {}}
                    inputProps={{ maxLength: props.FieldMaxLength ?? 1024 }}
                    InputProps={{
                        endAdornment: <TextFieldEndIcon showClearTextIcon={showClearTextIcon} showErrorIcon={props.IsInputInvalid} onClick={props.onClearEvent} />
                    }} 
                />
                { props.IsInputInvalid && <div className={props.ErrorMessageStyle ?? "booking-form-error-message"}>{props.ErrorMessage}</div> }
            </Fragment>
    )	
});
