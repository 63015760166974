import React from 'react';
import { MarkAsRead } from './FeaturesHelper';
import { FeatureCardProps } from './FeaturesEntities'
import { Button } from '@material-ui/core';

/** Common Acknowledge button for all feature cards. Hide button if the card is acknowledege*/
const AcknowledgeFeatureButton = (props: FeatureCardProps) => {

    if (props.CanShowAcknowledgeButton) {
        return <div className="feature-btn"><Button variant="outlined" color="primary" onClick={() => MarkAsRead(props.FeatureId)}>OK, GOT IT</Button></div>
    } else {
        return null;
    }
};

export default AcknowledgeFeatureButton;
