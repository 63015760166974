import appstore from "../../appStore";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { CheckAddPlus, DropLeadingZero } from "../../utils/Formattingutil";
import { HasLogInProperly } from "../Authentication/AuthHelper";
import { IsValidMobileNumber } from "../Utils/PhoneValidation";
import { ContactNumberKind } from "./VerificationEntities";

/**
 * Describe the type of the contact phone number used in the booking just made.
 * Supports the messaging from the Booking Confirmation screen.
 */
export function DetermineContactPhoneKind(): ContactNumberKind {

    const contactNumber: string = GetContactNumber();

    if (IsValidMobileNumber(contactNumber)) {
        return ContactNumberKind.Mobile;
    }

    return ContactNumberKind.Landline;
}

/**
 * Get the contact phone number used to make a booking.
 * Only used by the (Booking Success) Confirmation.tsx component.
 */
function GetContactNumber(): string {

    if (HasLogInProperly(true)) {        

        if (FeatureFlags.AuthV2) {
            // UserProfile and PhoneNumber are non-null after logged in and created a booking.
            return CheckAddPlus(appstore.getState().user2.UserProfile!.PhoneNumber!);
        }

        const auth = appstore.getState().authentication;
        return CheckAddPlus(auth.UserProfile!.ContactPhone);
    }
    else {
        const verification = appstore.getState().verification;
        const contactInfo = verification.UserContactNumberInfo;

        return contactInfo.CountryInfo!.CountryCode + DropLeadingZero(contactInfo.Contactnumber!);
    }
}