import React from 'react';
import { connect } from "react-redux";
import { DialogKind } from '../Dialog/DialogEntities';
import 'react-intl-tel-input/dist/main.css';
import PhoneInput from "react-intl-tel-input";
import './ContactVerification.scss';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import { CustomErrorMessages, DescriptiveErrorMessages, WellKnownMessageKind } from "../Utils/ErrorMessages";
import { DropLeadingZero } from '../../utils/Formattingutil';
import { IsGuest } from "./IsGuest";
import { CheckAddPlus } from '../../utils/Formattingutil';
import { Config } from "../../Config/Config";
import { AuthenticationState, LoginStatusKind } from '../Authentication/AuthEntities';
import { ApplicationState } from '../../appState';
import { VerificationState } from './VerificationState';
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { CreateBookingWithoutSmsVerification } from "../Booking/CreateBookingCommon";
import { VehicleOption } from '../Condition/Redux/ConditionEntities';
import { Dispatch } from '../Dispatch';
import { IsUnderProfileValidationMode  } from '../LoginValidation/LoginValidationHelper';
import { ContactNumberValidationResult, ContactNumberKind }  from '../Authentication/Login/LoginEntities';
import AuthImplV2 from "../Authentication/Login/AuthImplV2";
import { CreateCredentialForSignup } from "../Authentication/Login/CredentialHelper";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { ProfileChanges } from '../../Services/UserEntities';
import { UpdateContactNumber } from '../Authentication/UpdateProfileService';
import { VerifyLoginFromLocalStorageAndLogout } from "../Authentication/AuthHelper";
import { VerificationTrigger } from './VerificationEntities';
import { UILayoutMode } from '../UILogicControl/UILogicControlEntities';
import { IsValidMobileNumber, IsValidAustralianLandlineNumber, RemoveLeadingPlusSign, ParseContactNumber, ValidateContactNumber } from '../Utils/PhoneValidation';
import { FeatureFlags } from '../../Config/FeatureFlags';
import SilentLogin from '../Authentication/Login/SilentLogin';
import { AuthType } from '../Authentication/Login/LoginEntities';
import { LogEvent } from '../../utils/LogEvent';
import { BookingWorkFlowState } from '../Booking/Redux/BookingState';
import { StartSmsOutcome, StartSmsVerification } from './StartSmsVerification';
import { UserProfileV2 } from '../../Services/UserContractsV2';

interface ContactDetailsState {
    countryCode: string;
    errorMessage: string;
    isValidContactNumber: boolean | null;
    isButtonActive: boolean;
    contactNumber: string;
    isFourthAttempt: boolean;
}

class ContactDetails extends React.Component<ReduxProps, ContactDetailsState> {
    noOfAttempts: number;
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: ReduxProps) {
        super(props);
        this.inputRef = React.createRef();

        this.state = {
            countryCode: "+61",
            errorMessage: "",
            isValidContactNumber: null,
            isButtonActive: false,
            contactNumber: "",
            isFourthAttempt: false
        }

        this.noOfAttempts = 1;
        this.sendVerificationCode = this.sendVerificationCode.bind(this);
        this.decideActionsDependsOnMobileOrLandline = this.decideActionsDependsOnMobileOrLandline.bind(this);
        this.validateInputs = this.validateInputs.bind(this);
    }

    /**
     * On component mount, populate the contact number field and country code if they are available in the store.
     * Eg: Open the popup by clicking on the "edit number" button of the Verification popup.
     */
    componentDidMount() {
        LogEvent.OnDisplayingContactDetailsPage();
                
        if (this.props.verification.Trigger === VerificationTrigger.Signup) {
            if (FeatureFlags.AuthV2) {

                // PhoneNumber exists only when the dialog is opened by clicking on the 'Edit' (number) button in verification dialog
                if (this.props.UserProfileV2?.PhoneNumber) {
                    const phoneNumberParts = ParseContactNumber(this.props.UserProfileV2!.PhoneNumber!);
                    this.setState({
                        contactNumber: phoneNumberParts.NumberPart,
                        countryCode: phoneNumberParts.CountryCode,
                        isButtonActive: true
                    });
                }                
            }
            else {
                if (!!this.props.authentication.Credentials.ContactNumber) {
                    this.setState({
                        contactNumber: DropLeadingZero(this.props.authentication.Credentials.ContactNumber),
                        countryCode: RemoveLeadingPlusSign(this.props.authentication.Credentials.CountryInfo!.CountryCode),
                        isButtonActive: true
                    });
                }
            }
        }
        else {
            if (this.props.verification.UserContactNumberInfo.Contactnumber) {
                this.setState({ contactNumber: this.props.verification.UserContactNumberInfo.Contactnumber, isButtonActive: true });
    
                if(this.props.verification.UserContactNumberInfo.CountryInfo) {
                    this.setState({ countryCode: RemoveLeadingPlusSign(this.props.verification.UserContactNumberInfo.CountryInfo.CountryCode) });
                }
            }
            else if (this.props.authentication.UserProfile && this.props.authentication.UserProfile.ContactPhone) {
                this.setState({ contactNumber: ParseContactNumber(this.props.authentication.UserProfile.ContactPhone).NumberPart, isButtonActive: true });
                this.setState({ countryCode: ParseContactNumber(this.props.authentication.UserProfile.ContactPhone).CountryCode });
            }
        }
    }

    /** 
     * Update the store on change of the country code from the dropdown    
     *  */
    updateCode = (status: any, value: any) => {
        
        this.validateInputs();
        
        this.setState({ countryCode: value.dialCode }, () => {          
            if (this.props.verification.Trigger === VerificationTrigger.Signup) {

                if (FeatureFlags.AuthV2) {
                    const phonenumber = CheckAddPlus(value.dialCode) + DropLeadingZero(this.state.contactNumber);
                    Dispatch.UserV2.PhoneNumber(phonenumber);
                }
                else {
                    Dispatch.Auth.SignupPhoneCountry({ CountryCode: CheckAddPlus(value.dialCode), CountryName: value.name, CountryIsoCode: value.iso2 });
                }
            }   
            else {
                Dispatch.Verification.CountryInfo({ CountryCode: CheckAddPlus(this.state.countryCode), CountryName: value.name, CountryIsoCode: value.iso2 });
            }                        
        });
    };

    /**
     * The purpose of this function is to, after the change of below 2 inputs, re-valid if the current inputs are ready to go.
     *     1) International country selected which inclides +61 (domestic);
     *     2) Contact number
     * 
     * "Ready to go" means:
     *     1) Button is active;
     *     2) No error message.
     * 
     * The implementation of this function is exaclty the same from previous changeButtonColor().
     */
    validateInputs() {
        // make the button active only if the mobile number length is 8 or higher.
        this.setState({isButtonActive: this.inputRef.current!.value.length >= 8});

        // Restricting the phone number field only to numbers. State will not be updated if the user entered other characters.
        const re = /^[0-9\b]+$/;
        if (this.inputRef.current!.value === '' || re.test(this.inputRef.current!.value)) {
            this.setState({contactNumber: this.inputRef.current!.value})
        }

        /**
         * We need to clean error message here if the user start typing
         */
        this.setState({ errorMessage: "", isValidContactNumber: null });
    }

    updateNumber(e: any) {
        const contactNumber = e.target.value;

        if (FeatureFlags.AuthV2) {
            const phonenumber = CheckAddPlus(this.state.countryCode) + DropLeadingZero(contactNumber);
            Dispatch.UserV2.PhoneNumber(phonenumber);
        }
        else {
            if (this.props.verification.Trigger === VerificationTrigger.Signup) {
                if (ValidateContactNumber(this.state.contactNumber, this.props.authentication.Credentials.CountryInfo!.CountryCode).IsValid) {
                    Dispatch.Auth.SignupPhoneNumber(this.state.contactNumber);
                }
                else {
                    this.setState({ errorMessage: CustomErrorMessages.CredentialContactNumberErrorMessage, isValidContactNumber: false });
                    Dispatch.Auth.ClearSignupPhoneNumber();
                }
            }
            else { // Existing implementation within brackets
                Dispatch.Verification.ContactNumber(contactNumber);
                Dispatch.Verification.CountryInfo({ CountryCode: CheckAddPlus(this.state.countryCode) });
            }
        }
    }

    async sendVerificationCode() {        
        
        this.noOfAttempts++;
        Dispatch.Verification.ShowLoaderInContactDetails();
        this.setState({ isValidContactNumber: null, errorMessage: "" });

        let phoneNumberToVerify = null;

        if (!FeatureFlags.AuthV2) {
            const isGuest = IsGuest(this.props.authentication.AuthToken, this.props.authentication.UserProfile);

            // phone number test on server
            phoneNumberToVerify = this.DetermineSmsPhoneNumber(isGuest);
        }
        else {
            phoneNumberToVerify = this.props.UserProfileV2!.PhoneNumber!;
        }

        
        const result = await StartSmsVerification(phoneNumberToVerify);

        if (result.Outcome === StartSmsOutcome.InvalidPhoneNumber) {
            this.HandleInvalidPhoneNumber();
            return;
        }
                                       
        if (result.Outcome === StartSmsOutcome.Success) {
            LogEvent.SuccessfulPhoneVerification();
            Dispatch.Verification.HideLoaderInContactDetails();
            this.setState({ isValidContactNumber: true });

            Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);
            Dispatch.Dialog.ShowDialog(DialogKind.Verification);
        }
        else {
            // Display the generic error message for timeouts and other unanticipated errors asking the user to call 132227
            LogEvent.SendingVerificationCodeFailure(result.ErrorMessage);
            Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);

            if (this.props.verification.Trigger === VerificationTrigger.Signup) {
                new CredentialsController().GoBackToSignupPopupFromError();
            }
            else {
                ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
            }
        }
    }

    /**
     * Gets the target phone number from several possible places based on the context.
     */
    DetermineSmsPhoneNumber(isGuest: boolean): string {
        let countryCode = "", contactNumber = "";

        if (this.props.verification.Trigger === VerificationTrigger.Signup) {
            countryCode = this.props.authentication.Credentials.CountryInfo!.CountryCode;
            contactNumber = DropLeadingZero(this.props.authentication.Credentials.ContactNumber!);
        }
        else {
            if (isGuest) {
                countryCode = this.props.verification.UserContactNumberInfo.CountryInfo!.CountryCode;
                contactNumber = DropLeadingZero(this.props.verification.UserContactNumberInfo.Contactnumber!);
            }
            else {
                countryCode = CheckAddPlus(this.state.countryCode);
                contactNumber = DropLeadingZero(this.state.contactNumber);
            }
        }

        const smsPhoneNumber = countryCode + contactNumber;
        return smsPhoneNumber;
    }

    /** Logging and state changes after the API indicates that the phone numbre was invalid. */
    HandleInvalidPhoneNumber() {
        LogEvent.InvalidMobileNumberProvided();
        Dispatch.Verification.HideLoaderInContactDetails();
        this.setState({
            errorMessage: CustomErrorMessages.CredentialContactNumberErrorMessage,
            isValidContactNumber: false
        });

        if (this.noOfAttempts === 4) {
            LogEvent.OnProvidingInvalidMobileNumberFourthTime();
            this.setState({ isFourthAttempt: true });
            Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);

            if (this.props.verification.Trigger === VerificationTrigger.Signup) {
                new CredentialsController().GoBackToSignupPopupFromError();
            }
            else {
                Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.InvalidPhone, DialogToOpen: DialogKind.ContactDetails });
                Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            }
        }
    }

    /**
     * This function will be triggered after on click of "Next";
     * There will be 2 levels of check, before the "decision".
     * 
     * Level 1 -> We only consider landline for a guest booking;
     * 
     * Level 2 -> If it is a guest booking ->
     *     1> Mobile   -> this.sendVerificationCode();
     *     2> Landline -> Format is valid, then make a booking directly;
     *                    Otherwise, inline error message and inactivate the "Next" button;
     */
    async decideActionsDependsOnMobileOrLandline() {

        if (FeatureFlags.AuthV2) {
            await this.ProceedToVerificationOrUpdateProfile();
            return;
        }

        if (IsUnderProfileValidationMode()) { VerifyLoginFromLocalStorageAndLogout(); }

        /**
         * This is a guest, we expect both mobile and Australian landline
         */
        if(IsGuest(this.props.authentication.AuthToken, this.props.authentication.UserProfile)) {
            let countryCode = "", contactNumber = "";
  
            if (this.props.verification.Trigger === VerificationTrigger.Signup) {
                countryCode = this.props.authentication.Credentials.CountryInfo!.CountryCode;
                contactNumber = this.props.authentication.Credentials.ContactNumber!;
            }
            else {
                countryCode = this.props.verification.UserContactNumberInfo.CountryInfo!.CountryCode;
                contactNumber = this.props.verification.UserContactNumberInfo.Contactnumber!;
            }

            const validate: ContactNumberValidationResult = ValidateContactNumber(contactNumber, countryCode);

            if (validate.IsValid) {
                if (validate.ContactNumberKind === ContactNumberKind.Mobile) {
                    await this.sendVerificationCode();
                }
                else if (validate.ContactNumberKind === ContactNumberKind.Landline) {
                    Dispatch.Verification.ShowLoaderInContactDetails();

                    if (this.props.verification.Trigger === VerificationTrigger.Signup) { // Proceed signing up
                        
                        if (FeatureFlags.Auth0RedirectInChildWindow) {
                            Dispatch.Auth.ShowSilentLogin();
                        }
                        else {
                            new AuthImplV2().SignUpAuth0(CreateCredentialForSignup(this.props.authentication.Credentials)); 
                        }
                    }
                    else { // Existing implementation in brackets
                        CreateBookingWithoutSmsVerification();
                    }
                }
            }
            else {
                this.setState({
                    errorMessage: CustomErrorMessages.CredentialContactNumberErrorMessage,
                    isValidContactNumber: false,
                    isButtonActive: false
                });
            }
        }
        /**
         * The user has logged in successfully, no behaviour changed
         */
        else {
            if (IsUnderProfileValidationMode()) {
                const countryCode = this.props.verification.UserContactNumberInfo.CountryInfo!.CountryCode;
                const contactNumber = this.props.verification.UserContactNumberInfo.Contactnumber!;
                const fullNumber = CheckAddPlus(countryCode) + DropLeadingZero(contactNumber);
                Dispatch.Verification.ShowLoaderInContactDetails();

                if (IsValidMobileNumber(fullNumber)) { 
                    await this.sendVerificationCode();   
                }
                else if (IsValidAustralianLandlineNumber(fullNumber, countryCode, contactNumber)) { // ContactNumber, Profile
                    const body: ProfileChanges = { ContactNumber: { PhoneNumber: fullNumber } }
                    const result = await UpdateContactNumber(body);
                    Dispatch.Verification.HideLoaderInContactDetails();
                    
                    if (result) {
                        this.setState({ isValidContactNumber: true });
                        Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);
                    }
                    else {
                        this.setState({
                            errorMessage: CustomErrorMessages.InvalidPhoneNumber,
                            isValidContactNumber: false
                        });
                    }
                }
            }
            else {
                await this.sendVerificationCode();
            }
        }
    }

    /** Determines the next step and proceed based on the phone number type. e.g.: mobile number: validate and proceed to verification, landline: proceed to update profile */
    async ProceedToVerificationOrUpdateProfile() {

        //guest user
        if (this.props.authentication.LoginStatus === LoginStatusKind.LoggedOut) {
            // Is this ever get executed? There is a phone number field in the booking form it self
        }
        else {
            Dispatch.Verification.ShowLoaderInContactDetails();

            const phoneNumber = this.props.UserProfileV2!.PhoneNumber!;

            if (IsValidMobileNumber(phoneNumber)) {
                await this.sendVerificationCode();
            }
            else {
                this.setState({
                    errorMessage: CustomErrorMessages.InvalidPhoneNumber,
                    isValidContactNumber: false
                });
                Dispatch.Verification.HideLoaderInContactDetails();
            }

            // TODO: With Global Booker, this is not true anymore. This should be any valid landline number, not just Australian. Find out how to detect international landline numbers
            /*else if (IsValidAustralianLandlineNumber(fullNumber, countryCode, contactNumber)) { // ContactNumber, Profile
                // TODO: Check the validity of the number and update the profile without verification
            }*/
        }
    }

    render() {
        const { CountryInfo } = this.props.verification.UserContactNumberInfo;   
        // By default, Australian flag will be selected
        let countryIsoCode = CountryInfo?.CountryIsoCode ?? "au";
        
        if (this.props.verification.Trigger== VerificationTrigger.Signup) {
            countryIsoCode = this.props.authentication.Credentials.CountryInfo!.CountryIsoCode!;    
        }
        
        var sendButtonClass = this.state.isButtonActive ? "send-verification-btn active-btn" : "send-verification-btn ghost-button";
        var mobileValidityClass = this.state.isValidContactNumber === false ? "mobile-number red-underline" : this.state.isValidContactNumber || this.state.isButtonActive ? "mobile-number green-underline" : "mobile-number";

        return (
            <div className="contact-details">
                <div className="phone-number-group">
                    <div className="country-code-section">
                        <PhoneInput
                            defaultCountry={countryIsoCode}
                            css={ ['intl-tel-input', 'form-control'] }
                            separateDialCode="true"
                            fieldId={"phoneInput"}
                            localization="false"
                            value={this.state.countryCode}
                            onSelectFlag={this.updateCode}
                            preferredCountries={""}
                        />
                    </div>
                    <div className="mobile-number-section">
                        <input
                            ref={this.inputRef}
                            type="tel"
                            placeholder="Enter your contact number"
                            className={mobileValidityClass}
                            maxLength={Config.ContactNumberMaxLength}
                            onChange={this.validateInputs}
                            onBlur={(e) => this.updateNumber(e)}
                            value={this.state.contactNumber}
                            autoFocus />
                    </div>                    
                </div>
                { this.state.errorMessage ? <p className="verification-error incorrect-phone">{ this.state.errorMessage }</p> : "" }
                { !this.state.isFourthAttempt ? <button className={sendButtonClass} onClick={this.decideActionsDependsOnMobileOrLandline} disabled={!this.state.isButtonActive}>Next</button> : null}
                { this.props.verification.IsLoaderShownInContactDetails ? <img alt="loading" src={getContentUrl(ContentURL.images.Loading)} className="loading-image" height="60" /> : null }
                { this.props.IsSilentLoginActive && <SilentLogin Credentials={this.props.authentication.Credentials} AuthType={AuthType.Signup} /> }
            </div>
        );
    }
}

// #region Redux

interface ReduxProps {
    bookingpayloadFromStore: BookingWorkFlowState;
    authentication: AuthenticationState;
    verification: VerificationState;
    selectedCondition: VehicleOption;
    IsMobileDevice: boolean;
    IsSilentLoginActive: boolean;
    UserProfileV2: UserProfileV2 | null;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        bookingpayloadFromStore: state.booking,
        authentication: state.authentication,
        verification: state.verification,
        selectedCondition: state.condition.SelectedCondition,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        IsSilentLoginActive: state.authentication.IsSilentLoginActive,
        UserProfileV2: state.user2.UserProfile
    };
}

export default connect(GetPropsFromRedux)(ContactDetails);

// #endregion