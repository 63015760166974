import appstore from "../../appStore";
import { WellKnownMessageKind } from '../Utils/ErrorMessages';
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { AddressV2 } from '../../Services/MakeBookingContracts';

/**
 * More than 200 kilometers away.
 */
function IsLongDistance(pickup: AddressV2, dropoff: AddressV2): boolean {
    const a = new google.maps.LatLng(pickup.GeoLocation.Latitude, pickup.GeoLocation.Longitude);
    const b = new google.maps.LatLng(dropoff.GeoLocation.Latitude, dropoff.GeoLocation.Longitude);
    const distanceKM = google.maps.geometry.spherical.computeDistanceBetween(a, b) / 1000;

    return distanceKM > 200;
};

/** Show a warning popup if the user has picked a really long trip. */
export function ConsiderLongDistance(): void {
    const appState = appstore.getState();
    const pickup = appState.booking.PickupV2;
    const dropoff = appState.booking.DropoffV2;

    if (pickup && dropoff && IsLongDistance(pickup, dropoff)) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.LongTrip); 
    }     
};