import appstore from "../../appStore";
import { ServiceCheckStatus } from "../Booking/BookingEntities";
import { CheckFutureBookingTime, GetBookInAdvanceDays } from "../Booking/CheckBookingTime";
import { BookingFormKind, UILayoutMode } from "./UILogicControlEntities";
import { ServiceKind } from "../../utils/service-vehicles/ServiceMetadata";
import { IsPaymentAndServiceValidForPriceGuarantee } from "../Payment/PaymentHandler";

export function AreBookingRequisitesReady() : boolean {
    
    const appState = appstore.getState();
    const { UserContactNumberInfo } = appState.verification;
    const { IsSelectedVehicleServiceable, IsInvalidConditionForSatss, ActiveBookingForm } = appState.uiLogicControl.BookingForm;
    const vehicleOption = appState.condition.SelectedCondition;
    const { DropoffV2, BookingTimeV2, PickupServiceCheck, PaymentOption, DeliveryOption, PickupAdditionalDetails } = appState.booking;

    const contactNumber = PickupAdditionalDetails.ContactNumber && PickupAdditionalDetails.ContactNumber.Contactnumber || "";

    // ContactName validation is optional when parcel widget. Its only mandatory when passenger booking
    const contactName = PickupAdditionalDetails.ContactName && PickupAdditionalDetails.ContactName || "";
    if (ActiveBookingForm === BookingFormKind.PassengerBooking && !contactName) return false;
    
    // Validate pick-up address
    if (PickupServiceCheck.status !== ServiceCheckStatus.KnownGood) return false;

    // Drop off address is mandatory for parcel booking
    if ((vehicleOption.Service.kind === ServiceKind.Parcel)  && !DropoffV2) return false;

    // Validate future date & time only for future booking;
    if (!BookingTimeV2.IsImmediate) {
        const bookInAdvanceDays = GetBookInAdvanceDays(appState.authentication.UserProfile);
        const result = CheckFutureBookingTime(BookingTimeV2.RequestedDate, bookInAdvanceDays);

        if (!result.IsValid) return false;
    }

    // Validate contact number when passenger booking. Pickup & Drop-off contact number are optional when parcel widget enable. 
    if ( (ActiveBookingForm === BookingFormKind.PassengerBooking) && !contactNumber ) return false;

    // Contact number has no error 
    if (UserContactNumberInfo.ErrorMessage) return false;

    // Vehicles list is empty (i.e.: loading vehicles from the API failed in V2 API)
    if (appState.condition.ConditionList.length === 0) return false;

    // Validate selected vehicle
    if (!IsSelectedVehicleServiceable) return false;

    const IsBookingOnAccount = appState.booking.IsOnAccount;
    
    // Validate delivery option selected parcel booking and booking with on account i.e. Toggle is ON 
    if (ActiveBookingForm === BookingFormKind.ParcelBooking && IsBookingOnAccount && DeliveryOption === null) return false;

    const AccountSelectedForBooking = appState.booking.AccountData;

    // Validate account details when booking on accounts i.e. Toggle is ON
    if (IsBookingOnAccount && !AccountSelectedForBooking) return false;

    // Validate only for account bookings
    if (AccountSelectedForBooking) {

        // Drop off address is mandatory for account booking
        if (!DropoffV2) return false;

        // Extract account booking payload
        const { OrderNumber, FileNumber, SelectedAccount } = AccountSelectedForBooking;

        // For DVA accounts booking, Provider Number (OrderNumber) and File Number are mandatory fields
        if (SelectedAccount.IsDVA && (!OrderNumber || !FileNumber)) {
            return false;
        }
        else {
            // If OrderNumber field is required, then OrderNumber has to be not null
            if (SelectedAccount.IsOrderNumberRequired) {
                return OrderNumber?.trim() != "";                
            }
        }
    }
    else {

        // Validate payment method
        if (!PaymentOption) return false;

        if (!IsPaymentAndServiceValidForPriceGuarantee(appState)) return false;

        // Payment method has error
        if (appState.payment.PaymentCardError) return false;
    }

    // SATSS is selected as the payment method and the selected condition doesn't support SATSS.
    if (IsInvalidConditionForSatss) return false;
    
    return true;
};

/**
 * Helper function to decide 
 */
export function UnderMobileMode() : boolean {

    const { LayoutMode } = appstore.getState().uiLogicControl;

    return LayoutMode === UILayoutMode.Mobile;
}