import React from 'react'
import { FormControl, MenuItem, TextField } from '@material-ui/core'
import PersonIcon from "@material-ui/icons/PersonOutlined";
import AddAPhotoIcon from '@material-ui/icons/AddAPhotoOutlined';
import LocalBarIcon from '@material-ui/icons/LocalBarOutlined';
import AssignmentIndIcon from '@material-ui/icons/AssignmentIndOutlined';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import './DeliveryOptions.scss';
import { ApplicationState } from '../../../appState';
import { connect } from 'react-redux';
import { SnackBar } from '../../../widgets/InformationDisplay/Snackbar';
import { Dispatch } from '../../Dispatch';
import { DeliveryOptionHelpText, DeliveryOptionKind } from './ParcelEntities';

interface DeliveryOptionProps {
    ErrorMessage: string | null;
	SelectedOption: DeliveryOptionKind | null;
}

/** This component show delivery options to select when making parcel booking. This will only show up when booking on account. */
const DeliveryOptions: React.FC<DeliveryOptionProps> = (props) => {

	const SelectDeliveryOption = (event: React.ChangeEvent<any>) => {

        const selectedDeliveryOptionValue = event.target.value;

		Dispatch.Booking.ChangeDeliveryOption(selectedDeliveryOptionValue);

		Dispatch.Booking.SetDeliveryOptionError(null);
        
    }

	/**
     * Return the delivery option help text to be displayed below the delivery option dropdown.
     */
	const GetDeliveryHelpText = (): string => {

        // Do not show, in case of error
        if (props.ErrorMessage) return "";
        
        return props.SelectedOption ? DeliveryOptionHelpText[props.SelectedOption] : "";
    }
	
	const labelText = props.SelectedOption  ? "" : "Please select";
	const optionHelperText = GetDeliveryHelpText();

	return (
		<div className="booking-fields-panel">
			<FormControl variant="outlined" fullWidth={true} className="account-selector-dropdown">
				<TextField
					variant={"outlined"}
					select
					label={labelText}
					value={props.SelectedOption}
					id="delivery-options-label"
					onChange={SelectDeliveryOption}
				>
					<MenuItem value={DeliveryOptionKind.NoUnattended}>
						<div className='delivery-option-item'>
							<PersonIcon /> 
							<span>Do not leave unattended</span> 
						</div>
					</MenuItem>
					<MenuItem value={DeliveryOptionKind.Unattended}>
						<div className='delivery-option-item'>
							<AddAPhotoIcon />
							<span> Leave unattended</span> 
						</div>
					</MenuItem>
					<MenuItem value={DeliveryOptionKind.SameDayAlcohol}>
						<div className='delivery-option-item'>
							<LocalBarIcon />
							<span>Same-day alcohol delivery (RSA)</span> 
						</div>
					</MenuItem>
					<MenuItem value={DeliveryOptionKind.ProofOfAge}>
						<div className='delivery-option-item'>
							<AssignmentIndIcon />
							<span>Proof of age required </span>
						</div>
					</MenuItem>
					<MenuItem value={DeliveryOptionKind.ProofOfDelivery}>
						<div className='delivery-option-item'>
							<AssignmentTurnedInIcon />
							<span>Proof of delivery signature required </span>
						</div>
					</MenuItem>
				</TextField>
			</FormControl>
			{props.ErrorMessage && (
                <div className="booking-form-error-message">{props.ErrorMessage}
                </div>
            )}
			{optionHelperText && (
				<div className="delivery-help-text">
					<SnackBar DisplayText={optionHelperText} />
				</div>
            )}
		</div>
	)
}

function mapStateToProps(state: ApplicationState): DeliveryOptionProps {
    return {
        ErrorMessage: state.booking.DeliveryOptionError,
		SelectedOption: state.booking.DeliveryOption
    };
}

export default connect(mapStateToProps)(DeliveryOptions);
