import { UserProfileV2 } from "../../../Services/UserContractsV2";

export interface UserV2State {

    /** User's details loaded from the API */
    UserProfile: UserProfileV2 | null;
}

/** Initial value for UserV2State */
export const InitialUserV2State: UserV2State = {
    UserProfile: null
};