import * as React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StarBorder from '@material-ui/icons/StarBorder';
import { AddressEntryKind, AddressPickerEntry } from '../Entities/AddressPickerEntry';
import { ComboAddress } from '../Entities/ComboAddress';

/**
 * Part of the Address Picker component.
 * Renders a single entry in the address list dropdown.
 */
export const AddressDropdownEntry: React.FC<MyProps> = (props) => {

    const entry = props.Entry;
    const mainTextSegments = GetPrimaryTextParts();

    let divClass = "address-dropdown-entry";

    if (props.IsTargeted) {
        divClass = divClass + " keyboard-target";
    }

    // pin for google maps; star for favourite
    const icon = entry.Kind === AddressEntryKind.GoogleMaps ? LocationOnIcon : StarBorder;

    return (
        <div onClick={() => props.OnItemSelected(entry)} className={divClass}>
            <Grid container alignItems="flex-start">
                <Grid item>
                    <Box
                        component={icon}
                        style={{ color: props.IsTargeted ? 'red' : 'grey' }}
                    />
                </Grid>
                <Grid item xs>
                    {mainTextSegments.map((segment, index) => (
                        <span key={index} style={{ fontWeight: segment.IsHighlighted ? "bold" : "normal" }}>
                            {segment.Text}
                        </span>
                    ))}

                    <div>
                        <Typography variant="body2" color="textSecondary" noWrap={true}>
                            {ComboAddress.GetSecondaryText(entry)}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );

    /** The first text field, with the most important or summary text */
    function GetPrimaryTextParts(): TextHighlightSegment[] {

        if (entry.Kind === AddressEntryKind.GoogleMaps) {
            return ComputeHighlightSegments(entry.Suggestion.structured_formatting);
        }
        else {
            // no custom highlighting for favourites, for now
            const segment: TextHighlightSegment = {
                IsHighlighted: false,
                Text: entry.Favourite.Name
            }

            return [segment];
        }
    }
}

/** Props for a single entry in the address dropdown list */
interface MyProps {

    /** Text the user has typed in. Used to highlight matches */
    InputText: string;

    /** The address (prediction or favourite) to display */
    Entry: AddressPickerEntry;

    /** Callback when the user selects an entry from the list */
    OnItemSelected: (selectedItem: AddressPickerEntry) => void;

    /** True when this entry has focus / highlight from a keyboard down arrow event. */
    IsTargeted: boolean;
}

/**
 * For the main text entry, use the matched substring list to decompose the string into a list of matching and non-matching segments.
 * The returned array will have alternating highlighted and non-highlighted segments which form the original text when combined.
 */
function ComputeHighlightSegments(entry: google.maps.places.AutocompleteStructuredFormatting): TextHighlightSegment[] {

    const output: TextHighlightSegment[] = [];
    const matches = entry.main_text_matched_substrings;

    // special case: no matches
    if (matches.length === 0) {
        output.push({
            IsHighlighted: false,
            Text: entry.main_text,
        });

        return output;
    }

    // prefix
    const firstMatch = matches[0];

    if (firstMatch.offset > 0) {
        output.push({
            IsHighlighted: false,
            Text: entry.main_text.substring(0, firstMatch.offset),
        });
    }

    // entries
    matches.forEach((match, index) => {

        // matching segment
        const nextNonMatchIndex = match.offset + match.length;

        output.push({
            IsHighlighted: true,
            Text: entry.main_text.substring(match.offset, nextNonMatchIndex),
        });

        // non-matching segment up to the next match
        const nextIndex = index + 1;

        if (nextIndex < matches.length) {
            const nextMatch = matches[nextIndex];

            output.push({
                IsHighlighted: false,
                Text: entry.main_text.substring(nextNonMatchIndex, nextMatch.offset)
            });
        }
    });

    // global suffix
    const lastMatch = matches[matches.length - 1];
    const lastMatchingTextIndex = lastMatch.offset + lastMatch.length - 1;

    if (lastMatchingTextIndex < entry.main_text.length - 1) {
        output.push({
            IsHighlighted: false,
            Text: entry.main_text.substring(lastMatchingTextIndex + 1)
        });
    }

    return output;
}

/** A segment of a larger string with optional highlighting */
interface TextHighlightSegment {
    Text: string;

    IsHighlighted: boolean;
}