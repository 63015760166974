import { BrowserCacheLocation, Configuration, PublicClientApplication } from "@azure/msal-browser";
import { GetValues } from "../../../Config/MyAppConfig";
import { RouteUrls } from "../../../RouteUrls";

let msalApp: PublicClientApplication | null = null;

/** 
 * This defers construction of the PublicClientApplication until the first usage.
 * This is important so that brands without Azure B2C config don't ever try to instantiate it.
 */
export function GetMsalApp(): PublicClientApplication {

    if (msalApp == null) {
        const msalConfig = BuildMsalConfig();
        msalApp = new PublicClientApplication(msalConfig);
    }

    return msalApp;
}

/** Generates the MSAL Configuration object from our application's config. */
function BuildMsalConfig(): Configuration {

    // if this method is running, Azure B2C config will be defined for the current brand
    const b2cConfig = GetValues().AzureB2c!;

    const authority = `https://${b2cConfig.TenantDnsName}.b2clogin.com/${b2cConfig.TenantDnsName}.onmicrosoft.com/${b2cConfig.PrimaryUserFlowId}`;
    const loginDomain = `${b2cConfig.TenantDnsName}.b2clogin.com`;

    const safeOrigin = window?.location.origin ?? ""; // SSR safe
    const redirectUrl = `${safeOrigin}${RouteUrls.B2CLoginReturn}`;

    const msalConfig: Configuration = {
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        auth: {
            clientId: b2cConfig.ClientId,
            authority: authority,
            knownAuthorities: [loginDomain],
            redirectUri: redirectUrl,
        },
    };

    return msalConfig;
}