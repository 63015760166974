import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { Dispatch } from "../Dispatch";
import { BookingFormKind } from "../UILogicControl/UILogicControlEntities";
import { SetSelectedConditionToParcel, SetSelectedConditionToNextAvailable } from "../Condition/PopulateConditions";
import ParcelIcon from "./Parcel/ParcelIcon";
import "./BookingFormTypeSelector.scss";
import TaxiIcon from "./TaxiIcon";

interface PropsFromStore {
    ActiveForm: BookingFormKind;
}

/** Component contains tabs to select the type of booking. e.g: Book a Taxi | Parcel Delivery. */
const BookingFormTypeSelectorCore: React.FC<PropsFromStore> = (props) => {

    function OnTabChange(event: React.ChangeEvent<{}>, value: BookingFormKind) {
        Dispatch.UILogicControl.ActiveBookingForm(value);

        // Select Parcel as default condition and lock the vehicle selector.
        if (value === BookingFormKind.ParcelBooking) {
            SetSelectedConditionToParcel();
        }

        if (value === BookingFormKind.PassengerBooking) {
            SetSelectedConditionToNextAvailable();
        }
    }

    return (
        <div className="booking-type-tabs">
            <Tabs
                value={props.ActiveForm}
                onChange={OnTabChange}
                centered
            >
                <Tab label="Book a Taxi" icon={<TaxiIcon />} value={BookingFormKind.PassengerBooking} disableRipple  />
                <Tab label="Parcel Delivery" icon={<ParcelIcon />} value={BookingFormKind.ParcelBooking} disableRipple />
            </Tabs>
		</div>
	)
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        ActiveForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
    }
}

export const BookingFormTypeSelector = connect(mapStateToProps)(BookingFormTypeSelectorCore);