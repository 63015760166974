import React, { ChangeEvent, Fragment } from "react";
import { Table } from 'reactstrap';
import "./MyWalletTable.scss";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { PaymentCard } from "../../Services/PaymentEntities";
import { DialogKind } from '../Dialog/DialogEntities';
import { Dispatch } from '../Dispatch';
import { SetCardAsDefaultPayment, SetCashAsDefaultPayment, SortPaymentOptions } from "../Payment/PaymentHelper";
import CardRegistration from "./CardRegistration";
import { PayDriverOption, PaymentOption, PaymentOptionKind } from "../Payment/PaymentEntities";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { MobileDialog } from "../Dialog/MobileDialog";
import { CommonDialogHeaderCore } from "../Dialog/CommonDialogHeader";
import { CommonDialogDescriptionCore } from "../Dialog/CommonDialogDescription";
import { LogEvent } from "../../utils/LogEvent";
import { FilterAllowedPaymentOptions, PopulatePaymentOptions } from "../Payment/PaymentHandler";

interface WalletTableRowProps {
    key: number,
    paymentOption: PaymentOption
}

interface stateProps {
    showSpinner: boolean;
}

interface WalletTableProps {
    IsMobileDevice: boolean;
    PaymentOptions: PaymentOption[];
    defaultCard: PaymentCard | null;
    IsCardRegistrationPanelOpen: boolean;
}

class MyWalletTable extends React.Component<WalletTableProps, stateProps> {
    constructor(props: WalletTableProps) {
        super(props);

        this.state = {
            showSpinner: false
        }
    }

    /**
    * return default payment method image, based on boolean value of isDefault
   */
    getDefaultPaymentCardImage = (defaultStatus: boolean) => {
        return defaultStatus ? getContentUrl(ContentURL.images.greenToggleOn) : getContentUrl(ContentURL.images.greenToggleOff)
    }

    /**
     * extract last 8 digits from card Number (eg ****1234)
     * param is a Payment card, because it is simpler to understand the type
     * as card number is undefined for Cash
     */
    extractCardNumber(card: PaymentCard) {
        return card.CardNumber ? card.CardNumber.slice(-8) : "";
    }

    /**
     * Set the payment card to be removed and show the remove dialog confirmation
     */
    onRemoveCardClicked = (card: PaymentCard) => {
        LogEvent.OnRemovingPaymentCard();
        Dispatch.Payment.SetCardToRemove(card);
        Dispatch.Dialog.ShowDialog(DialogKind.RemovePaymentCardConfirmation);
    }

    /**
     * It returns a payment option for a row
     * Moved it to a separate function, just to improve readability
     */
    CardDetailsTableData = (option: PaymentOption) => {
        
        return (
            <div className="cardDetails">
                <img src={getContentUrl(ContentURL.images.PaymentType[option.Type])} width="66px" height="40px" />
                <div className="cardDetailsText">
                    {
                        this.props.IsMobileDevice && <div className="cardNickName"><span className="cardNickNameText">{option.Name}</span></div>
                    }
                    <div>
                        <span className="cardTypeNumber">
                            {option.Type}
                            <span className="cardNumber">
                                {option.Card && this.extractCardNumber(option.Card)}
                            </span>
                        </span>
                    </div>
                    {option.Card?.CardExpiry ? (
                        <div className="cardExpiry">Expiry   <span>{option.Card.CardExpiry}</span></div>
                    ) : ""}
                </div>
            </div>
        )
    }

    /**
     * a toggle component, which is ON/OFF based on isDefault property of the payment option
     */
    defaultPaymentOptionToggle = (paymentOption: PaymentOption) => {
        return (
            <div className="cardDefault">
                <div className="cardDefaultIcon">
                    <div className="Wallettoggle-button">
                        <input type="checkbox" className="check" checked={paymentOption.IsDefault} onChange={() => this.changeDefault(paymentOption)} />
                        <div className="switch"></div>
                        <div className="track"></div>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * Change default payment option
     * We use combination of card id and card name to compare cards
     * Unsetting a card as default or setting a cash as default are same because in both cases cash becomes default
     */
    changeDefault = async (paymentOption: PaymentOption) => {
        this.setState({ showSpinner: true });
        LogEvent.OnChangingDefaultPaymentCard();

        // Check whether it is unsetting of a payment card(credit/cabcharge) as default or setting cash as default
        if ((paymentOption.Card?.CardId === this.props.defaultCard?.CardId && paymentOption.Card?.CardName === this.props.defaultCard?.CardName) || paymentOption.Kind === PaymentOptionKind.PayDriverDirectly) { 
            await SetCashAsDefaultPayment(this.props.defaultCard);
            paymentOption = PayDriverOption;           
        }
        else {
            paymentOption.Card && await SetCardAsDefaultPayment(paymentOption.Card);
        }

        Dispatch.Booking.ChangePaymentMethod(paymentOption);
        this.setState({ showSpinner: false });
    }

    /** Sort and filter the payment options */
    sortAndFilterPaymentOption = () => {
        
        const filteredPaymentOptions = this.props.PaymentOptions.filter(FilterAllowedPaymentOptions);

        return SortPaymentOptions(filteredPaymentOptions);
    }

    /**
     * This function returns wallet row(card row + border row), this takes in a card and returns the row for it,
     * We check for cash, in remove section, because there will be no remove functionality for cash
     */

    WalletTableRow = ({ paymentOption }: WalletTableRowProps) => {
        return (<Fragment>
            <tr>
                <td>{this.CardDetailsTableData(paymentOption)}</td>
                <td style={{ paddingLeft: 25 }}>
                    <div className="cardNickName">
                        <span className="cardNickNameText">{paymentOption.Name}</span>
                    </div>
                </td>
                <td>
                    <div className="cardRemove">
                        {paymentOption.Kind === PaymentOptionKind.Card ? <img onClick={() => paymentOption.Card && this.onRemoveCardClicked(paymentOption.Card)} className="cardRemoveIcon" src={getContentUrl(ContentURL.images.RemoveTrash)} /> : ''}
                    </div>
                </td>
                <td>{this.defaultPaymentOptionToggle(paymentOption)}</td>
            </tr>
            <tr><td colSpan={4}><hr className="Dividing-line"></hr></td></tr>
        </Fragment>)
    }

    /**
     * Returns add a card button
     */
    addCardButton = () => {
        return (
            <button onClick={this.addCardClick} className="AddCardButton">
                <img className="AddCardButtonIcon" src={getContentUrl(ContentURL.images.buttons.addIcon)} />
                <span className="AddCardButtonText">Add a new card</span>
            </button>
        )
    }

    /**
     * AddCard button on click
     */
    addCardClick = () => {
        LogEvent.OnAddingNewPaymentCard();
        Dispatch.Payment.ToggleCardRegistrationPanel(true);
    }

    /**
     * Set the default card via mobile
     */
    changeDefaultCardFromMobile = (e: ChangeEvent<any>) => {

        let selectedOption = this.props.PaymentOptions.find(option => option.Id === e.target.value);

        if (!selectedOption) return; 

        this.changeDefault(selectedOption);        
    }    

    /**
     * Render table with all the cards only when there is data, else return the loading gif
     * This might happen when the service is slow or it is waiting for response
     * Cash is added to wallet irrespective of the return status from the service(even if there is)
     */
    render() {

        const desktopPanelCssClass = !this.props.IsMobileDevice ? "my-wallet-list-panel" : "";

        const walletDetails = this.props.IsMobileDevice ? this.mobileWalletTable() : this.desktopWalletTable();

        return (
            <div className={desktopPanelCssClass}>
                <CommonDialogHeaderCore TopmostDialog={DialogKind.PaymentWallet} />
                <CommonDialogDescriptionCore TopmostDialog={DialogKind.PaymentWallet} />
                {this.props.PaymentOptions.length > 0 ? walletDetails : <img className="loadingWalletData" alt="Loading Cards" src={getContentUrl(ContentURL.images.Loading)} />}
                {this.state.showSpinner ? <img className="loadingWalletData" alt="Setting Default " src={getContentUrl(ContentURL.images.Loading)} /> : ''}
                {this.props.IsCardRegistrationPanelOpen && <CardRegistration CardRegistrationPanelParent="Wallet" />}
            </div>
        );
    }

    mobileWalletTable = () => {

        const defaultSelectedCard = this.props.PaymentOptions.find(x => x.IsDefault);

        return  (
            <>            
                <div className="wallet-details">                    
                    <div className="wallet-radio-group">                   
                        <RadioGroup value={defaultSelectedCard?.Id} onChange={e => this.changeDefaultCardFromMobile(e)}>
                            {this.mobileWalletRow()}
                        </RadioGroup>
                    </div> 
                    <this.addCardButton />
                </div>
                <MobileDialog />
            </>
        )
    }

    mobileWalletRow = () => {
        
        const optionsToDisplay = this.sortAndFilterPaymentOption();
        
        const walletList = optionsToDisplay.map((option, index) => {                          
            return (
                <div key={index}>
                    <div className="mobile-wallet-row">
                        <FormControlLabel value={option.Id} control={<Radio color="secondary" />} label="" />
                        {this.CardDetailsTableData(option)}
                        <div className="cardRemove">
                            {option.Kind === PaymentOptionKind.Card ? <img onClick={() => option.Card && this.onRemoveCardClicked(option.Card)} className="cardRemoveIcon" src={getContentUrl(ContentURL.images.RemoveTrashGrey)} /> : ''}
                        </div>
                    </div>
                    <hr className="Dividing-line" />
                </div>
        )});

        return walletList;
    }

    desktopWalletTable = () => {
        
        const optionsToDisplay = this.sortAndFilterPaymentOption();

        return <Table className="walletTable">
                    <thead className="">
                        <tr>
                            <th className="cardDetailsHeader">Card details</th>
                            <th className="cardNickNameHeader">Card nickname</th>
                            <th className="cardRemoveHeader">Remove</th>
                            <th className="cardDefaultHeader">Set as default</th>
                        </tr>
                    </thead>
                    <tbody className="WalletBody">
                        {optionsToDisplay.map((option, index) => {
                            return <this.WalletTableRow key={index} paymentOption={option} />
                        })}
                        <tr>
                            <td className="AddCardTd" colSpan={4}>
                                <this.addCardButton />
                            </td>
                        </tr>
                    </tbody>
                </Table>
    }
}

function mapStateToProps(state: ApplicationState): WalletTableProps {
    return {
        PaymentOptions: PopulatePaymentOptions(state),
        defaultCard: state.payment.DefaultCard,
        IsCardRegistrationPanelOpen: state.payment.IsCardRegistrationPanelOpen,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export default connect(mapStateToProps)(MyWalletTable);