import { FullStoryAPI } from 'react-fullstory';
import { LoadMyBookings, LoadMyRecentTrips } from "../Booking/BookingLoaders";
import { Config } from "../../Config/Config";
import { LoadMyCards, GetUserMpsProfile, LoadEmptyListOfCards, SetDeviceData } from "../Payment/PaymentHelper";
import { PromoteGuestBookings } from "../MyBookings/Logic/PromoteGuestBookings";
import { GetMyTemplates } from '../BookingTemplate/BookingTemplateHelper';
import { FeatureFlags } from '../../Config/FeatureFlags';
import { ValidateProfile } from './ProfileValidation';
import { Dispatch } from '../Dispatch';
import { TryLoadAndPersistProfile, TryRestoreUserProfile } from './GetUserProfileService';
import { ShowDialogRetryErrorMessage, ShowDialogSimpleErrorMessage } from '../../widgets/general-error-message/ErrorMessagingHelper';
import { TryAgainMessageKind, WellKnownMessageKind } from '../Utils/ErrorMessages';
import { TryRenewAuth0Session } from './AuthHelper';
import { SimpleUserProfile } from '../User/ProfileEntitiesV2';
import appstore from '../../appStore';
import { LogEvent } from '../../utils/LogEvent';
import { AuthToken } from './AuthEntities';
import { LogEventInGA } from '../../utils/LogEventsInGoogleAnalytics';
import { DeriveUserIdFromAuthToken } from './ProcessUserProfile';
import { LoadMyBookingsV2 } from '../BookingV2/BookingLoadersV2';
import { PayDriverOption } from '../Payment/PaymentEntities';
import { ConvertToPaymentOption } from '../Payment/PaymentHandler';
import { LoadMyAddressPoints } from '../AddressPoints/LoadAddressPoints';
import { ConsiderShowingNewFeaturePopup } from '../Booking/Mobile/ConsiderShowingNewFeaturePopup';
import { UserV2 } from '../UserV2/UserV2';
import { PreviewFeatures } from '../Features/PreviewFeatures';

/** 
 * User has just logged in or signed up.
 * Load their profile and other user data, then do validation.
 */
export async function StartLoginSession(isNewSignUp: boolean) {

    // Clear the payment method error message
    Dispatch.Payment.SetError(null);

    // Do not show error any existing message
    Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);

    if (FeatureFlags.AuthV2) {
        // awaiting this to avoid simultaneous calls to the API
        await UserV2.ReloadProfile(true);
        await LoadAllMyUserData(isNewSignUp);
        await ValidateProfile(isNewSignUp);
    }
    else {
        const profile = await TryLoadAndPersistProfile(true);
        if (!profile) {

            ShowDialogRetryErrorMessage(TryAgainMessageKind.Auth0LoginTryAgain);
            return;
        }

        // Send user logged in event to Google analytics
        LogEventInGA.UserLogin(profile.UserId);

        AttachUserIdToTracing(profile);

        await PromoteGuestBookings();
        LoadAllMyUserData(isNewSignUp);
        await ValidateProfile(isNewSignUp);
        ShowAddPaymentCardScreenAfterSignup();
    }
}

/** 
 *  Attempt to restore our entire login session. 
 *  The Auth0 session must be validated before we can make any API calls.
 *  This is called at application startup (i.e. fresh page visit or browser refresh).
 */
export async function TryRestoreLoginSession(originalToken: AuthToken) {

    // all the operations below this if block is not applicable for B2C login because user profile does not exist with AuthV2
    if (FeatureFlags.AuthV2) {
        await UserV2.ReloadProfile(false);
        await LoadAllMyUserData(false);
        await ValidateProfile(false);
        return;
    }

    LogEvent.HttpReferrer(document.referrer);

    // if this fails, we are logged out
    const newToken = await TryRenewAuth0Session();
    if (!newToken) return;

    // check for persistence mismatch
    const oldUserId = DeriveUserIdFromAuthToken(originalToken);
    const newUserId = DeriveUserIdFromAuthToken(newToken);

    let profile: SimpleUserProfile | null = null;

    if (oldUserId !== newUserId) {
        LogEvent.LoginRestoreUserChanged(oldUserId, newUserId);

        // data is uncertain; reload from server, logout if failed
        profile = await TryLoadAndPersistProfile(true);
    }
    else {
        // first try to load profile from API
        profile = await TryLoadAndPersistProfile(false);

        // loading from the API failed, restore from local storage. it might not be the latest data in the profile. but it will be updated with the next page refresh (rare situation)
        if (!profile) profile = await TryRestoreUserProfile(newToken);
    }

    await CompleteLoginRestore(profile);
}

/** 
 *  Attempt to restore login session from SSO.
 */
export async function TryRestoreLoginSessionFromSingleSignOn() {

    LogEvent.HttpReferrer(document.referrer);

    // if this fails, we are logged out
    const token = await TryRenewAuth0Session();
    if (!token) return;

    // load profile from server
    let profile: SimpleUserProfile | null = await TryLoadAndPersistProfile(true);

    await CompleteLoginRestore(profile);
}

/** 
 *  Restore related information per profile, and do profile validation.
 */
async function CompleteLoginRestore(profile: SimpleUserProfile | null) {

    // abort on failure
    if (!profile) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.Auth0RenewFailure);
        return;
    }

    AttachUserIdToTracing(profile);

    LoadAllMyUserData(false);
    await ValidateProfile(false);
}

/**
 * Apply the logged in Profile's User ID to our tracing tech. 
 */
function AttachUserIdToTracing(userProfile: SimpleUserProfile) {

    // Adding user identity data to fullstory tracking for signed in users.
    FullStoryAPI('identify', userProfile.UserId, { displayName: userProfile.DisplayName });

    // Adding user ID to Appinsights tracking
    appInsights.setAuthenticatedUserContext(userProfile.UserId.toString());

    // Adding user ID to Google Analytics
    LogEventInGA.SetUser(userProfile.UserId);
}

/** Various data loads for a signed in user. */
async function LoadAllMyUserData(isNewSignUp: boolean) {

    GetUserMpsProfile();
    LoadPaymentCards(isNewSignUp);

    SetDeviceData();
    if (!isNewSignUp && FeatureFlags.QuickBook) LoadMyRecentTrips();

    // Get the list of booking templates (favourites)
    if (FeatureFlags.BookingTemplates && !isNewSignUp) GetMyTemplates();

    // Load address points. Don't await
    if (FeatureFlags.AddressPoints && !isNewSignUp) LoadMyAddressPoints();

    if (FeatureFlags.PreviewFeatures) await PreviewFeatures.Reload();

    // This needs to be awaited. The result resets the MyBookings.All redux store slice. It can remove tracking link bookings if not awaited.
    if (!isNewSignUp) {
        if (!FeatureFlags.BookingApiV2) {
            await LoadMyBookings(Config.StatusListInScheduleView);
        } else {
            await LoadMyBookingsV2(Config.StatusListInScheduleViewFromV2API);
        }
    }

    // when latest feature is new to user, show latest feature card to logged in user.
    ConsiderShowingNewFeaturePopup();
}

export async function LoadPaymentCards(isNewSignUp: boolean) {

    if (isNewSignUp) {
        LoadEmptyListOfCards();
    }
    else {
        await LoadMyCards();
    }

    SelectDefaultPaymentOption();
}

/** Select the user's default payment option in the booking widget. */
function SelectDefaultPaymentOption() {

    let payment = PayDriverOption;
    const defaultCard = appstore.getState().payment.DefaultCard;

    if (defaultCard) {
        payment = ConvertToPaymentOption(defaultCard);
    }

    Dispatch.Booking.ChangePaymentMethod(payment);
}

/**
 * Show the add payment card screen to the newly registered user, who has clicked the "Add a new card" option from the payment method 
 */
function ShowAddPaymentCardScreenAfterSignup() {

    const isUserAddingPaymentCard = appstore.getState().payment.ShouldDisplayAddPaymentCardScreenAfterSignup;

    if (!FeatureFlags.CardNotPresentPayment || !isUserAddingPaymentCard) return;

    LogEvent.ShowAddCardScreenAfterSignup();

    Dispatch.Payment.ToggleCardRegistrationPanel(true);
    Dispatch.Payment.HideAddPaymentCardScreenAfterSignup();
}