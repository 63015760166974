import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../appState";
import './FareEstimator.scss';
import { Dispatch } from "../Dispatch";
import { InitVehicleState } from "../Condition/Redux/ConditionState";
import { IsValidPickupAndDestinationAddress } from "./FareHelper";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { MobileFareEstimatorPage } from "./MobileFareEstimatorPage";
import { FareEstimatorPage } from "./FareEstimatorPage";
import { MobileDialog } from "../Dialog/MobileDialog";

interface FareEstimatorProps {
    ShouldOpenVehicleSelectorUI: boolean;
    ShowServiceInfo: boolean;
    LayoutMode: UILayoutMode;
}

/** Widget specific to get the fare estimate. This is really a simplified version of the booking widget after cutting out unrelated fields to fare estimate.
 * Contains a button to move to the booking form with prepopulated data such as addesses. */
const FareEstimatorCore: React.FC<RouteComponentProps & FareEstimatorProps> = (props) => {
    const { ShouldOpenVehicleSelectorUI: ShouldOpenVehicleSectorUI } = props;
    
    // Vehicle selector section should be collapsed on page load and defaulted to next available
    useEffect(() => {

        Dispatch.Condition.SelectedCondition(InitVehicleState);

        // Currently opened.
        Dispatch.Condition.HideVehicleSelectorUI();

    }, []);

    useEffect(() => {

        if (ShouldOpenVehicleSectorUI) {
          Dispatch.Condition.ShowVehicleSelectorUI();
        }

    }, [ShouldOpenVehicleSectorUI]);

    if (props.LayoutMode === UILayoutMode.Mobile) {
        return <>
            <MobileFareEstimatorPage />
            <MobileDialog />
        </>;
    } else {
        return <FareEstimatorPage ShowServiceInfo={props.ShowServiceInfo} />;
    }
}

function mapStateToProps(state: ApplicationState): FareEstimatorProps {
    return {
        ShouldOpenVehicleSelectorUI: IsValidPickupAndDestinationAddress(state),
        ShowServiceInfo: state.condition.ShowServiceInfo,
        LayoutMode: state.uiLogicControl.LayoutMode,
    }
}

export const FareEstimator = connect(mapStateToProps)(FareEstimatorCore);