import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { AccountDetails } from "../Account/AccountDetails";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import { CalculateIsBookingOnAccount } from "../NewBookingForm";
import { BookingFormStep } from "../FormSteps/BookingFormStep";

/**
 * Possibly renders the Account Details component in the booking form.
 * It only appears when the user has enabled the "Book on Account" toggle option.
 * Supports mobile and desktop layouts.
 */
const MaybeAccountDetailsCore: React.FC<ReduxProps> = (props) => {

    if (!props.IsBookingOnAccount) return null;

    return (
        <BookingFormStep Label={FormStepLabel.AccountDetails}>
            <AccountDetails />
        </BookingFormStep>
    );
}

// #region Redux

interface ReduxProps {

    /** Whether the user has toggled the "Book on Account" option on. */
    IsBookingOnAccount: boolean;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        IsBookingOnAccount: CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile),
    };
}

export const MaybeAccountDetails = connect(GetPropsFromRedux)(MaybeAccountDetailsCore);

// #endregion