import React, { Component, Fragment } from 'react';
import "./BookingTemplate.scss";
import { connect } from 'react-redux';
import { ApplicationState } from '../../appState';
import { BookingTemplateState } from "./BookingTemplateState";
import { BookingTemplateSelection } from '../Booking/NewBookingTabHelper';
import { QuickBookExpandedSection } from '../UILogicControl/UILogicControlEntities';
import { ConfirmTemplateRemoval } from '../Dialog2/BookingTemplate/RemoveTemplate';
import { GetMyTemplates } from './BookingTemplateHelper';
import { Button } from '@material-ui/core';
interface PropFromStore {
    TemplateList: BookingTemplateState,
    QuickBookExpandedStatus: QuickBookExpandedSection
}
/**
 * Displays the list of booking templates
 */
class BookingTemplateList extends Component<PropFromStore> {

    /** Open the dialog to confirm the removal of the favourite and refresh the list if removed successfully.*/
    async openRemoveDialog(templateID: number) {

        const removeResult = await ConfirmTemplateRemoval(templateID);

        // Removed successfully. Refresh the favourites list
        if (removeResult) GetMyTemplates();
    }
   
    render() {
       
        const { TemplateList, QuickBookExpandedStatus } = this.props;

        // Sort the list on BookingTemplateId in descending order to display the latest created template at the top
        const list = TemplateList.FilteredTemplates.length > 0 ? TemplateList.FilteredTemplates.sort((x,y) => x.BookingTemplateId! > y.BookingTemplateId! ? -1 : 1) : [];
        
        const collapseListClass = QuickBookExpandedStatus !== QuickBookExpandedSection.Templates && "fav-list-collapse";

        const favItems = list.map((item, index) => {
            // Do not show the horizontal line for the last element in the template list
            const isLastItem = index === list.length-1;
            return (
                <Fragment key={index}>
                    <div>
                            <div className="fav-details" >
                                <p className="name">{item.Name}</p> 
                                <div className="delete-fav-link" onClick={e => e.preventDefault()} >
                                    <a href="#" onClick={()=> this.openRemoveDialog(item.BookingTemplateId)}>Remove</a>
                                </div>
                            </div>                                       
                        <div className="button-group">
                            <Button className="delete-fav" 
                                onClick={() => BookingTemplateSelection(item)}
                                variant="outlined" 
                                color="primary">select
                            </Button>
                        </div>                    
                    </div>
                    { !isLastItem && <div className="divide-fav"></div> }
                </Fragment>
            )
        });

        return (
            <div className={`fav-list ${collapseListClass}`}>
                {favItems}
            </div>             
        );
    }
}

function mapStateToProps(state: ApplicationState) : PropFromStore {
    return {
       TemplateList: state.bookingTemplates,
       QuickBookExpandedStatus: state.uiLogicControl.BookingForm.QuickBookExpandedStatus
    }
}
export default connect(mapStateToProps)(BookingTemplateList);
