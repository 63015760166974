/** 
 * Denotes the delivery option used for a parcel booking 
 */
export enum DeliveryOptionKind {
    NoUnattended = "NoUnattended",
    Unattended = "Unattended",
    SameDayAlcohol = "SameDayAlcohol", 
    ProofOfAge = "ProofOfAge",
    ProofOfDelivery = "ProofOfDelivery",

}

/** Delivery help text for the selected delivery option */
export const DeliveryOptionHelpText = {
	NoUnattended: "Hand the parcel to any recipient at the destination.",
	Unattended: "When delivery is unattended, a proof of delivery photo (POD) will be shared on the tracking card after completion.",
	SameDayAlcohol: "Alcohol delivery can only be made to a nominated recipient over 18 years of age. The recipient will be asked for ID and signature, which may be recorded along with their date of birth. Their name must be the same as drop-off contact name.",
	ProofOfAge: "This delivery requires proof of age from any recipient at the destination, and the recipient should be over 18 years of age.",
	ProofOfDelivery: "This delivery requires proof of delivery signature from any recipient at the destination."
}

