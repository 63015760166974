import React from "react";
import "./NotesToDriver.scss";
import $ from "jquery";
import { connect } from "react-redux";
import { ContentURL, getContentUrl } from "../../Utils/ContentURL";
import { Dispatch } from "../../Dispatch";
import { ApplicationState } from "../../../appState";
import { Config } from "../../../Config/Config";
import { TextField } from "@material-ui/core";
import { LogEvent } from "../../../utils/LogEvent";
import { DriverNotesType } from "../../AddressPoints/Redux/AddressPointsEntities";
import { BookingFavouriteAddress } from "../BookingEntities";

const MaxLength = Config.DriverNotesMaxLength;

interface PropsFromStore {
    PickupNotes: string | null;
    DropoffNotes: string | null;
    FavouriteAddress: BookingFavouriteAddress;
}

interface NotesToDriverState {
    isFocus: boolean;
    NotesLength: number;
}

interface DriverNotesProps {
    NotesType?: DriverNotesType;
}

class NotesToDriver extends React.Component<PropsFromStore & DriverNotesProps, NotesToDriverState> {
    private inputRef: React.RefObject<HTMLTextAreaElement>;

    constructor(props: PropsFromStore & DriverNotesProps) {
        super(props);
        this.state = {
            isFocus: false,
            NotesLength: MaxLength
        };
        this.inputRef = React.createRef();
        this.CalculateCharactersLength = this.CalculateCharactersLength.bind(this);
    }

    componentDidMount() {
        if (this.props.NotesType === DriverNotesType.Dropoff) {
            this.inputRef.current!.value = this.props.DropoffNotes ?? "";
        }
        else {
            this.inputRef.current!.value = this.props.PickupNotes ?? "";
        }

        // Display the remaining character length when populating data from a different source (e.g: Favourites or book from history).
        this.CalculateCharactersLength();
    }

    UNSAFE_componentWillReceiveProps(nextProps: PropsFromStore) {
        const driverNote = this.props.NotesType === DriverNotesType.Dropoff ? nextProps.DropoffNotes : nextProps.PickupNotes;

        if (this.inputRef.current!.value !== driverNote) {
            this.inputRef.current!.value = driverNote ?? "";

            this.CalculateCharactersLength();
        }
    }

    /** Condition check and dynamic css changes */
    CalculateCharactersLength() {
        var currentLength = this.inputRef.current!.value.length;

        if (currentLength >= MaxLength - 30) {
            $(".custom-label-notes").css("color", "red");
            $(".custom-img-layout-notes").css("display", "block");
        } else {
            $(".custom-label-notes").css("color", "black");
            $(".custom-img-layout-notes").css("display", "none");
        }
        var noteslength = MaxLength - currentLength;
        this.setState({ NotesLength: noteslength });
    }

    /** Call Action Creator  */
    CallStoreToUpdate(e: any) {
        if (this.props.NotesType === DriverNotesType.Dropoff) {
            Dispatch.Booking.AddDropoffNotesToDriver(e.target.value);
        }
        else {
            Dispatch.Booking.AddPickupNotesToDriver(e.target.value);
        }

        this.UpdateFocusStatus(false);
        if (e.target.value) {
            LogEvent.OnAddingDriverNotes();
        }
    }

    /** Set focus status for driver notes */
    UpdateFocusStatus = (isFocus: boolean) => this.setState({ isFocus: isFocus });

    render() {
        
        const canShrinkLabel = this.state.isFocus || !!this.inputRef.current?.value;
        let label = "Notes For Driver"; // When there is only one driver notes field.

        if (this.props.NotesType === DriverNotesType.Pickup) label = "Pickup notes for Driver";
        if (this.props.NotesType === DriverNotesType.Dropoff) label = "Destination notes for Driver";
        
        return (
                <div className="booking-fields-panel drivernotes-panel">
                    <TextField
                        rowsMax={3}                    
                        multiline={true}
                        fullWidth={true}
                        variant="outlined"
                        inputRef={this.inputRef}
                        label={label}
                        className="simple-textfield"
                        id="outlined-multiline-static"
                        inputProps={{maxLength: MaxLength}}
                        onInput={this.CalculateCharactersLength}
                        InputLabelProps={{shrink: canShrinkLabel}}                
                        onBlur={(e1) => this.CallStoreToUpdate(e1)}                        
                        onFocus={() => this.UpdateFocusStatus(true)}
                        placeholder="e.g. unit, gate and floor numbers."
                    />
                    <div className="character-calculation-div">           
                        <img
                            alt="warning"
                            src={getContentUrl(ContentURL.images.Diagnostic.warning)}
                            className="custom-img-layout-notes"
                        />
                        <label className="input-label custom-label-notes">{this.state.NotesLength} characters left</label>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): PropsFromStore => {
    return {
        PickupNotes: state.booking.PickupNotesToDriver,
        DropoffNotes: state.booking.DropoffNotesToDriver,
        FavouriteAddress: state.booking.FavouriteAddress
    };
};

export default connect(mapStateToProps)(NotesToDriver);
