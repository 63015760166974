import { FormControl, IconButton, InputAdornment, InputLabel, TextField } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { Dispatch } from "../../Dispatch";

interface PropsFromStore {
    Name: string | null;
}

/** Component contains the input field for the name of the address point. */
const AddressPointNameCore: React.FC<PropsFromStore> = (props) => {

    // input text typed by the user
    const [inputText, setInputText] = React.useState('');

    const nameInputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        nameInputRef.current!.value = props.Name ?? "";
        setInputText(nameInputRef.current!.value);
    }, [props.Name]);

    /** Clear the data from the store on click of clear button (X). */
    function ClearName() {
        setInputText('');
        Dispatch.AddressPoints.ClearName();
    }

    /** Input text can be changed from either user types the text or value forced from an external state (in Edit mode). */
    function OnTextInputChanged(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        setInputText(value);
        Dispatch.AddressPoints.RefreshName(value);
    }

    const endIcon = inputText !== '' ? <Close fontSize="small" className="clear-icon" /> : null;

    return (
        <div className="address-point-input">
            <FormControl variant="standard">
                <InputLabel shrink htmlFor="name-input">
                    Address name
                </InputLabel>
                <TextField
                    id="name-input"
                    inputRef={nameInputRef}
                    fullWidth={true}
                    variant="outlined"
                    onChange={OnTextInputChanged}
                    placeholder="Enter a name for your address"
                    className="simple-textfield"
                    InputProps={{
                        endAdornment: <InputAdornment position="end" className="clear-icon">
                            <IconButton onClick={ClearName}>
                                {endIcon}
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </FormControl>            
        </div>
        );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        Name: state.addressPoints.ActiveAddressPoint.Name
    }
}

export const AddressPointName = connect(mapStateToProps)(AddressPointNameCore);