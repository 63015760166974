import React from 'react';
import './TextArea.scss';
import { TextAreaProps } from './TextAreaProps';
import { getContentUrl, ContentURL } from '../../Utils/ContentURL';
import { TextField } from "@material-ui/core";

/** Text area for additional notes. */
class TextArea extends React.Component<TextAreaProps, { isLoading: boolean }> {
    public state: {
        isLoading: boolean;
    };

    constructor(props: TextAreaProps) {
        super(props);
        this.state = { isLoading: false };
    }

    /** Trigger the submit function in the Contact form on click of the button in the text area. */
    private async submit() {
        this.setState({ isLoading: true }); // Show loader
        if (this.props.isActive) {
            await this.props.onSubmit();
        } else {
            await this.props.inactiveSubmit();
        }
        this.setState({ isLoading: false });
    }

    public render() {

        const submitBtnClass = this.props.isActive && !this.state.isLoading ? "submit-btn submit-btn-active" : "submit-btn";
        
        return <div className="text-area">
            <TextField
                name="itemDescription"
                variant="outlined" 
                fullWidth={true}
                multiline={true}
                placeholder="Enter additional feedback here"
                value={this.props.value}
                onChange={this.props.updateHandler}
            />            
            <button className={submitBtnClass} disabled={this.state.isLoading} onClick={() => this.submit()}>
                Submit enquiry
                {this.state.isLoading ? <img alt="loading" src={getContentUrl(ContentURL.images.Loading)} className={`submit-spinner`} /> : null}
            </button>
        </div>;
    }
}

export default TextArea;

