import { ReduxStoreSlice } from "../../../ReduxStoreSlice";
import { VehicleOption, ConditionLookupTables, AsyncLoadState, FareRequestInput, ApiVehicleOption } from "./ConditionEntities";
import { ConditionState } from "./ConditionState";
import { InitConditionContainerState } from "./ConditionState";
import { FareEstimateReducer, FareEstimateReducerV2 } from "./FareEstimateReducer";
import { CalculateMaxiTaxiSeaterInfo, DecorateMaxiTaxiInConditionList } from "../ProcessMaxiTaxi";
import { TaxiFareEstimateResponseV2, TaxiFareResponseV2 } from "../../../Services/FareEntities";
import { Vehicle } from "../../../Services/MakeBookingContracts";

const slice = new ReduxStoreSlice<ConditionState>("Condition", InitConditionContainerState);

/** Dispatcher for actions in the Condition state slice. */
export const ConditionDispatchV2 = {

    /** Update selected condition. */
    SelectedCondition: slice.Action("Selected Condition", ChangeSelectedCondition),

    /** Update conditions list. */
    RefreshConditions: slice.Action("Update Condition List", UpdateConditionList),

    /** Update conditions list V2 for Global Booker. */
    RefreshConditionsV2: slice.Action("Update Condition List V2", UpdateConditionListV2),

    /** Make the Vehicle Selection UI visible */
    ShowVehicleSelectorUI: slice.EmptyAction("Show Vehicle Selector", ShowVehicleSelectorUI),

    /** Hide the Vehicle Selection UI */
    HideVehicleSelectorUI: slice.EmptyAction("Hide Vehicle Selector", HideVehicleSelectorUI),

    /** An update to the status of a Condition-loading API call. The caller will supply all fields of the new state. The number is the Suburb ID. */
    ConditionDataLoadStatus: slice.Action("Condition Data Load Status", UpdateConditionDataLoadStatus),

    /** An update to the status of a GetFare API call. The caller will supply all fields of the new state. */
    FareLoadStatus: slice.Action("Fare Data Load Status", UpdateFareLoadStatus),

    /** Apply fare estimate data to Conditions data. It supports separate loading of conditions vs fares. */
    ApplyFareEstimate: slice.Action("Apply Fare Estimate", ApplyFareEstimate),

    /** Apply fare estimate v2 data to Conditions data. */
    ApplyFareEstimateV2: slice.Action("Apply Fare Estimate V2", ApplyFareEstimateV2),

    /** Remove fare estimates from Conditions data. This happens when the dropoff address is cleared. */
    ClearFareEstimate: slice.EmptyAction("Clear Fare Estimate", ClearFareEstimate),

    /** Load all conditions data available in the specified area. This is purely a V1 workaround to support parcels. */
    LoadAllRawV1Data: slice.Action("Load All Conditions", LoadAllRawV1Data),

    /** Set currently active service (i.e. opened for more info) */
    ActiveService: slice.Action("Set Active Service", SetActiveCondition),

    /** Show more info about selected service in a popup. */
    ShowServiceInfo: slice.EmptyAction("Show Service Info", ShowServiceInfo),

    /** Hide more info popup. */
    HideServiceInfo: slice.EmptyAction("Hide Service Info", HideServiceInfo),

    /** Select the "Price Guarantee" option for the booking fare */
    SetPriceGuarantee: slice.EmptyAction("Set Price Guarantee", SetPriceGuarantee),
    
    /** Clear (undo) the "Price Guarantee" selection for the booking fare */
    ClearPriceGuarantee: slice.EmptyAction("Clear Price Guarantee", ClearPriceGuarantee),

    /** Load all the vehicles available for the current brand. */
    AllVehicles: slice.Action("Load All Vehicles", LoadAllVehicles)
};

/** Reducer for the Condition store slice */
export const ConditionReducerV2 = slice.MakeCombinedReducer(); 

/** Update selected condition. */
function ChangeSelectedCondition(state: ConditionState, selectedCondition: VehicleOption): ConditionState {
    return {
        ...state,
        SelectedCondition: selectedCondition
    }
}

/** Update conditions list. */
function UpdateConditionList(state: ConditionState, conditionLookup: ConditionLookupTables): ConditionState {
    const MaxiTaxiSeaterInfo = CalculateMaxiTaxiSeaterInfo(conditionLookup.MaxiTaxiLookUp);
    const ConditionList = DecorateMaxiTaxiInConditionList(conditionLookup.ConditionList, MaxiTaxiSeaterInfo, conditionLookup.MaxiTaxiLookUp);
    return {
        ...state,
        ConditionList: ConditionList,
        MaxiTaxiLookUp: conditionLookup.MaxiTaxiLookUp,
        MaxiTaxiSeaterInfo: MaxiTaxiSeaterInfo
    }
}

/** V2 of update conditions list from Global Booker. */
function UpdateConditionListV2(state: ConditionState, conditionLookup: ConditionLookupTables): ConditionState {
    const MaxiTaxiSeaterInfo = { Minimum: 0, Average: 0, Maximum: 0 };

    return {
        ...state,
        ConditionList: conditionLookup.ConditionList,
        MaxiTaxiLookUp: conditionLookup.MaxiTaxiLookUp,
        MaxiTaxiSeaterInfo: MaxiTaxiSeaterInfo
    }
}

/** Make the Vehicle Selection UI visible */
function ShowVehicleSelectorUI(state: ConditionState): ConditionState {
    return {
        ...state,
        IsVehicleSelectionActive: true,
    };
}

/** Hide the Vehicle Selection UI */
function HideVehicleSelectorUI(state: ConditionState): ConditionState {
    return {
        ...state,
        IsVehicleSelectionActive: false,
    };
}

/** An update to the status of a Condition-loading API call. The caller will supply all fields of the new state. The number is the Suburb ID. */
function UpdateConditionDataLoadStatus(state: ConditionState, dataLoadStatus: AsyncLoadState<number>): ConditionState {
    return {
        ...state,
        LoadingStatus: dataLoadStatus,
    };
}

/** An update to the status of a GetFare API call. The caller will supply all fields of the new state. */
function UpdateFareLoadStatus(state: ConditionState, loadStatus: AsyncLoadState<FareRequestInput>): ConditionState {
    return {
        ...state,
        FareLoadStatus: loadStatus,
    };
}

/** Apply fare estimate data to Conditions data. It supports separate loading of conditions vs fares. */
function ApplyFareEstimate(state: ConditionState, fares: TaxiFareResponseV2): ConditionState {
    return FareEstimateReducer(state, fares);
}

/** Apply fare estimate v2 data to Conditions data. */
function ApplyFareEstimateV2(state: ConditionState, fares: TaxiFareEstimateResponseV2): ConditionState {
    return FareEstimateReducerV2(state, fares);
}

/** Remove fare estimates from Conditions data. This happens when the dropoff address is cleared. */
function ClearFareEstimate(state: ConditionState): ConditionState {
    const { FixedFare, ...newState } = state;

    return {
        ...newState,
        SelectedCondition: RemoveFareEstimate(state.SelectedCondition),
        ConditionList: state.ConditionList.map(RemoveFareEstimate),
    };
}

/** Load all conditions data available in the specified area. */
function LoadAllRawV1Data(state: ConditionState, allConditions: ApiVehicleOption[]): ConditionState {
    return {
        ...state,
        AllRawV1Data: allConditions
    }
}

/** Returns a vehicle with the Fare field empty. Reuses the existing instance if possible. */
function RemoveFareEstimate(vehicle: VehicleOption): VehicleOption {
    if (vehicle.Fare) {
        return {
            ...vehicle,
            Fare: undefined,
            FixedFare: undefined
        };
    }
    else {
        return vehicle;
    }
}

/** Set currently active service (i.e. opened for more info) */
function SetActiveCondition(state: ConditionState, service: VehicleOption): ConditionState {
    return {
        ...state,
        ActiveService: service
    }
}

/** Show more info about selected service in a popup. */
function ShowServiceInfo(state: ConditionState): ConditionState {
    return {
        ...state,
        ShowServiceInfo: true
    }
}

/** Hide more info popup. */
function HideServiceInfo(state: ConditionState): ConditionState {
    return {
        ...state,
        ShowServiceInfo: false
    }
}

/** Select the "Price Guarantee" option for the booking fare */
function SetPriceGuarantee(state: ConditionState): ConditionState {
    return {
        ...state,
        IsPriceGuaranteeSelected: true
    }
}

/** Clear (undo) the "Price Guarantee" selection for the booking fare */
function ClearPriceGuarantee(state: ConditionState): ConditionState {
    return {
        ...state,
        IsPriceGuaranteeSelected: false
    }
}

/** Load all the vehicles available for the current brand. */
function LoadAllVehicles(state: ConditionState, vehicles: Vehicle[]) {
    return {
        ...state,
        AllVehicles: vehicles
    }
}