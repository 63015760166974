import { Button } from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import StarBorder from "@material-ui/icons/StarBorder";
import React, { useState } from "react";
import { AddressPointDetails } from "../../../Services/AddressPointsContracts";
import { LoadGooglePlaceDetails } from "../../Booking/BookingLocationHelper";
import { DialogKind } from "../../Dialog/DialogEntities";
import { Dispatch } from "../../Dispatch";
import { GeoPoint } from "../../Location/Entities";
import { AddressPointModalType } from "../Redux/AddressPointsEntities";

/** Component contains details of an address point, a single item of the address points list. */
export const AddressPointListItem: React.FC<AddressPointDetails> = (props) => {

    const [isExpanded, setIsExpanded] = useState(false);

    /** Open the dialog to edit address point. It is async since this calls Google to get Geolocation for the specified address in order to display the pin on the map. */
    async function OpenEditWindow(address: AddressPointDetails) {

        const placeResult = await LoadGooglePlaceDetails(address.GooglePlaceId);

        if (!placeResult || !placeResult.geometry) return;

        Dispatch.AddressPoints.ActiveAddressPoint(address);
        Dispatch.Dialog.ShowDialog(DialogKind.EditAddressPoint);
        Dispatch.AddressPoints.SetUiEditModeType(AddressPointModalType.Edit);

        const geoLocation: GeoPoint = {
            latitude: placeResult.geometry.location.lat(),
            longitude: placeResult.geometry.location.lng()
        };

        Dispatch.AddressPoints.RefreshAddress({
            DisplayText: address.DisplayText,
            GooglePlaceId: address.GooglePlaceId,
            GeoLocation: geoLocation
        });
    }

    /** Opens the dialog to get confirmation for address point removal. */
    function OpenDeleteWindow(address: AddressPointDetails) {
        Dispatch.AddressPoints.ActiveAddressPoint(address);
        Dispatch.Dialog.ShowDialog(DialogKind.RemoveAddressPoint);
    }

    return (
        <>
            <div className="address-point-header">
                <div className="address-point-name-group">
                    <span><StarBorder fontSize="small" /></span>
                    <span className="name-text">{props.Name}</span>
                </div>
                <div>
                    <Button onClick={() => OpenEditWindow(props)}>Edit</Button>
                    <Button onClick={() => OpenDeleteWindow(props)}>Remove</Button>
                </div>
            </div>
            <div className="address-point-address">{props.DisplayText}</div>
            <div onClick={() => setIsExpanded(!isExpanded)} className="view-details-btn">
                <span className="view-details-text">View Details</span>
                <span>{isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}</span>
            </div>
            {isExpanded && <AddressPointListItemExpanded {...props} />}
        </>
    );
}

const AddressPointListItemExpanded: React.FC<AddressPointDetails> = (props) => {

    return (
        <>
            <div className="driver-notes">
                <div className="driver-notes-label">Pickup notes for driver</div>
                <div className="driver-notes-value">{props.PickupNotes}</div>
            </div>
            <div className="driver-notes">
                <div className="driver-notes-label">Destination notes for driver</div>
                <div className="driver-notes-value">{props.DropoffNotes}</div>
            </div>
        </>
    );
}