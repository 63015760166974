import { ReduxStoreSlice } from "../../../ReduxStoreSlice";
import { BookingWorkFlowState, DefaultBookingWorkFlowState } from "./BookingState";
import { ServiceCheckStatus, Verification, PickupServiceCheckState, AccountBookingPayload, PickupServiceabilityDetails, PlaceResult } from "../BookingEntities";
import { PaymentOption } from "../../Payment/PaymentEntities";
import { ApiGeoPoint } from "../../../Services/BookingEntities";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { GooglePlaceToAddressV2Details } from "../../../widgets/google-maps/ParseGooglePlace";
import { AddressV2 } from "../../../Services/MakeBookingContracts";
import { DateTime } from "luxon";
import { BookingTimeFutureV2, BookingTimeNowV2, BookingTimeV2 } from "../../BookingV2/BookingV2Entities";
import { AddressPointDetails } from "../../../Services/AddressPointsContracts";
import { DeliveryOptionKind } from "../Parcel/ParcelEntities";
import { UserContactNumberInfo } from "../../Verification/VerificationEntities";
import { Config } from "../../../Config/Config";

const slice = new ReduxStoreSlice<BookingWorkFlowState>("Booking", DefaultBookingWorkFlowState);

/** Dispatcher for actions in the Booking state slice. */
export const BookingDispatchV2 = {
    ChangePickup: slice.Action("Pickup Address", HandlePickUpAddressChange),
    ChangeDropoff: slice.Action("Dropoff Address", HandleDropOffAddressChange),
    ClearPickup: slice.EmptyAction("Clear Pickup", HandlePickUpAddressClear),
    ClearDropoff: slice.EmptyAction("Clear Dropoff", HandleDropOffAddressClear),
    UpdateBookingTimeForNow: slice.EmptyAction("Update Booking Time For Now", UpdateBookingTimeForNow),
    UpdateBookingTimeForFuture: slice.Action("Update Booking Time For Future", UpdateBookingTimeForFuture),
    ChangeVerification: slice.Action("Change Verification", HandleVerificationChange),
    AddPickupNotesToDriver: slice.Action("Pickup Notes To Driver", HandlePickupNotesToDriverChange),
    ClearPickupNotesToDriver: slice.EmptyAction("Clear Pickup Notes To Driver", ClearPickupNotesToDriverChange),
    AddDropoffNotesToDriver: slice.Action("Dropoff Notes To Driver", HandleDropoffNotesToDriverChange),
    ClearDropoffNotesToDriver: slice.EmptyAction("Clear Dropoff Notes To Driver", ClearDropoffNotesToDriverChange),
    UpdatePickupServiceability: slice.Action("Pickup Serviceability", HandlePickupServiceCheckUpdate),
    EnableDiagnosticUI: slice.EmptyAction("Enable Diagnostic UI", HandleEnableDiagnosticUI),
    SetBookOnAccount: slice.Action("Book On Account", HandleSetBookOnAccount),
    ChangePaymentMethod: slice.Action("Change Payment Option", HandlePaymentOptionChange),
    UpdateAccountDetails: slice.Action("Change Account Details", HandleAccountBookingPayloadChange),
    ClearAccountDetails: slice.EmptyAction("Clear Account Details", HandleClearAccountDetails),
    SetDeviceData: slice.Action("Set Device Data", HandleSetDeviceData),
    SetTemplateName: slice.Action("Change Template Name", HandleTemplateNameChange),
    ClearPaymentOption: slice.EmptyAction("Clear Payment Option", ClearPaymentOption),
    RefreshPickupFavouriteAddress: slice.Action("Favourite Address: Pickup", RefreshPickupFavouriteAddress),
    RefreshDropoffFavouriteAddress: slice.Action("Favourite Address: Dropoff", RefreshDropoffFavouriteAddress),
    ClearPickupFavouriteAddress: slice.EmptyAction("Clear Favourite Address: Pickup", ClearPickupFavouriteAddress),
    ClearDropoffFavouriteAddress: slice.EmptyAction("Clear Favourite Address: Dropoff", ClearDropoffFavouriteAddress),

    ChangeDeliveryOption: slice.Action("Change Delivery Option", HandleDeliveryOptionChange),
    SetDeliveryOptionError: slice.Action("Set Delivery Option Error", SetDeliveryOptionErrorMessage),

    PickupContactName: slice.Action("Pickup Contact Name", HandlePickupContactName),
    ClearPickupContactName: slice.EmptyAction("Clear Pickup Contact Name", HandleClearPickupContactName),
    
    PickupContactNumber: slice.Action("Pickup Contact Number", HandlePickupContactNumber),
    ClearPickupContactNumber: slice.EmptyAction("Clear Pickup Contact Number", HandleClearPickupContactNumber),

    DropoffContactName: slice.Action("Dropoff Contact Name", HandleDropoffContactName),
    ClearDropoffContactName: slice.EmptyAction("Clear Dropoff Contact Name", HandleClearDropoffContactName),
    
    DropoffContactNumber: slice.Action("Dropoff Contact Number", HandleDropoffContactNumber),
    ClearDropoffContactNumber: slice.EmptyAction("Clear Dropoff Contact Number", HandleClearDropoffContactNumber),
};

/** Reducer for the Booking store slice */
export const BookingReducerV2 = slice.MakeCombinedReducer();

/** Handle the PickUp address  for Booking Payload */
function HandlePickUpAddressChange(state: BookingWorkFlowState, pickupAddress: PlaceResult): BookingWorkFlowState {
    return {
        ...state,
        PickupV2: ConvertPlaceResultToAddressV2(pickupAddress)
    };
}

/** Handle the Dropoff condition for Booking Payload */
function HandleDropOffAddressChange(state: BookingWorkFlowState, dropoffAddress: PlaceResult): BookingWorkFlowState {
    return {
        ...state,
        DropoffV2: ConvertPlaceResultToAddressV2(dropoffAddress)
    };
}

/** Clear the PickUp address for Booking Payload. Also reset the pickup state check. */
function HandlePickUpAddressClear(state: BookingWorkFlowState): BookingWorkFlowState {

    return {
        ...state,
        PickupServiceCheck: { status: ServiceCheckStatus.NoInputSelected },
        PickupV2: null,
    };
}

/** Clear the Dropoff condition for Booking Payload */
function HandleDropOffAddressClear(state: BookingWorkFlowState): BookingWorkFlowState {

    return {
        ...state,
        DropoffV2: null,
    };
}

/** Apply a booking time update action to the booking state (book for now). */
function UpdateBookingTimeForNow(state: BookingWorkFlowState): BookingWorkFlowState {

    const timeV2: BookingTimeNowV2 = {
        IsImmediate: true
    };

    return BookingTimeHandler(state, timeV2);
}

/** Apply a booking time update action to the booking state (book for future). */
function UpdateBookingTimeForFuture(state: BookingWorkFlowState, bookingTime: DateTime): BookingWorkFlowState {

    const timeV2: BookingTimeFutureV2 = {
        RequestedDate: bookingTime,
        IsImmediate: false
    };

    return BookingTimeHandler(state, timeV2);
}

/** Generate a shared BookingWorkFlowState for update time now and future. */
function BookingTimeHandler(state: BookingWorkFlowState, timeV2: BookingTimeV2): BookingWorkFlowState {

    return {
        ...state,
        BookingTimeV2: timeV2
    };
}

/** This is doing a kind of PATCH on the Verification property */
function HandleVerificationChange(state: BookingWorkFlowState, newData: Verification): BookingWorkFlowState {

    return {
        ...state,
        Verification: {
            ...state.Verification,
            ...newData
        }
    };
}

/** Update Pickup notes to driver in the booking payload. */
function HandlePickupNotesToDriverChange(state: BookingWorkFlowState, notes: string): BookingWorkFlowState {
    return {
        ...state,
        PickupNotesToDriver: notes,
    };
}

/** Update Dropoff notes to driver in the booking payload. */
function HandleDropoffNotesToDriverChange(state: BookingWorkFlowState, notes: string): BookingWorkFlowState {
    return {
        ...state,
        DropoffNotesToDriver: notes,
    };
}

/** Clear Pickup notes to driver in the booking payload. */
function ClearPickupNotesToDriverChange(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        PickupNotesToDriver: null,
    };
}

/** Clear Dropoff notes to driver in the booking payload. */
function ClearDropoffNotesToDriverChange(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        DropoffNotesToDriver: null,
    };
}

/** Handler for the PickupServiceCheckUpdate action. This will update the PickupServiceCheck data. */
function HandlePickupServiceCheckUpdate(bookingState: BookingWorkFlowState, serviceabilityDetails: PickupServiceabilityDetails): BookingWorkFlowState {

    // invalid updates get ignored
    if (!IsProposedStateChangeValid(bookingState, serviceabilityDetails.PickupPlaceId, serviceabilityDetails.ServiceabilityCheckState)) {
        return bookingState;
    }

    return {
        ...bookingState,
        PickupServiceCheck: serviceabilityDetails.ServiceabilityCheckState,
    };
}

/** This is turning into a mini reducer for CreateBookingPayload.PickupServiceCheck. Should i be using recompose or something?? */
function IsProposedStateChangeValid(currentBookingState: BookingWorkFlowState, pickupAddressPlaceId: string, proposedNewState: PickupServiceCheckState): boolean {

    // check is only fitting to BookingController
    if (FeatureFlags.BookingApiV2) return true;

    // updates to indeterminate state are always OK
    if ((proposedNewState.status === ServiceCheckStatus.CheckInProgress) || (proposedNewState.status === ServiceCheckStatus.NoInputSelected)) {
        return true;
    }

    // result update: only honour if it still matches the pickup address
    const existingPickup = currentBookingState.PickupV2;
    return !!existingPickup && existingPickup.GoogleMapsPlaceId === pickupAddressPlaceId;
}

/** Handle a EnableDiagnosticUI action. Just sets the flag to true. */
function HandleEnableDiagnosticUI(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        ShowDiagnosticUI: true,
    };
}

/** Enable/disable Book on account feature based on the given value */
function HandleSetBookOnAccount(state: BookingWorkFlowState, newValue: boolean): BookingWorkFlowState {
    return {
        ...state,
        IsOnAccount: newValue
    };
}

/** Handle the Payment option change for Booking Payload */
function HandlePaymentOptionChange(state: BookingWorkFlowState, selectedPaymentOption: PaymentOption): BookingWorkFlowState {
    return {
        ...state,
        PaymentOption: selectedPaymentOption
    };
};

/** Handle updating account details for bookings on account. */
function HandleAccountBookingPayloadChange(state: BookingWorkFlowState, accountDetails: AccountBookingPayload): BookingWorkFlowState {
    return {
        ...state,
        AccountData: accountDetails,
    };
}

/** Handle clear account details when Book on account is disabled. */
function HandleClearAccountDetails(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        AccountData: null
    };
}

/** Handle for setting device data for Booking Payload */
function HandleSetDeviceData(state: BookingWorkFlowState, deviceData: string): BookingWorkFlowState {
    return {
        ...state,
        DeviceData: deviceData
    };
};

/** Handle the Template name */
function HandleTemplateNameChange(state: BookingWorkFlowState, name: string): BookingWorkFlowState {
    return {
        ...state,
        TemplateName: name,
    };
}

/**
 * Convert PlaceResult to AddressV2
 */
function ConvertPlaceResultToAddressV2(result: PlaceResult): AddressV2 {

    const geoLocation: ApiGeoPoint = {
        Latitude: result.address.Lat,
        Longitude: result.address.Long,
    };

    const addressV2Details = GooglePlaceToAddressV2Details(result.place);

    const addressV2: AddressV2 = {
        ...addressV2Details,
        FullTextAddress: result.address.PlaceText,
        GeoLocation: geoLocation,
        GoogleMapsPlaceId: result.place.place_id!,
    }

    return addressV2;
}

/** Clear the Payment option on the booking payload */
function ClearPaymentOption(state: BookingWorkFlowState): BookingWorkFlowState {

    const { PaymentOption, ...newCreatePayload } = state;

    return newCreatePayload;
};

/** Update selected favourite address details when selected as pickup address. */
function RefreshPickupFavouriteAddress(state: BookingWorkFlowState, addressDetails: AddressPointDetails): BookingWorkFlowState {
    return {
        ...state,
        FavouriteAddress: {
            ...state.FavouriteAddress,
            Pickup: addressDetails
        }
    }
}

/** Update selected favourite address details when selected as dropoff address. */
function RefreshDropoffFavouriteAddress(state: BookingWorkFlowState, addressDetails: AddressPointDetails): BookingWorkFlowState {
    return {
        ...state,
        FavouriteAddress: {
            ...state.FavouriteAddress,
            Dropoff: addressDetails
        }
    }
}

/** Clear favourite address details from FavouriteAddress.Pickup */
function ClearPickupFavouriteAddress(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        FavouriteAddress: {
            ...state.FavouriteAddress,
            Pickup: null
        }
    }
}

/** Clear favourite address details from FavouriteAddress.Dropoff */
function ClearDropoffFavouriteAddress(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        FavouriteAddress: {
            ...state.FavouriteAddress,
            Dropoff: null
        }
    }
}

/** Handle the delivery option change for Booking Payload */
function HandleDeliveryOptionChange(state: BookingWorkFlowState, selectedDeliveryOptionValue: DeliveryOptionKind): BookingWorkFlowState {
    return {
        ...state,
        DeliveryOption: selectedDeliveryOptionValue
    };
};

/** Set the error message if delivery option not selected when new parcel booking */
function SetDeliveryOptionErrorMessage(state: BookingWorkFlowState, errorMessage: string | null): BookingWorkFlowState {
    return {
        ...state,
        DeliveryOptionError: errorMessage
    }
}

/** clear dropoff contact name  in store. */
function HandleClearDropoffContactName(state: BookingWorkFlowState): BookingWorkFlowState {

    return {
        ...state,
        DropoffAdditionalDetails: {
            ...state.DropoffAdditionalDetails,
            ContactName: ""
        }
    }
}

/** update dropoff contact name  in store. */
function HandleDropoffContactName(state: BookingWorkFlowState, contactName: string): BookingWorkFlowState {

    return {
        ...state,
        DropoffAdditionalDetails: {
            ...state.DropoffAdditionalDetails,
            ContactName: contactName
        }
    }
}

/** update dropoff contact number  in store. */
function HandleDropoffContactNumber(state: BookingWorkFlowState, contactNumber: UserContactNumberInfo): BookingWorkFlowState {

    return {
        ...state,
        DropoffAdditionalDetails: {
            ...state.DropoffAdditionalDetails,
            ContactNumber: contactNumber
        }
    }
}

/** clear dropoff contact number  in store. */
function HandleClearDropoffContactNumber(state: BookingWorkFlowState): BookingWorkFlowState {

    return {
        ...state,
        DropoffAdditionalDetails: {
            ...state.DropoffAdditionalDetails,
            ContactNumber: {
                Contactnumber: "",
                CountryInfo: Config.DefaultCountryInfo,
            }
        }
    }
}

/** update pickup contact name  in store. */
function HandlePickupContactName(state: BookingWorkFlowState, contactName: string): BookingWorkFlowState {

    return {
        ...state,
        PickupAdditionalDetails: {
            ...state.PickupAdditionalDetails,
            ContactName: contactName
        }
    }
}

/** clear pickup contact name  in store. */
function HandleClearPickupContactName(state: BookingWorkFlowState): BookingWorkFlowState {

    return {
        ...state,
        PickupAdditionalDetails: {
            ...state.PickupAdditionalDetails,
            ContactName: ""
        }
    }
}

/** update pickup contact number  in store. */
function HandlePickupContactNumber(state: BookingWorkFlowState, contactNumber: UserContactNumberInfo): BookingWorkFlowState {

    return {
        ...state,
        PickupAdditionalDetails: {
            ...state.PickupAdditionalDetails,
            ContactNumber: contactNumber
        }
    }
}

/** clear pickup contact number  in store. */
function HandleClearPickupContactNumber(state: BookingWorkFlowState): BookingWorkFlowState {

    return {
        ...state,
        PickupAdditionalDetails: {
            ...state.PickupAdditionalDetails,
            ContactNumber: {
                Contactnumber: "",
                CountryInfo: Config.DefaultCountryInfo,
            }
        }
    }
}