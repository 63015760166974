import React from 'react';
import { ParcelCard } from './ParcelCard';
import { FavouritesCard } from './FavouritesCard';
import { FeatureCardProps, FeatureId } from './FeaturesEntities';
import { DriverProfile } from './DriverProfile';
import { MultipleTracking } from './MultipleTracking';
import { SearchFavourites } from './SearchFavourites';
import { DriverVaxed } from './DriverVaxed';
import SaveAddresses from './SaveAddresses';

/**
 * Displays feature cards
 */
export const FeatureCard = (props: FeatureCardProps) => {

    switch (props.FeatureId) {

        case FeatureId.Favourites:
            return <FavouritesCard {...props} />
        
        case FeatureId.Parcel:
            return <ParcelCard {...props} />

        case FeatureId.MultipleTracking:
            return <MultipleTracking {...props} />

        case FeatureId.DriverProfile:
            return <DriverProfile {...props} />     
            
        case FeatureId.SearchYourFavourites:
            return <SearchFavourites {...props} />
        
        // case FeatureId.DriverVaxed:
        //         return <DriverVaxed {...props} />   
        
        case FeatureId.SaveAddresses:
            return <SaveAddresses {...props} />

        default:
            return null;
    }
} 