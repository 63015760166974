import React from 'react';
import { connect } from "react-redux";
import $ from 'jquery';
import { ApplicationState } from '../../appState';
import "./HeaderProfilePanel.scss";
import { LoginStatusKind, LogoutKind } from "../../modules/Authentication/AuthEntities";
import { ContentURL, getContentUrl } from '../../modules/Utils/ContentURL';
import Auth from "../../modules/Authentication/AuthImpl";
import { CheckAddPlus } from '../../utils/Formattingutil';
import { Link } from "react-router-dom";
import { Dispatch } from '../../modules/Dispatch';
import { SimpleUserProfile } from '../../modules/User/ProfileEntitiesV2';
import { GetBrandedUrl, BrandedImage } from '../../modules/Utils/BrandedContentUrls';
import { FeatureFlags } from '../../Config/FeatureFlags';
import { B2CLogout } from '../../modules/AuthV2/AuthV2Testing';

interface HeaderProfilePanelProps {
    UserProfile?: SimpleUserProfile;  
    LoginStatus: LoginStatusKind;
    IsProfilePanelShown: boolean;
}

/** Displays a card UI summarising the user's profile, floating in the top right corner of the screen, just below the login box in the header. */
class HeaderProfilePanel extends React.Component<HeaderProfilePanelProps> {

    /** A reference to the DOM element for the Div at the root of this panel */
    private rootDivRef: React.RefObject<HTMLDivElement>;

    constructor(props: HeaderProfilePanelProps) {
        super(props);
        this.rootDivRef = React.createRef();
    }

    UNSAFE_componentWillReceiveProps(nextProps: HeaderProfilePanelProps) { 
        if (nextProps.IsProfilePanelShown) $(this.rootDivRef.current!).slideDown(300).css("display", "block"); 
        else $(this.rootDivRef.current!).slideUp(300);
    }

    signOut = () => {

        if (FeatureFlags.AuthV2) {
            B2CLogout();
        }
        else {
            new Auth().logout(LogoutKind.WebsiteAndAuth0);
        }
    }
    closeProfilePanelShown = () => { Dispatch.Auth.HideProfilePanel(); }

    render() {
        const userProfile = this.props.UserProfile;
        
        return (
          <div ref={this.rootDivRef} className="header-profile-panel">
                <div className="header-profile-container">
                    <div className="header-profile-panel-clear">
                        <img onClick={this.closeProfilePanelShown} src={GetBrandedUrl(BrandedImage.CloseIcon)} width="14px" height="auto" />
                    </div>
                    {userProfile != null && <div className="header-profile-panel-image"><img src={userProfile.PictureUrl} /></div>}
                    {userProfile != null && <div className="header-profile-panel-namecard">
                      <span className="header-profile-panel-namecard-title">{userProfile.DisplayName}</span>
                      <span className="header-profile-panel-namecard-email">{userProfile.Email}</span>
                    </div>}
                    <hr className="key-line" />
                    {userProfile != null && userProfile.ContactPhone != "" && <div className="header-profile-panel-info">
                        <div className="singleMenu">
                            <img src={getContentUrl(ContentURL.images.UserProfile.PhoneBlack)} width="22px" height="22px" />
                            <div className="singleMenuName">{CheckAddPlus(userProfile.ContactPhone)} </div></div>
                        <div className="singleMenu">
                            <img src={getContentUrl(ContentURL.images.UserProfile.MyProfileIcon)} width="22px" height="22px" />
                            <Link to="/userprofile" onClick={this.closeProfilePanelShown} className="singleMenuNameLink">My Profile </Link></div>
                    </div>
                    }
              <button onClick={this.signOut}>Sign out</button>
            </div>
          </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): HeaderProfilePanelProps {
    return {
        UserProfile: state.authentication.UserProfile,
        LoginStatus: state.authentication.LoginStatus,
        IsProfilePanelShown: state.authentication.IsProfilePanelShown
    };
}

export default connect(mapStateToProps)(HeaderProfilePanel);