import { Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { RouteUrls } from "../../RouteUrls";
import BookingDetails from "../Booking/BookingDetails";

/**
 * This is a wrapper of fare estimator form for desktop view.
 */
export const FareEstimatorForm: React.FC = () => {
	const history = useHistory();

	function GoToBookingWidget() {
		history.push(RouteUrls.BookingWidget);
	}

	return (
	<div className="booking-form-panel" style={{ top: 35 }}>
		<div className="booking-form-content">
			<div className="fare-estimator-title">
				<h2>Fare Estimate Calculator</h2>
				<p>Please enter a valid pickup and destination.</p>
			</div>
			<BookingDetails />
			<div className="booking-btn-panel">
				<Button
					variant="contained"
					size="medium"
					color="primary"
					onClick={GoToBookingWidget}
				>
				Next
				</Button>
			</div>
		</div>
	</div>
	);
};
