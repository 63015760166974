import React from "react";
import "./NewBooking.scss";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import NewBookingForm from "./NewBookingForm";
import CardRegistration from "../Cards/CardRegistration";
import { BookingWidgetModeKind } from "../BookingTemplate/BookingTemplateEntities";
import BookingPageLoading from "./Widget/BookingPageLoading";
import BookingButton from "./Widget/BookingButton";
import { TncFooter } from "./Widget/TncFooter";
import { BookingFormKind } from "../UILogicControl/UILogicControlEntities";
import ParcelBookingForm from "./Parcel/ParcelBookingForm";
import { MaybeBookingTemplateNameField } from "./OptionalParts/MaybeBookingTemplateNameField";
import { MaybeParcelModeSelector } from "./OptionalParts/MaybeParcelModeSelector";
import { MaybeAccountOption } from "./OptionalParts/MaybeAccountOption";

/**
 * The booking edit widget in desktop layout mode.
 */
const NewBooking : React.FC<ReduxProps> = (props) => {
    
    const isTemplateModeOn = props.BookingWidgetMode !== BookingWidgetModeKind.Booking;
    const isParcelMode = props.ActiveBookingWidget === BookingFormKind.ParcelBooking;

    return (
        <div>            
            <div className="booking-form-panel">  
                <div className="booking-form-content">

                    <MaybeBookingTemplateNameField />
                    <MaybeAccountOption />
                    <MaybeParcelModeSelector />
                    {isParcelMode ? <ParcelBookingForm /> : <NewBookingForm /> }
                    <BookingButton />
                </div>
                {!isTemplateModeOn && <TncFooter /> }      
            </div>

            {props.IsCardRegistrationPanelOpen && <CardRegistration CardRegistrationPanelParent="BookingForm" />}  
            <BookingPageLoading/>              
        </div>           
    );
}

interface ReduxProps {
    BookingWidgetMode: BookingWidgetModeKind;
    IsCardRegistrationPanelOpen: boolean;
    ActiveBookingWidget: BookingFormKind;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        IsCardRegistrationPanelOpen: state.payment.IsCardRegistrationPanelOpen,
        ActiveBookingWidget: state.uiLogicControl.BookingForm.ActiveBookingForm
    };
}

export default connect(GetPropsFromRedux)(NewBooking);