import moment, { Moment, Duration } from "moment";

/** Describes the two booking refresh cycles */
export enum BookingRefreshCycle {

    /** Long interval timer (around 1min) where all bookings are refreshed */
    AllBookings = 1,

    /** Short interval timer (around 10s) where only dispatching or active bookings are refreshed */
    DispatchingOnly = 2,

    /** Very short timer, around 2s, vehicle locations only. V2 API only. */
    ActiveVehiclesOnly = 3,
}

/** Refresh rate for the long interval (inactive bookings) */
const longRefreshInterval = moment.duration(1, "minute");

/** Refresh rate for the short interval (dispatching / active bookings) */
const shortRefreshInterval = moment.duration(10, "seconds");

/** For fast V2 vehicle location polling only */
const tinyRefreshInterval = moment.duration(3, "seconds");

/** When each booking refresh cycle last occurred. Initially empty. */
const lastRefreshTimes: Partial<Record<BookingRefreshCycle, Moment>> = { }

/** Gets the refresh cycle that is currently (over)due, or null if there is no refresh due. */
export function GetDueRefreshCycle(): BookingRefreshCycle | null {

    // long cycle (all bookings) takes preference
    if (IsCycleDue(BookingRefreshCycle.AllBookings, longRefreshInterval)) {
        return BookingRefreshCycle.AllBookings;
    }

    // short cycle
    if (IsCycleDue(BookingRefreshCycle.DispatchingOnly, shortRefreshInterval)) {
        return BookingRefreshCycle.DispatchingOnly;
    }

    // tiny cycle
    if (IsCycleDue(BookingRefreshCycle.ActiveVehiclesOnly, tinyRefreshInterval)) {
        return BookingRefreshCycle.ActiveVehiclesOnly;
    }

    // all on cooldown
    return null;
}

/** Returns true if the current cycle is due for a refresh.
 *  Either it has never refreshed before, or the interval between refreshes has elapsed. */
function IsCycleDue(cycle: BookingRefreshCycle, interval: Duration): boolean {

    const lastRefreshForThisCycle = lastRefreshTimes[cycle];
    if (!lastRefreshForThisCycle) return true;

    const nextScheduledTime = lastRefreshForThisCycle.clone().add(interval);
    return moment().isAfter(nextScheduledTime);
}

/** Take note that a refresh is occurring. Log the time in order to track when it's next due. */
export function NoteRefreshOccurring(cycle: BookingRefreshCycle) {
    lastRefreshTimes[cycle] = moment();

    // a longer cycle will override (also refresh) a shorter cycle
    if (cycle == BookingRefreshCycle.AllBookings) {
        lastRefreshTimes[BookingRefreshCycle.DispatchingOnly] = moment();
        lastRefreshTimes[BookingRefreshCycle.ActiveVehiclesOnly] = moment();
    }

    if (cycle == BookingRefreshCycle.DispatchingOnly) {
        lastRefreshTimes[BookingRefreshCycle.ActiveVehiclesOnly] = moment();
    }
}