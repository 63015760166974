import { Api } from "../../Services/Api";
import { FeatureInfo, FeatureOptInRequest } from "../../Services/FeaturesContracts";
import { Dispatch } from "../Dispatch";
import { PreviewFeatureId } from "./FeatureEntities";

/** Operations to manage server-managed preview features. */
export namespace PreviewFeatures {

    /** Reload the preview feature list from the API and update redux state. */
    export async function Reload() {

        const result = await Api.Features.GetMyFeatures();
        if (!result.isSuccess) return;

        // drop records defined by the API but not supported on the client
        const supportedFeatures = result.value.filter(i => IsSupported(i.FeatureId));

        Dispatch.Features.RefreshPreviewFeatures(supportedFeatures);
    }

    /**
     * Change the opt in/out state of the feature.
     * It will reload the full set of features after this as a way to update redux state.
     */
    export async function ToggleOptInState(feature: FeatureInfo) {

        const request: FeatureOptInRequest = {
            FeatureId: feature.FeatureId,
            UserOptIn: !feature.IsEnabled
        }

        const result = await Api.Features.OptInFeature(request);

        if (result.isSuccess) {
            await Reload();
        }
    }

    /**
     * Tests whether a feature ID received from the API is understood / supported by the website.
     * This is equivalent to testing whether it matches a defined value in the PreviewFeatureId enum.
     */
    export function IsSupported(featureId: number): boolean {

        return !!WellKnownDescriptions[featureId];
    }

    /**
     * Gets a human-readable descriptive text for the specified feature.
     */
    export function GetDescription(feature: FeatureInfo): string {

        return WellKnownDescriptions[feature.FeatureId] ?? feature.Name;
    }

    /** 
     *  Explanations of the preview features as they will appear on the UI.
     *  This is also used to test whether a feature ID (number) received from the API is understood (supported) by the website, so don't make it Partial.
     */
    const WellKnownDescriptions: Record<PreviewFeatureId, string> = {

        [PreviewFeatureId.ParcelDeliveryWidget]: "Access our new parcel delivery feature to add pickup and dropoff contact details and driver notes for each location."
    }
}