import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";

export interface AuthV2State {

    /** I'm just guessing at this point */
    IsLoggedIn: boolean;

    /** Get Active Account */
    Account: AccountInfo | null;

    /** ID Token that you get from logging in. */
    LoginToken: AuthenticationResult | null;

    /** Access Token that you get for the API. */
    AccessToken: AuthenticationResult | null;
}

/** Initial value for AuthV2State */
export const InitialAuthV2State: AuthV2State = {
    IsLoggedIn: false,
    LoginToken: null,
    Account: null,
    AccessToken: null,
};