import { BookingAuthority } from "./Services/BookingEntities";
import { AuthToken } from "./modules/Authentication/AuthEntities";
import { FeatureId } from "./modules/WhatsNew/FeaturesEntities";
import { SimpleUserProfile } from "./modules/User/ProfileEntitiesV2";
import { BookingInfo } from "./modules/MyBookings/MyBookingEntities";
import { BookingLink } from "./modules/TrackingLinks/Redux/TrackingLinksState";
import { UserProfileV2 } from "./Services/UserContractsV2";

/** Keys for data that will be kept in local storage. The string values must be unique. 
 */
enum LocalStorageItemKeys {
    /** Auth0 token */
    authToken = 'authToken',

    /** Obsolete key, no longer used. This entry is left as a reminder not to reuse this key in future, since there may be some old data for some users. */
    userProfile = 'userProfile_V1',

    /** User profile V2 */
    userProfileV2 = 'userProfile_V2',

    /** List of the user's bookings.
     * NOTE: This is obsolete. The type of this list has been changed from BookingTrackingResult to BookingInfo. Use myBookingsV2 instead.
     * */
    myBookings = 'myBookings',

    /** List of the features acknowledged by the user */
    acknowledgedFeatures = 'acknowledgedFeatures',

    /** Obsolete key. Persisted shared booking links */
    trackingLinks = 'trackingLinks',

    /** List of persisted bookings */
    myBookingsV2 = 'myBookingsV2',

    /** Persisted shared booking links in V2 format (with link version.) */
    trackingLinksV2 = 'trackingLinksV2',

    /** Snackbar that acknowledged by the user */
    HasSeenPriceGuaranteeTip = 'HasSeenPriceGuaranteeTip',

    /** Price Guarantee Modal FAQ that acknowledged by the user */
    HasSeenPriceGuaranteeModalFAQ = 'HasSeenPriceGuaranteeModalFAQ',

    /** User details of the users in the management database. i.e. Azure B2C users */
    MgmtUserProfile = "MgmtUserProfile",
}

/** A strongly typed wrapper over local storage. The data type being stored is {T}.
 * There will be one instance of this class for each data set in local storage.
 * See the exports at the bottom of this file for details! */
class StorageAgent<T> {

    private key: LocalStorageItemKeys;

    constructor(key: LocalStorageItemKeys) {
        this.key = key;
    }

    /** Retrieve the data from local storage. May return null if there was nothing in the first place. */
    LoadData(): T | null {
        const textData = localStorage.getItem(this.key);
        if (textData === null) return null;

        const realData = JSON.parse(textData) as T;
        return realData;
    }

    /** Persist the data into local storage. */
    StoreData(data: T) {
        const text = JSON.stringify(data);
        localStorage.setItem(this.key, text);
    }

    /** Remove the data into local storage. */
    ClearData() {
        localStorage.removeItem(this.key);
    }
}

/** All the application data persisted in HTML local storage */
export const MyStorage = {

    /** The user's bookings. Important for guest users, for which this is the only persistence mechanism */
    Bookings: new StorageAgent<BookingInfo[]>(LocalStorageItemKeys.myBookingsV2),

    /** The user's last Auth0 token details. Required to restore a login session */
    AuthToken: new StorageAgent<AuthToken>(LocalStorageItemKeys.authToken),

    /** User profile (V2). For a smoother startup experience. */
    UserProfileV2: new StorageAgent<SimpleUserProfile>(LocalStorageItemKeys.userProfileV2),

    /** Which "What's New" features the user has acknowledged */
    FeatureList: new StorageAgent<FeatureId[]>(LocalStorageItemKeys.acknowledgedFeatures),

    /** Obsolete, will be removed in future. Booking tracking links collected from Share Booking URL loads */
    TrackingLinks: new StorageAgent<BookingAuthority[]>(LocalStorageItemKeys.trackingLinks),

    /** Booking tracking links collected from Share Booking URL loads */
    TrackingLinksV2: new StorageAgent<BookingLink[]>(LocalStorageItemKeys.trackingLinksV2),

    /** Snackbar features the user has acknowledged */
    HasSeenPriceGuaranteeTip: new StorageAgent<boolean>(LocalStorageItemKeys.HasSeenPriceGuaranteeTip),

    /** Price Guarantee Modal FAQ the user has acknowledged */
    HasSeenPriceGuaranteeModalFAQ: new StorageAgent<boolean>(LocalStorageItemKeys.HasSeenPriceGuaranteeModalFAQ),

    /** User details of the users in the management database. i.e. Azure B2C users */
    MgmtUserProfile: new StorageAgent<UserProfileV2>(LocalStorageItemKeys.MgmtUserProfile),
};
