import appstore from "../../appStore";
import { DataLoadingStatus, FareRequestInput, VehicleTypeV2 } from "./Redux/ConditionEntities";
import { ServiceCheckStatus } from "../Booking/BookingEntities";
import { FareEstimateRequestV1, FareLocation, TaxiFareEstimateRequestV2, TaxiFareEstimateResponseV2, TaxiFareResponseV2 } from "../../Services/FareEntities";
import { ApplicationState } from "../../appState";
import { ComputeAsyncLoadState } from "./ComputeAsyncLoadStats";
import { RoundTime } from '../Fare/FareHelper';
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { AsyncUpdateOutcome } from "./AsyncUpdateOutcome";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { ServiceResult } from "../../Services/ServiceEntities";
import { ConsiderToShowPriceGuaranteeTip } from "../Booking/Widget/ConsiderToShowPriceGuaranteeTip";
import { DateTime } from "luxon";

/** Check whether it is possible to do a Fare data load, then perform it if necessary. 
 Returns undefined when no action will occur due to prerequisites not being met.
 Otherwise, returns a promise that can be awaited when the work completes.  
 NOTE: Since we allow long distance bookings, we now request fare estimates for long distance trips. */
export async function ConsiderFareUpdate(): Promise<AsyncUpdateOutcome> {

    const appState = appstore.getState();

    // input not ready?
    const proposedInput = ComputeFareRequestInput(appState);
    if (proposedInput === null) return AsyncUpdateOutcome.InputsNotReady;
    
    const pickupCheck = appState.booking.PickupServiceCheck;
    if (pickupCheck.status !== ServiceCheckStatus.KnownGood) return AsyncUpdateOutcome.InputsNotReady;
    
    if (!FeatureFlags.BookingApiV2) {
        // requisite: conditions loaded OK
        const conditionLoad = appState.condition.LoadingStatus;
        if (conditionLoad.Status !== DataLoadingStatus.Idle) return AsyncUpdateOutcome.InputsNotReady;
        if (conditionLoad.LastInput === null) return AsyncUpdateOutcome.InputsNotReady;
        if (conditionLoad.LastInput !== pickupCheck.suburbId) return AsyncUpdateOutcome.InputsNotReady;
    }

    // No need to load fare if the selected time is in past. The UI displays an error for this and booking is blocked.
    if ((proposedInput.Time.IsImmediate === false) && proposedInput.Time.RequestedDate < DateTime.now()) {
        return AsyncUpdateOutcome.InputsNotReady;
    }

    // drop identical requests (only if fare is available from the previous request)
    const currentStatus = appState.condition.FareLoadStatus;

    if (currentStatus.LastInput && AreInputsEqual(currentStatus.LastInput, proposedInput) && (currentStatus.Status !== DataLoadingStatus.Error) && appState.condition.SelectedCondition.Fare) {
        return AsyncUpdateOutcome.InputsUnchanged;
    }

    // OK! (return awaitable promise)
    return await PerformFareLoad(proposedInput);
}

/** Generates a FareRequestInput from the current store. Returns null if any required input is null. */
function ComputeFareRequestInput(appState: ApplicationState): FareRequestInput | null {

    const pickup = appState.booking.PickupV2;
    if (!pickup) return null;

    const dropoff = appState.booking.DropoffV2;
    if (!dropoff) return null;

    return {
        Pickup: pickup,
        Dropoff: dropoff,
        Time: appState.booking.BookingTimeV2,
    };
}

/** Returns true if the following FareRequestInput objects are identical. Ths is used to prevent duplicate loads. It's a bit involved since there are three fields to check. */
function AreInputsEqual(existing: FareRequestInput, other: FareRequestInput): boolean {

    if (existing.Pickup.GoogleMapsPlaceId !== other.Pickup.GoogleMapsPlaceId) return false;
    if (existing.Dropoff.GoogleMapsPlaceId !== other.Dropoff.GoogleMapsPlaceId) return false;
    if (existing.Time.IsImmediate !== other.Time.IsImmediate) return false;

    // future bookings: check time component
    if (!existing.Time.IsImmediate && !other.Time.IsImmediate) {

        if (RoundTime(existing.Time.RequestedDate).equals(RoundTime(other.Time.RequestedDate)) === false) {
            return false;
        }
    }

    // all good!
    return true;
}

/** Perform the GetFare API call, including setting the state to in progress and then complete / error. */
async function PerformFareLoad(input: FareRequestInput): Promise<AsyncUpdateOutcome> {

    // Clear old fare estimate
    Dispatch.Condition.ClearFareEstimate();

    Dispatch.Condition.FareLoadStatus({ Status: DataLoadingStatus.InProgress, LastInput: input });

    let result: ServiceResult<TaxiFareEstimateResponseV2 | TaxiFareResponseV2>;

    if (FeatureFlags.BookingApiV2) {
        result = await ApplyFareEstimateHandlerV2();
    }
    else {
        result = await ApplyFareEstimateHandlerV1(input);
    }

    // check for input drift, which would make the result no longer meaningful
    const inputNow = ComputeFareRequestInput(appstore.getState());
    if ((inputNow === null) || !AreInputsEqual(inputNow, input)) {
        return AsyncUpdateOutcome.InputChangedDuringLoad;
    }

    // update loading state
    const newState = ComputeAsyncLoadState(result, input);
    Dispatch.Condition.FareLoadStatus(newState);

    // update actual data
    if (result.isSuccess) {
        SaveFareInStore(result.value);
        ConsiderToShowPriceGuaranteeTip();
        return AsyncUpdateOutcome.Success;
    } 
    else {
        return AsyncUpdateOutcome.LoadFailed;
    }
}

/** Save the fare details into the condition list */
function SaveFareInStore(fareResponse: TaxiFareEstimateResponseV2 | TaxiFareResponseV2): void {
    
    if (FeatureFlags.BookingApiV2) 
    {
        Dispatch.Condition.ApplyFareEstimateV2(<TaxiFareEstimateResponseV2>fareResponse);
    }
    else {
        Dispatch.Condition.ApplyFareEstimate(<TaxiFareResponseV2>fareResponse);
    }
}

/**
 * Call the V1 (Booking API) for getting the fare estimate
 */
async function ApplyFareEstimateHandlerV1(input: FareRequestInput): Promise<ServiceResult<TaxiFareResponseV2>> {

    // Intentionally using V2 time because it has the proper format and both V1 and V2 have the same value.
    const bookingTime = appstore.getState().booking.BookingTimeV2;

    // NULL for now bookings.
    let departureTime: string | null = null;

    if (bookingTime.IsImmediate === false) {
        departureTime = bookingTime.RequestedDate.toISO();
    }

    const pickupLocation: FareLocation = {
        Latitude: input.Pickup.GeoLocation.Latitude,
        Longitude: input.Pickup.GeoLocation.Longitude,
        GoogleMapsPlaceId: input.Pickup.GoogleMapsPlaceId
    };

    const dropoffLocation: FareLocation = {
        Latitude: input.Dropoff.GeoLocation.Latitude,
        Longitude: input.Dropoff.GeoLocation.Longitude,
        GoogleMapsPlaceId: input.Dropoff.GoogleMapsPlaceId
    };    

    const request: FareEstimateRequestV1 = {
        Pickup: pickupLocation,
        Dropoff: dropoffLocation,
        PickupSuburbId: appstore.getState().condition.LoadingStatus.LastInput!, // This method is called only if LastInput is not null.
        DepartureTime: departureTime,
    };

    return await Api.Fare.GetFareV2(request);
}

/**
 * Call the V2 (GB API) for getting the fare estimate
 */
async function ApplyFareEstimateHandlerV2(): Promise<ServiceResult<TaxiFareEstimateResponseV2>> {
    
    const { PickupV2, DropoffV2, BookingTimeV2 } = appstore.getState().booking;

    const bookingDetails: TaxiFareEstimateRequestV2 = {
        Pickup: PickupV2!,
        Dropoff: DropoffV2!,
        VehicleType: VehicleTypeV2.StandardTaxi
    }

    // Future booking
    if (BookingTimeV2?.IsImmediate === false && BookingTimeV2?.RequestedDate) {
        bookingDetails.FutureRequestedTime = BookingTimeV2.RequestedDate.toISO();
    }

    return await Api.MakeBooking.GetFare(bookingDetails);
}