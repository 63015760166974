import { Checkbox } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux';
import { ApplicationState } from '../../../appState';
import { SimpleUserProfile } from '../../User/ProfileEntitiesV2';
import { ClearDropoffContactAndName, ClearPickupContactAndName, UpdateDropoffContactAndName, UpdatePickupContactAndName } from '../Parcel/BookingContactUtil';
import './BookingUseCompanyDetails.scss';

interface BookingCompanyDetailsProps {
    IsPickup: boolean;
}
interface BookingCompanyDetailsStoreProps {
    UserProfile?: SimpleUserProfile;
}

/** This component have checkbox to use company details for pickup or drop-off while making Parcel booking.  */  
const BookingUseCompanyDetails: React.FC<BookingCompanyDetailsProps & BookingCompanyDetailsStoreProps> = (props) => {

	const label = props.IsPickup ? "Use company details for pickup" : "Use company details for drop-off";

	const OnUseCompanyDetails = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		
		const ContactName = props.UserProfile!.ContactName;
		const ContactPhone = props.UserProfile!.ContactPhone;

		if (props.IsPickup) {
			if (checked) {
            	UpdatePickupContactAndName(ContactName, ContactPhone);
			} else {
				ClearPickupContactAndName();
			}
        }
        else {
			if (checked) {
				UpdateDropoffContactAndName(ContactName, ContactPhone);
			} else {
				ClearDropoffContactAndName()
			}
        }
	}

	if (!props.UserProfile) return null;

	return (
		<div>
			<Checkbox onChange={OnUseCompanyDetails} />
			<span style={{ marginLeft: -5}}>
				{label}
			</span>
		</div>
	)
}

function mapStateToProps(state: ApplicationState): BookingCompanyDetailsStoreProps {
    return {
        UserProfile: state.authentication.UserProfile,
    }
}

export default connect(mapStateToProps)(BookingUseCompanyDetails); 
