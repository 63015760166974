import React from 'react';
import { SwitchToQuickBoookTab } from './FeaturesHelper';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { Link } from 'react-router-dom';
import { SignUpLink } from './SignUpLink';
import AcknowledgeFeatureButton from './AcknowledgeFeatureButton';
import FeatureCloseButton from './FeatureCloseButton';
import { Typography } from '@material-ui/core';

export const SearchFavourites = (props: FeatureCardProps) => {

    return (
        <React.Fragment key={FeatureId.SearchYourFavourites}>
            <div className="feature-card">
                <FeatureCloseButton {...props} />
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>22.07.2021</span>
                </div>
                <Typography variant="h6" color="primary">SEARCH YOUR FAVOURITES</Typography>
                <Typography variant="body2" gutterBottom>Search and select from your favourites to quick book your trips. <Link to={"/"} rel="noopener" className="feature-link" onClick={() => SwitchToQuickBoookTab()}>Click here</Link> to see. </Typography>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.SearchFavourites)} alt="Search Favourites" />
                </div>
                    <SignUpLink />
                    <AcknowledgeFeatureButton {...props} />
            </div>
        </React.Fragment>
    );
}
