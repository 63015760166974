import React from "react";
import { connect } from "react-redux";
import "./MobileBookingForm.scss";
import BookingConditionManager from "../../Condition/UI/BookingConditionManager";
import BookingAddressGroup from "../Widget/BookingAddressGroup";
import BookingPageLoading from "../Widget/BookingPageLoading";
import BookingInputStyling from "../FormSteps/BookingInputStyling";
import BookingButton from "../Widget/BookingButton";
import { BookingFavouriteAddress } from '../BookingEntities';
import BookingDateTime from "../BookingDateTime";
import { ApplicationState } from "../../../appState";
import TrackVisibility from 'react-on-screen';
import { TrackVisibilityBlock } from "./TrackVisibilityBlock";
import { GoogleMapWrapped } from "../../../widgets/google-maps/GoogleMapWrapped";
import GoogleMapWrapperWithGps from "./GoogleMapWrapperWithGps";
import $ from "jquery";
import { TncFooter } from "../Widget/TncFooter";
import { BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import { FareEstimateSnackbar } from "../Widget/FareEstimateSnackbar";
import { SnackbarForPriceGuaranteeTip } from "../Widget/SnackbarForPriceGuaranteeTip";
import { DriverNotesSection } from "../Widget/DriverNotesSection";
import { BookingLocationContactName } from "../Widget/BookingLocationContactName";
import { BookingLocationContactNumber } from "../Widget/BookingLocationContactNumber";
import { ContactKind } from "../../BookingTemplate/BookingTemplateEntities";
import BookingAddressComponent from "../BookingAddressComponent";
import NotesToDriver from "../Widget/NotesToDriver";
import { DriverNotesType } from "../../AddressPoints/Redux/AddressPointsEntities";
import BookingUseCompanyDetails from "../Widget/BookingUseCompanyDetails";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import { MaybeParcelModeSelector } from "../OptionalParts/MaybeParcelModeSelector";
import { MaybeAccountDetails } from "../OptionalParts/MaybeAccountDetails";
import { MaybeCardSelector } from "../OptionalParts/MaybeCardSelector";
import { MaybeDeliveryOptions } from "../OptionalParts/MaybeDeliveryOptions";
import { MaybeAccountOption } from "../OptionalParts/MaybeAccountOption";

/**
 * Renders the booking form on mobile device layouts.
 */
class MobileBookingForm extends React.Component<ReduxProps> {

    /**
     * When below all satisfied, then scroll to map
     * 1) Any address was focused;
     * 2) No address is focused.
     */
    componentDidUpdate(prevProps: ReduxProps) {
        if (prevProps.IsAddressFocused && !this.props.IsAddressFocused) {
            const topSectionHeight = $("#app-header").height() + $("#app-tab").height() + 4;
            window.scrollTo({ top: topSectionHeight, left: 0, behavior: 'smooth' });
        }
    }

    render() {

        const isParcelMode = this.props.ActiveBookingForm === BookingFormKind.ParcelBooking;

        return (
            <div className="mobile-booking-form-panel">
                <TrackVisibility partialVisibility>
                    <TrackVisibilityBlock/>
                </TrackVisibility>

                <MaybeAccountOption />

                <GoogleMapWrapperWithGps ShowGps={true}>
                    <GoogleMapWrapped />
                </GoogleMapWrapperWithGps>

                <MaybeParcelModeSelector />

                {isParcelMode ? this.RenderParcelForm() : this.RenderTaxiForm()}

                <FareEstimateSnackbar />
                <SnackbarForPriceGuaranteeTip />
                <BookingButton />
                <TncFooter />
                <BookingPageLoading />
            </div>
        );
    }

    /** Render the booking form in parcel mode (extra details) */
    RenderParcelForm(): JSX.Element {
               
        return <>
            <MaybeAccountDetails />

            <BookingInputStyling Title={FormStepLabel.PickupDetails}>
                <BookingAddressComponent isPickup/>
                <NotesToDriver NotesType={DriverNotesType.Pickup} />
                <BookingUseCompanyDetails IsPickup/>
                <BookingLocationContactName ContactType={ContactKind.PickUp} />
                <BookingLocationContactNumber ContactType={ContactKind.PickUp}  />		
            </BookingInputStyling> 

            <BookingInputStyling Title={FormStepLabel.DropOffDetails} > 
                <BookingAddressComponent isPickup={false}/>
                <NotesToDriver NotesType={DriverNotesType.Dropoff} />
                <BookingUseCompanyDetails IsPickup={false}/>
                <BookingLocationContactName ContactType={ContactKind.DropOff} />
                <BookingLocationContactNumber ContactType={ContactKind.DropOff}  />		
            </BookingInputStyling> 

            <BookingInputStyling>
                <BookingDateTime />
            </BookingInputStyling>

            <BookingConditionManager />
            <MaybeCardSelector />
            <MaybeDeliveryOptions />
        </>
    }

    /** Render the booking widget in Taxi (simple) mode. */
    RenderTaxiForm(): JSX.Element {
                
        return <>
            <MaybeAccountDetails />

            <BookingInputStyling Title={FormStepLabel.BookingDetails}>
                <BookingAddressGroup />
            </BookingInputStyling>
            
            <BookingInputStyling>
                <BookingDateTime />
            </BookingInputStyling>

            <BookingConditionManager />

            <BookingInputStyling Title={FormStepLabel.ContactDetails}>
                <BookingLocationContactName ContactType={ContactKind.PickUp} />
            </BookingInputStyling>

            <BookingInputStyling>
                <BookingLocationContactNumber ContactType={ContactKind.PickUp}  />
            </BookingInputStyling>

            <BookingInputStyling Title={FormStepLabel.DriverInstruction}>
                <DriverNotesSection {...this.props.FavouriteAddress} />
            </BookingInputStyling>

            <MaybeCardSelector />
        </>
    }
}

// #region Redux

interface ReduxProps {
    FavouriteAddress: BookingFavouriteAddress;
    IsAddressFocused: boolean;
    ActiveBookingForm: BookingFormKind;
}

function mapStateToProps(state: ApplicationState): ReduxProps {
    return {
        IsAddressFocused: state.uiLogicControl.BookingForm.IsAddressFocused,
        FavouriteAddress: state.booking.FavouriteAddress,
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
    };
}

export default connect(mapStateToProps)(MobileBookingForm);

// #endregion