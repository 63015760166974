import { ServiceResult } from "./ServiceEntities";
import { GetManagementServiceRootUrlWithoutState, Post } from "./ServiceManager";
import { SmsStatus } from "./SmsVerificationContracts";

/** Base URL for the SMS Verification Controller in the Booking Management Service */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrlWithoutState()}SmsVerification/`;
}

/** HTTP API Calls to the SMS Verification Controller in the Booking Management Service. */
class SmsVerificationServiceClass {

    /**
     * Standalone phone number validity check.
     * Clients can call this before StartChallenge to detect this specific error case.
     */
    public TestPhoneNumber(phoneNumber: string): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}TestPhoneNumber`;

        const request: SmsChallengeStartRequest = {
            PhoneNumber: phoneNumber
        };

        return Post<void>(url, request);
    }

    /**
     * Start a new SMS challenge to verify a phone number.
     * When successful, returns the ID of the challenge.
     */
    public StartChallenge(phoneNumber: string): Promise<ServiceResult<string>> {
        const url = `${GetBaseUrl()}StartChallenge`;

        const request: SmsChallengeStartRequest = {
            PhoneNumber: phoneNumber
        };

        return Post<string>(url, request);
    }

    /**
     * Submitting the verification code that was sent in an SMS.
     */
    public SubmitResponse(challengeId: string, verificationCode: string): Promise<ServiceResult<void>> {

        const url = `${GetBaseUrl()}SubmitResponse`;

        const request: SmsChallengeSubmission = {
            ChallengeId: challengeId,
            Code: verificationCode,
        };

        return Post<void>(url, request);
    }

    /**
     * Request the delivery status of the SMS message of an SMS challenge.
     */
    public CheckMessageStatus(challengeId: string): Promise<ServiceResult<SmsStatus>> {

        const url = `${GetBaseUrl()}CheckMessageStatus`;

        const request: SmsChallengeFollowupRequest = {
            ChallengeId: challengeId
        };

        return Post<SmsStatus>(url, request);
    }

    /**
     * Request the verification SMS to be resent.
     */
    public ResendSms(challengeId: string): Promise<ServiceResult<void>> {

        const url = `${GetBaseUrl()}ResendSms`;

        const request: SmsChallengeFollowupRequest = {
            ChallengeId: challengeId
        };

        return Post<void>(url, request);
    }

    /**
     * Update the current user's phone number, given a successful SMS Verification challenge that demonstrates ownership of the number.
     * The new phone number is implied by the Challenge ID. 
     * Only meaningful for signed in users.
     */
    public UpdatePhoneNumber(challengeId: string): Promise<ServiceResult<void>> {

        const url = `${GetBaseUrl()}UpdatePhoneNumber`;

        const request: SmsChallengeFollowupRequest = {
            ChallengeId: challengeId
        };

        return Post<void>(url, request);
    }
}

/** 
 *  Request object for the StartChallenge operation. 
 *  Intentionally not exported / exposed, since it is trivial and only used for a request. 
 */
interface SmsChallengeStartRequest {

    /** The phone number that will receive the verification code via SMS. */
    PhoneNumber: string;
}

/**
 *  What a client sends to respond to an SMS Challenge.
 *  Intentionally not exported / exposed, since it is trivial and only used for a request.
 */
interface SmsChallengeSubmission {

    /** The challenge this code is responding to. Typed as Guid at the API. */
    ChallengeId: string;

    /** The verification code received in the SMS message. */
    Code: string;
}

/**
 *  Message Contract for operations that follow on from a previous SMS Challenge request.
 *  Intentionally not exported / exposed, since it is trivial and only used for a request.
 */
export interface SmsChallengeFollowupRequest {

    /** The SMS Challenge ID from the client's perspective. */
    ChallengeId: string;
}

/** Singleton export for static access to the operations */
export const SmsVerificationService = new SmsVerificationServiceClass();