import React from 'react';
import { connect } from 'react-redux';
import './MobileMenu.scss';
import { ApplicationState } from '../../appState';
import { getContentUrl, ContentURL }  from '../Utils/ContentURL';
import { Dispatch } from "../Dispatch";
import { LoginStatusKind } from "../../modules/Authentication/AuthEntities";
import { SimpleUserProfile } from '../../modules/User/ProfileEntitiesV2';
import { GetBrandedUrl, BrandedImage } from "../Utils/BrandedContentUrls";
import { TriggerSheetLoginOrLogout } from "../Authentication/AuthHelper";
import { GetMenuItems } from "../../widgets/Header/GetMenuItems";
import { Badge, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import { CheckAddPlus } from '../../utils/Formattingutil';
import { GetValues } from '../../Config/MyAppConfig';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom';
import CredentialsController from "../../modules/Authentication/Login/CredentialsController";
import { FeatureFlags } from '../../Config/FeatureFlags';
import { DialogKind } from '../../modules/Dialog/DialogEntities';
import { MyWallet, WhatIsNew } from '../../widgets/Header/HeaderEntities';

interface MobileMenuProps {
    ShowMenu: boolean;
};

interface ProfileProps {
    UserProfile?: SimpleUserProfile;  
};

interface LoginProps {
    LoginStatus: LoginStatusKind;
    NewFeatureList: number[];
};


/**
 * Mobile menu which is trigerred from header.
 */
export const MobileMenuCore: React.FC<MobileMenuProps & ProfileProps & LoginProps> = (props) => {

    if (!props.ShowMenu) return null;

    return (
        <>
            <Slide direction="left" in={props.ShowMenu} mountOnEnter unmountOnExit>
                <div className="mobile-menu-panel">
                    <ClearLeftSheet />
                    <PortraitSection UserProfile={props.UserProfile} />
                    {!FeatureFlags.GuestOnly && <WelcomeSection LoginStatus={props.LoginStatus} NewFeatureList={props.NewFeatureList} />}
                    <MenuSection LoginStatus={props.LoginStatus} NewFeatureList={props.NewFeatureList}  />
                    {!FeatureFlags.GuestOnly && < LogInOutButton LoginStatus={props.LoginStatus} NewFeatureList={props.NewFeatureList} />}
                    <CallUsSection/>
                </div>
            </Slide>

            <div className="mobile-menu-bg" onClick={() => Dispatch.UILogicControl.CloseMenu()}></div>
        </> 
    )
}

function mapStateToProps(state: ApplicationState): MobileMenuProps & ProfileProps & LoginProps {
    return {
        ShowMenu: state.uiLogicControl.ShowMenu,
        UserProfile: state.authentication.UserProfile,
        LoginStatus: state.authentication.LoginStatus,
        NewFeatureList: state.features.NewFeatureList
    };
}

export const MobileMenu = connect(mapStateToProps)(MobileMenuCore);

/**
 * Clear left sheet section
 */
const ClearLeftSheet: React.FC<{}> = () => {
    return (
        <div className="mobile-menu-clear">
            <div onClick={() => Dispatch.UILogicControl.CloseMenu()}>
                <div>
                    <img src={getContentUrl(ContentURL.images.WhiteX)} width="15"></img>
                </div>
            </div>
        </div>
    );
}

/**
 * Portrait section
 */
const PortraitSection: React.FC<ProfileProps> = (props) => {
    const portrait = props.UserProfile ? props.UserProfile.PictureUrl : GetBrandedUrl(BrandedImage.DriverDummy);

    return (
        <div className="mobile-menu-portrait">
            <img src={portrait} width="70px" height="auto"></img>
            {props.UserProfile && <div>
                <span>{props.UserProfile.DisplayName}</span>
                <span>{CheckAddPlus(props.UserProfile.ContactPhone)}</span>
            </div>}
        </div>
    );
}

/**
 * Welcome section
 */
const WelcomeSection: React.FC<LoginProps> = (props) => {

    if (props.LoginStatus !== LoginStatusKind.LoggedOut) return null;

    return (
        <div className="mobile-menu-welcome">
            <div>
                <span>Hello.</span>
                <span>Create a profile to add payment methods, create favourites and more.</span>
                <button className="" onClick={GotoSignup}>SIGN UP</button>
            </div>
        </div>
    );

    function GotoSignup() {
        Dispatch.UILogicControl.CloseMenu();

        if (FeatureFlags.MultiTenantLogin) {
            Dispatch.Dialog.ShowDialog(DialogKind.MultiTenantSelector);
            return;
        }

        new CredentialsController().DoSignup();
    }
}

/**
 * Buttons for login or logout from mobile menu
 */
const LogInOutButton: React.FC<LoginProps> = (props) => {

    const icon = props.LoginStatus === LoginStatusKind.LoggedIn ? getContentUrl(ContentURL.images.Users.PortaitToLogout) : getContentUrl(ContentURL.images.Users.PortaitToLogin);

    const text = props.LoginStatus === LoginStatusKind.LoggedIn? "Sign out" : "Log in";

    return (
        <div className="mobile-menu-btns" onClick={() => { TriggerSheetLoginOrLogout(props.LoginStatus) }}>
            <img src={icon} width="23px"></img>
            <span>{text}</span> 
        </div>
    );
}

/**
 * An icon with an optional badge showing a number
 */
const IconWithBadge: React.FC<IconWithBadgeProps> = (props) => {
    return <div>
        {props.Count !== null && <Badge badgeContent={props.Count} style={{ verticalAlign: "top", left: "20px" }} color="error"></Badge>}
        <Icon><img src={props.IconUrl} /></Icon>
    </div>;
}

interface IconWithBadgeProps {
    /** number is optional won't be render if null  */ 
    Count: number | null;
    IconUrl: string | undefined;
}

/**
 * Menu section
 */
const MenuSection: React.FC<LoginProps> = (props) => {

    const style = props.LoginStatus === LoginStatusKind.LoggedIn ? "mobile-menu-list mobile-menu-list-login" : "mobile-menu-list mobile-menu-list-logout";

    return (
        <div className={style}>
            <List>
                {
                    GetMenuItems().map((item) => {
                        if (item.DisplayToLoggedInUserOnly && props.LoginStatus !== LoginStatusKind.LoggedIn) return null;
                        if (item.Title === MyWallet.Title && !FeatureFlags.CardNotPresentPayment) return;

                        const target = item.IsExternalLink ? "_blank" : "";
                        
                        // The "Contact us" link for Silverservice and Cabcharge brand are external links and need to be opened in a new tab.
                        const path = !item.IsExternalLink ? item.ToLink : {pathname: item.ToLink}

                        let badgeCount: number | null = null;

                        if (item.Icon === WhatIsNew.Icon) badgeCount = props.NewFeatureList.length;
                        
                        return (
                            <Link to={path} key={item.Title} target={target} rel="noopener">
                                <ListItem button>
                                    <ListItemIcon className="mobile-menu-icon">
                                        <IconWithBadge Count={badgeCount} IconUrl={item.Icon} />
                                    </ListItemIcon>
                                    <ListItemText className="mobile-menu-text" primary={item.Title} />
                                </ListItem>
                            </Link>
                        )
                    })
                }
            </List>
        </div>
    );
}

/**
 * Call us in menu footer
 * Call us in menu footer. There is no content if the Brand doesn't have a phone number.
 */
const CallUsSection: React.FC<{}> = () => {

    const phoneNumber = GetValues().BrandContactNumber;
    if (!phoneNumber) return null;

    return (
        <div className="mobile-menu-call-us">
            <a href={`tel:${phoneNumber}`}>
                <img width="45px" height="auto" src={GetBrandedUrl(BrandedImage.PhoneIconInMenu)}></img>
                <img width="auto" height="20px" src={GetBrandedUrl(BrandedImage.Logo)} className="logo"/>
            </a>
        </div>
    );
}