/** 
 * To describe which step in booking form for component StepControl;
 * and, the value will be used as text/description in title.
 */
export enum FormStepLabel {

    AccountDetails = 'Account details',

    BookingDetails = 'Booking details',

    ContactDetails = 'Contact details',

    DriverInstruction = 'Driver instruction',

    PaymentMethod = 'Payment method',

    SelectYourService = 'Select your service',

    PickupDetails = 'Pickup details',

    DropOffDetails = 'Drop-off details',

    DeliveryOptions = 'Delivery options'
}