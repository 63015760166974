import React from "react";
import "./BookingAddressGroup.scss";
import BookingAddressComponent from "../BookingAddressComponent";

/**
 * Wrapper component of grouping of pickup & dropoff
 */
const BookingAddressGroup = () => {    
   
        return(
            <>                
                <BookingAddressComponent isPickup={true} />
                <BookingAddressComponent isPickup={false} />
            </> 
        )
}

export default BookingAddressGroup;