import React from "react";
import { DriverNotesType } from "../../AddressPoints/Redux/AddressPointsEntities";
import { BookingFavouriteAddress } from "../BookingEntities";
import NotesToDriver from "./NotesToDriver";

/** Consists of various driver notes fields based on the status/type of input values in booking address fields. */
export const DriverNotesSection: React.FC<BookingFavouriteAddress> = (props) => {

    const separateNotesInputs = (
        <>
            <NotesToDriver NotesType={DriverNotesType.Pickup} />
            <NotesToDriver NotesType={DriverNotesType.Dropoff} />
        </>
    );

    if (props.Pickup) return separateNotesInputs;
    if (props.Dropoff) return separateNotesInputs;

    return <NotesToDriver />
}