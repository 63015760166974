import appstore from "../../appStore";
import { Api } from "../../Services/Api";
import { MyStorage } from "../../Storage";
import { ShowDialogRetryErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { B2CLogout } from "../AuthV2/AuthV2Testing";
import { ParseAndStoreContactNumber } from "../Booking/NewBookingTabHelper";
import { Dispatch } from "../Dispatch";
import { TryAgainMessageKind } from "../Utils/ErrorMessages";

/** Operations to manage UserV2 (Azure B2C users) */
export namespace UserV2 {

    /** Reload the V2 user profile from the API and store in various locations (redux, local storage) */
    export async function ReloadProfile(shouldLogoutOnFail: boolean) {

        const result = await Api.UserV2.GetMyProfile();

        //if the caller specified not to logout on failure, try to load from the persisted storage and exit. e.g.: when loading profile on page refresh.
        if (!result.isSuccess && !shouldLogoutOnFail) {
            await LoadUserProfileFromLocalStorage();
            return;
        }

        if (!result.isSuccess) {
            await B2CLogout();

            ShowDialogRetryErrorMessage(TryAgainMessageKind.Auth0LoginTryAgain);
            return;
        }

        MyStorage.MgmtUserProfile.StoreData(result.value);
        Dispatch.UserV2.RefreshProfile(result.value);

        //pre-fill some input fields in the booking form
        SetBookingFormDefaultsFromProfileV2();
    }

    /** Load the user profile details from the persisted storage and logout if failed. */
    async function LoadUserProfileFromLocalStorage() {

        const profile = MyStorage.MgmtUserProfile.LoadData();
        if (!profile) {
            await B2CLogout();

            ShowDialogRetryErrorMessage(TryAgainMessageKind.Auth0LoginTryAgain);
            return;
        }

        Dispatch.UserV2.RefreshProfile(profile);
    }

    /**
     * After loading the user profile V2, push some default values onto the booking form.
     */
    function SetBookingFormDefaultsFromProfileV2() {

        const appState = appstore.getState();
        const phoneNumber = appState.user2.UserProfile?.PhoneNumber;

        if (phoneNumber) ParseAndStoreContactNumber(phoneNumber);

        // account can be null from the auth result
        const contactName = appState.auth2.Account?.name;

        if (contactName) Dispatch.Booking.PickupContactName(contactName);
    }
}