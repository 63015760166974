import appstore from "../../appStore";
import { Dispatch } from "../Dispatch";
import { CustomErrorMessages } from "./ErrorMessages";
import { DropLeadingZero } from "../../utils/Formattingutil";
import { UserContactNumberInfo } from "../Verification/VerificationEntities";
import { IsValidMobileNumber, IsValidAustralianLandlineNumber } from "./PhoneValidation";

export function FormatPassengerContactNumber(passengerContactNumber: UserContactNumberInfo | undefined) {

    if (!passengerContactNumber) return;

    const { CountryInfo, Contactnumber } = passengerContactNumber;

    // Do not proceed, if the contact number is empty
    if (!Contactnumber) return;

    const countryCode = CountryInfo?.CountryCode ?? "+61";

    let contactNumber = countryCode + DropLeadingZero(Contactnumber);

    return contactNumber;
}

/**
 * 1. This method is only called for logged-in users
 * 2. An empty contact number is valid, as it's an optional field for templates
 */
export function ValidateContactNumberInput(canContactNumberBeEmpty: boolean = true) : boolean {
        
    const { PickupAdditionalDetails } = appstore.getState().booking;

    /**
     * PassengerContactNumber is guaranteed to exist.
     * Pax contact will default from the user's profile if exist otherwise,
     * Pax will have default country info
     */
    const { CountryInfo, Contactnumber = "" } = PickupAdditionalDetails.ContactNumber!;

    // Do not validate if, the contact number is empty
    if (canContactNumberBeEmpty && !Contactnumber) return true;

    const countryCode = CountryInfo?.CountryCode ?? "+61";

    const formattedNumber = countryCode + DropLeadingZero(Contactnumber);

    // Determine if the contact number is mobile
    if (IsValidMobileNumber(formattedNumber)) return true;        

    // Determine if this contact number is landline
    if (IsValidAustralianLandlineNumber(formattedNumber, countryCode, Contactnumber)) return true;    
    
    // Display error message
    Dispatch.Verification.ContactNumberErrorMessage(CustomErrorMessages.InvalidPhoneNumber);

    return false;
}

/**
 * This is to clean Verification.UserMobileInfo
 */
export function CleanUserContactNumberInfo(): void {
    Dispatch.Verification.ClearContactNumber();
}
