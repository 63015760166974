import { AuthenticationResult } from "@azure/msal-common";
import { Config } from "../../Config/Config";
import { LogEvent } from "../../utils/LogEvent";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { AuthToken, LoginStatusKind } from "../Authentication/AuthEntities";
import { StoreNewAuthToken } from "../Authentication/AuthHelper";
import { ClearDataOnLogout } from "../Authentication/AuthImpl";
import { StartLoginSession } from "../Authentication/LoginSession";
import { Dispatch } from "../Dispatch";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { IdTokenClaims } from "./MSAL/MsalEntities";
import * as MsalOperations from "./MSAL/MsalOperations";

/** Returns true if we are able to login successfully */
export function RestoreAccount(): boolean {

    const account = MsalOperations.RestoreLoginAccount();

    if (account) {
        Dispatch.AuthV2.RestoreLogin(account);
        LogEvent.AuthV2SessionRestore(account);

        // compat
        Dispatch.Auth.LoginStatus(LoginStatusKind.LoggedIn);
    }
    else {
        console.log("There was no login account to restore.");
    }

    return !!account;
}

/** Do a Login with Azure B2C */
export async function B2CLogin(): Promise<void> {

    const result = await MsalOperations.LoginWithUI();    

    if (result.IsSuccess) {
        Dispatch.AuthV2.NewLogin(result.Auth);

        const authToken = ConvertB2cDataToAuthToken(result.Auth);
        StoreNewAuthToken(authToken);

        // compat
        Dispatch.Auth.LoginStatus(LoginStatusKind.LoggedIn);

        await StartLoginSession(false);
    }
    else {
        LogEvent.AuthV2Error("Login", result.Error);
    }
}

export async function B2CLogout(): Promise<void> {

    await MsalOperations.Logout();
    Dispatch.AuthV2.Logout();

    // compat
    Dispatch.Auth.LoginStatus(LoginStatusKind.LoggedOut);
    ClearDataOnLogout();
}

/** Token refresh with B2C and store the new token in redux. If failed, logout the user forcefully and display an error message in the UI. */
export async function B2CTokenRefresh(): Promise<void> {

    const result = await MsalOperations.TokenRefresh();

    if (result.IsSuccess) {
        Dispatch.AuthV2.NewLogin(result.Auth);

        const authToken = ConvertB2cDataToAuthToken(result.Auth);
        StoreNewAuthToken(authToken);
    }
    else {
        LogEvent.AuthV2Error("Token Refresh", result.Error);
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.Auth0RenewFailure);
        B2CLogout();
    }
}

/** Convert the response from the B2C login request (when success) in to the internal model 'AuthToken'. */
function ConvertB2cDataToAuthToken(result: AuthenticationResult): AuthToken {

    // TODO: Is expiresOn local date or UTC?. Find out and adjust if required.
    const expiresIn = result.expiresOn ? result.expiresOn.getTime() - Date.now() : null;

    // result.idTokenClaims is of type 'object'. Not sure if it's a good idea to use it even  like this. Currently, there is no other way to find token expiry time (result.expiresOn seems to be null always)
    const expiresAt = (result.idTokenClaims as IdTokenClaims).exp;

    return {
        accessToken: result.accessToken,
        idToken: result.idToken,
        email: result.account?.username,
        sub: result.uniqueId,
        state: result.state ?? "",
        expiresIn: expiresIn ?? 7200,
        scope: result.scopes.join(", "),
        expiresAt: expiresAt
    }
}

/** Check if the IdToken from B2C is expired */
export function IsIdTokenExpired(expiresAtSeconds: number) {

    // reduce the predefined time (e.g: 5 minutes) from the expiry time to do an early refresh to avoid issues if the token expired just after making the API call and the backend invalidates the token.
    const safeExpiryTime = expiresAtSeconds - Config.TokenExpiryBufferMillis / 1000;

    // expiry time from B2C (exp property in idToeknClaims) is in seconds. Therefore comparing with current time value in seconds
    return safeExpiryTime < (new Date().getTime() / 1000);
}