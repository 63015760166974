import Add from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { AddressPointDetails } from "../../../Services/AddressPointsContracts";
import { CommonDialogDescriptionCore } from "../../Dialog/CommonDialogDescription";
import { CommonDialogHeaderCore } from "../../Dialog/CommonDialogHeader";
import { DialogKind } from "../../Dialog/DialogEntities";
import { MobileDialog } from "../../Dialog/MobileDialog";
import { Dispatch } from "../../Dispatch";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { AddressPointModalType } from "../Redux/AddressPointsEntities";
import { AddressPointListItem } from "./AddressPointListItem";
import "./AddressPoints.scss";

interface AddressPointsProps {
    List: AddressPointDetails[];
    ActiveLayout: UILayoutMode;
}

/** Component contains the list of address points. Each address point is a separate child component. */
const AddressPointsListCore: React.FC<AddressPointsProps> = (props) => {

    const [sortedList, setSortedList] = useState<AddressPointDetails[]>([]);

    useEffect(() => {
        // Sort the saved addresses list by name alphabetically. Need to run this every render in order to sort properly with new and updated Address Points.
        const newList = props.List.sort((a, b) => {
            return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
        });

        setSortedList(newList);
    }, [props.List]);

    /** Open the dialog to create a new address point. */
    function OpenCreateWindow() {
        Dispatch.Dialog.ShowDialog(DialogKind.CreateAddressPoint);
        Dispatch.AddressPoints.SetUiEditModeType(AddressPointModalType.Create);
    }

    return (
        <>
            <div className="address-list-main-area">
                <CommonDialogHeaderCore TopmostDialog={DialogKind.AddressPoints} />
                <CommonDialogDescriptionCore TopmostDialog={DialogKind.AddressPoints} />
                <div className="address-list-content-area">
                    <div className="address-point-list-item add-btn" onClick={OpenCreateWindow}>
                        <span><Add fontSize="large" /></span>
                        <span className="add-btn-text">Add a new address</span>
                    </div>
                    {sortedList.map((address) =>
                        <div className="address-point-list-item" key={address.AddressPointId}>
                            <AddressPointListItem {...address} />
                        </div>
                    )}
                </div>
            </div>
            {props.ActiveLayout === UILayoutMode.Mobile && <MobileDialog />}
        </>
        );
}

function mapStateToProps(state: ApplicationState): AddressPointsProps {
    return {
        List: state.addressPoints.List,
        ActiveLayout: state.uiLogicControl.LayoutMode
    }
}

export const AddressPointsList = connect(mapStateToProps)(AddressPointsListCore);
