
import moment from "moment";
import appstore from "../../appStore";
import { FullStoryAPI } from 'react-fullstory';
import { IsValidMobileNumber } from "../Utils/PhoneValidation";
import { HasLogInProperly } from '../Authentication/AuthHelper';
import { GetOverallBookingContact } from "./CreateBookingCommon";
import { LogEvent } from '../../utils/LogEvent';
import { PayDriverOption, PaymentOptionKind } from "../Payment/PaymentEntities";
import { BookingWorkFlowState } from "./Redux/BookingState";

/**
 * Successful booking creation logging component
 */
export function LogBookingCreation(isFixedFareBooking: boolean) {

    let accountNumber = "", orderNumber = "", selectedVehicle = "Next Available";

    // read store
    const state = appstore.getState();
    const accountData = state.booking.AccountData;
    const selectedCondition = state.condition.SelectedCondition;
    const bookingContact = GetOverallBookingContact();

    // account data
    if (accountData) {
        accountNumber = accountData.SelectedAccount.AccountNumber;
        orderNumber = accountData.OrderNumber || "";
    }

    // condition
    if (!!selectedCondition.ApiVehicle) {
        selectedVehicle = selectedCondition.ApiVehicle.Name;
    }

    const LogBookingCreationData: LogBookingCreationData = {
        Kind: HasLogInProperly() ? "Signed In User" : "Guest User",
        DateStamp: moment().format('YYYYMMDD'),
        IsLandline: (!IsValidMobileNumber(bookingContact.PhoneNumber)).toString(),
        AccountNumber: accountNumber,
        OrderNumber: orderNumber,
        Condition: selectedVehicle,
        FixedFare: isFixedFareBooking.toString(),
        PaymentMethod: GetPaymentMethodDescription(state.booking),
    };

    LogEvent.BookingCreationSuccess(LogBookingCreationData);
    FullStoryAPI('event', "Booking Creation Success", LogBookingCreationData );

    // international number
    // ISSUE: this australia-only logic can't be used. TODO: fix.
    //if (bookingContact.CountryCode !== "+61") {

    //    const countryName = state.verification.UserContactNumberInfo.CountryInfo?.CountryName;
    //    LogEvent.BookingCreatedWithInternationalNumber(countryName ?? "");
    //}
}

/**
 * Provides the payment method for a booking
 */
function GetPaymentMethodDescription(booking: BookingWorkFlowState): string {

    // Check whether booking on accounts
    if (booking.AccountData && booking.IsOnAccount) return "Booking on accounts";

    // Check whether paying by card. 
    if (booking.PaymentOption?.Kind === PaymentOptionKind.Card) return "Paying by card";

    // Paying the Driver directly.
    return PayDriverOption.Name;
}

/**
 * The data object which is used for logging successful booking creation
 */
export interface LogBookingCreationData {
    Kind: string;
    DateStamp: string;
    IsLandline: string;
    AccountNumber: string;
    OrderNumber: string;
    Condition: string;
    FixedFare: string;
    PaymentMethod: string;
}