import { AccountBookingPayload, AddressAdditionalDetails, BookingFavouriteAddress, PickupServiceCheckState, ServiceCheckStatus, Verification } from "../BookingEntities";
import { Config } from "../../../Config/Config";
import { PaymentOption } from "../../Payment/PaymentEntities";
import { AddressV2 } from "../../../Services/MakeBookingContracts";
import { BookingTimeV2 } from "../../BookingV2/BookingV2Entities";
import { DeliveryOptionKind } from "../Parcel/ParcelEntities";

/** Shared application state for the booking under construction in the create booking UI */
export interface BookingWorkFlowState {
    Verification: Verification,
    PickupNotesToDriver: string | null;
    DropoffNotesToDriver: string | null;
    PickupServiceCheck: PickupServiceCheckState;
    PaymentOption?: PaymentOption;

    /** When true, adds a debug UI component to show the internal loading status */
    ShowDiagnosticUI: boolean;

    /** Whether the User has selected to make the booking on Account. When true, additional booking input fields are available */
    IsOnAccount: boolean;

    /** Account specific information only for bookings with accounts. */
    AccountData: AccountBookingPayload | null;

    /** It is user's device information(used for fraud detection), it is set using braintree library,it is a required field to successfully do an app payment
     * Booking api decides whether it is valid card booking by checking existence of device data field.
     * MPS also sends this devicedata to braintree along with transaction request,braintree uses kount for fraud detection.
    */
    DeviceData?: string;

    /** Template name text for favourites */
    TemplateName?: string;

    /** Pickup address details to use for V2 booking service (Global Booker). */
    PickupV2: AddressV2 | null;

    /** Dropoff address details to use for V2 booking service. */
    DropoffV2: AddressV2 | null;

    /** Booking timing details to use for V2 booking service. */
    BookingTimeV2: BookingTimeV2;

    /** Favourite addresses details if selected for any location (pickup or dropoff). */
    FavouriteAddress: BookingFavouriteAddress;

    /** user selected delivery option when parcel widget booking  */
    DeliveryOption: DeliveryOptionKind | null;

    /** Delivery option error */
    DeliveryOptionError: string | null;

    /** Additional details (contacts, driver notes etc) about pickup location. */
    PickupAdditionalDetails: AddressAdditionalDetails;

    /** Additional details (contacts, driver notes etc) about dropoff location. */
    DropoffAdditionalDetails: AddressAdditionalDetails;

}

/** Initial value of additional address details store slices. */
const addressDetailsInitialValue: AddressAdditionalDetails = {
    ContactNumber: { CountryInfo: Config.DefaultCountryInfo }
}

/** Initial value of the Booking state */
export const DefaultBookingWorkFlowState: BookingWorkFlowState = {
    PickupServiceCheck: {
        status: ServiceCheckStatus.NoInputSelected
    },
    Verification: {},
    ShowDiagnosticUI: false,
    IsOnAccount: false,
    AccountData: null,
    PickupV2: null,
    DropoffV2: null,
    BookingTimeV2: {
        IsImmediate: true
    },
    FavouriteAddress: {
        Pickup: null,
        Dropoff: null
    },
    PickupNotesToDriver: null,
    DropoffNotesToDriver: null,

    DeliveryOption: null,
    DeliveryOptionError: null,

    PickupAdditionalDetails: addressDetailsInitialValue,
    DropoffAdditionalDetails: addressDetailsInitialValue,
};