import { DataLoadingStatus } from "../Condition/Redux/ConditionEntities";
import { BookingWidgetModeKind, BookingTemplateLoadKind } from '../BookingTemplate/BookingTemplateEntities';
/**
 * This 1st level store of Redux contains all states which:
 * 1> Support UI control;
 * 2> Support logic control.
 * 
 * For example, "BookingFormApiRunningStatus" is used in booking form, but it's value won't be used for booking itself.
 * TODO: Remove booking.NonBookingPayload.
 * 
 * Regulations:
 * When introduce more states in, please be organised into groups whose names refelct their purpose or belonging.
 */
export interface UILogicControlState {
    /** For booking form related */
    BookingForm: BookingForm;

    /** For addresses related */
    AddressStatus: AddressStatus;

    /** For Auth0 related */
    Credentials: Credentials;

    /** Mobile (compact) or Desktop (normal) layout. */
    LayoutMode: UILayoutMode;

    /** Decide whether to show menu or not, which is only used for mobile device currently, but will be used to control all devices in the future. */
    ShowMenu: boolean;
}

/** Mobile or Desktop. (Should it be small vs big?) */
export enum UILayoutMode {
    Mobile = "Mobile",
    Desktop = "Desktop",
}

/**
 * This is a type for a group --> states which support the UI & logic for sign-up or log-in
 */
export interface Credentials {
    /**
     * This enumeration type is to decribe off where the Auth0 sign-up or log-in popups are triggered.
     */
    PopupTriggerSource: CredentialPopupTrigger;
    /**
     * This is indicating whether some request is running relating to Auth0 related operations, such as sign-up, log-in, forgot-password and check user existence.
     * Or, some internal APIs, such as check user existence, within the new Auth0 flows.
     */
    ApiRunningStatus: DataLoadingStatus;
}

/**
 * This enumeration type is to decribe off where the Auth0 sign-up or log-in popups are triggered.
 */
export enum CredentialPopupTrigger {
    FromMenu = 'From menu',
    FromGuestBooking = 'From guest booking'
}

/**
 * This is a type for a group --> states which support the UI & logic for booking form
 */
export interface BookingForm {
    /** 
     * This status is tracking, in booking form, if booking or related APIs is running or under other statuses;
     * For example, if "Create booking" Api is progessing or not;
     * This status is faciliting, the displaying or not the loading image attached to booking form.
     */
    ApiRunningStatus: DataLoadingStatus;

    /** If the "strict validation" mode is on or off */
    IsStrictValidationModeOn: boolean;

    /** 
     * AddressStatus.Pickup and Dropoff will decide "Google map" and "landing page" to display;
     * but, landing page should only shows when hard-fresh of the booking form page;
     * this flag to prevent landing pagebto show again after it replaced by Google map, even though no values or focus to pickup and dropoff.
     */
    IsLandingPageFinished: boolean;

    /**
     * Holds the active Booking Tab value
     */
    SelectedBookingTab: BookingTabKind;

    /**
     * Holds the status of the Recent Trip API
     */
    RecentTripLoadingStatus: RecentTripLoadKind;

    /**
     * Determines whether the selected vehicle is serviceable for the specified suburb
     */
    IsSelectedVehicleServiceable: boolean;

    /**
     * Determines add/edit favourite on the booking widget
     */
    BookingWidgetMode: BookingWidgetModeKind;

    /**
     * Holds the status of the Booking Template API
     */
    BookingTemplateLoadingStatus: BookingTemplateLoadKind;

    /**
     * Holds the status of the expanded templates/recents list on the QuickBook tab
     */
    QuickBookExpandedStatus: QuickBookExpandedSection;

    /**
     * True when the the account details could not be populated when copying a booking.
     * 
     * This flag is used when:
     *   1) There is a specified account from booking templates/recents/booking history on account number;
     *   2) There are accounts linked to logged-in users;
     * 
     * If there is no accounts linked to logged in user, the value of this flag is meaningless.
     * Default as false.
     */
    SpecifiedAccountNotFound: boolean;

    /**
     * Either pickup or dropoff field is on focus
     */
    IsAddressFocused: boolean;

    /** Whether the header should show or hide */
    ShouldShowHeader: boolean;
    
    /** Is the selected vehicle/service invalid to use with a SATSS voucher. */
    IsInvalidConditionForSatss: boolean;

    /** Whether to show or hide the message about informing the user on how to attain a fare estimate */
    ShouldShowFareEstimateMesssage: boolean;

    /** Whether to show or hide the Snackbar message about informing the user on how to attain a Price Guarantee toggle */
    ShouldShowSnackbarMesssage : boolean;

    /** Which booking form is currently selected. */
    ActiveBookingForm: BookingFormKind;
}

export enum QuickBookExpandedSection {
    
    /** Both template and recent trip list are collapsed */
    None = "None",

    /** Template list is expanded */
    Templates = "Templates",

    /** Recent trip list is expanded */
    RecentBookings = "RecentBookings"
}

export enum RecentTripLoadKind {

    /** Default value */
    Idle = 'Idle',

    /** Before calling the GetMyBookings API */
    Loading = 'Loading Recent Trip',    

    /** After calling the GetMyBookings API, recent trip data is returned */
    Loaded = 'Recent Trip Loaded',

    /** After calling the GetMyBookings API, has no previous trips  */
    Empty = 'Has No Recent Trips'
}

export enum RecentTripButtonKind {

    /** When "Book again" button is clicked of a recent trip from the list */
    BookAgain = 'bookagain',
    
    /** When "Book return" button is clicked of a recent trip from the list */
    BookReturn = 'bookreturntrip'
}

export enum BookingTabKind {
    
    /** QuickBook Tab is selected */
    QuickBook = 0,

    /** NewBooking Tab is selected */
    NewBooking = 1,

    /** Active Tab is selected, which is used for mobile mode only */
    ActiveBooking = 2
}

/**
 * This is a type for a group --> states which support the UI & logic for addresses
 * The name of it tries to be distinguishing from BookingEntities.Address
 */
export interface AddressStatus {
    Pickup: AddressStatusType;
    Dropoff: AddressStatusType;
}

/**
 * This type refelcts the status of below properties for pick-up and drop-off addresses.
 *     1> Is focused or not;
 *     2> Does HTML element has any typed value or not;
 */
export interface AddressStatusType {
    IsFocus: boolean;
    DoesInputHaveValue: boolean;
    IsValid: boolean;
}

/** Different booking form types. */
export enum BookingFormKind {
    PassengerBooking = "Passenger Booking",
    ParcelBooking = "Parcel Booking"
}
