import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { ReduxStoreSlice } from "../../../ReduxStoreSlice";
import { AuthV2State, InitialAuthV2State } from "./AuthV2State";

const slice = new ReduxStoreSlice<AuthV2State>("AuthV2", InitialAuthV2State);

export const AuthV2Dispatch = {
    LoginStatus: slice.Action("Login Status", LoginStatus),
    NewLogin: slice.Action("New Login", NewLogin),
    RestoreLogin: slice.Action("Restore Login", RestoreLogin),
    Logout: slice.EmptyAction("Logout", Logout),
};

/** Reducer for the AuthV2 store slice */
export const AuthV2Reducer = slice.MakeCombinedReducer();

function LoginStatus(state: AuthV2State, newStatus: boolean): AuthV2State {
    return {
        ...state,
        IsLoggedIn: newStatus,
    };
}

/**
 * Restore a Login from local storage during startup.
 * This happens automatically without user input.
 */
function RestoreLogin(state: AuthV2State, account: AccountInfo): AuthV2State {
    return {
        ...state,
        IsLoggedIn: true,
        Account: account,
        LoginToken: null,
    };
}

/**
 * A explicit login from the UI (via user clicking Login).
 */
function NewLogin(state: AuthV2State, result: AuthenticationResult): AuthV2State {
    return {
        ...state,
        IsLoggedIn: true,
        LoginToken: result,
        Account: result.account,
    };
}

/**
 * Logout removes all account and token data.
 */
function Logout(state: AuthV2State): AuthV2State {
    return InitialAuthV2State;
}