import { ReduxStoreSlice } from "../../../ReduxStoreSlice";
import { UserProfileV2 } from "../../../Services/UserContractsV2";
import { InitialUserV2State, UserV2State } from "./UserV2State";

const slice = new ReduxStoreSlice<UserV2State>("UserV2", InitialUserV2State);

export const UserV2Dispatch = {
    RefreshProfile: slice.Action("Refresh User Profile", RefreshUserProfile),
    PhoneNumber: slice.Action("User Phone Number", UpdatePhoneNumber),
    ClearProfile: slice.EmptyAction("Clear Profile", ClearUserProfile),
};

export const UserV2Reducer = slice.MakeCombinedReducer();

/** Refresh the user profile state. Use case: after loading the profile details from the API or restore from the local storage. */
function RefreshUserProfile(state: UserV2State, userProfile: UserProfileV2): UserV2State {
    return {
        ...state,
        UserProfile: userProfile
    }
}

/** Update the phone number in the user profile. */
function UpdatePhoneNumber(state: UserV2State, phoneNumber: string): UserV2State {
    return {
        ...state,
        UserProfile: {
            ...state.UserProfile!,
            PhoneNumber: phoneNumber
        }
    }
}

/** Clear the entire user profile. Used on logout. */
function ClearUserProfile(state: UserV2State): UserV2State {
    return {
        UserProfile: null
    }
}