import appstore from "../../appStore";
import { ServiceCheckStatus } from "../Booking/BookingEntities";
import { Dispatch } from "../Dispatch";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { ApiVehicleOption, DataLoadingStatus, VehicleOption } from "./Redux/ConditionEntities";
import { ParseVehicleConditionHelper } from "./ConditionHelper";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { MakeVehicleLookupFromApiData } from "./ConditionHelperV2";
import { ServiceKind } from "../../utils/service-vehicles/ServiceMetadata";

/** Called on click of the vehicle selector section. Set the default set of vehicles if the address is empty. */
export async function listConditionsWithFareByPickUp() {

    const appState = appstore.getState();
    const bookingPayload = appState.booking;

    // pickup valid?
    const pickupCheckStatus = bookingPayload.PickupServiceCheck.status;

    // pickup defined?
    if (!bookingPayload.PickupV2 && pickupCheckStatus === ServiceCheckStatus.NoInputSelected) {

        // Populate the default services list when there is no pickup address specified.
        if (FeatureFlags.BookingApiV2) {
            PopulateConditionListWithDefaultVehiclesV2();
        }
        else {
            PopulateConditionListWithDefaultVehicles();
        }

        Dispatch.Condition.ShowVehicleSelectorUI();
        return;
    }

    if ((pickupCheckStatus === ServiceCheckStatus.Error) || (pickupCheckStatus === ServiceCheckStatus.KnownBad)) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.NoPickupForCondition);
        return;
    }

    Dispatch.Condition.ShowVehicleSelectorUI();
}

// Default vehicle condition list to be displayed for booking template                  
export const vehicleConditionList: ApiVehicleOption[] = [
    { "ApiId": 3, "Name": "Silver Service", "MaxSeat": 4, "Description": "up to 4 passengers", ApiVersion: 1 },
    { "ApiId": 6, "Name": "Parcels", "MaxSeat": 4, "Description": "Parcels", ApiVersion: 1 },
    { "ApiId": 12, "Name": "Sedan", "MaxSeat": 4, "Description": "up to 4 passengers", ApiVersion: 1 },
    { "ApiId": 14, "Name": "1 Wheelchair", "MaxSeat": 1, "Description": "accessible taxi", ApiVersion: 1 },
    { "ApiId": 16, "Name": "MAXI TAXI", "MaxSeat": 6, "Description": "6 passengers", ApiVersion: 1 },
    { "ApiId": 17, "Name": "MAXI TAXI", "MaxSeat": 7, "Description": "7 passengers", ApiVersion: 1 },
    { "ApiId": 18, "Name": "MAXI TAXI", "MaxSeat": 8, "Description": "8 passengers", ApiVersion: 1 },
    { "ApiId": 19, "Name": "MAXI TAXI", "MaxSeat": 9, "Description": "9 passengers", ApiVersion: 1 },
    { "ApiId": 20, "Name": "MAXI TAXI", "MaxSeat": 10, "Description": "10 passengers", ApiVersion: 1 },
    { "ApiId": 21, "Name": "MAXI TAXI", "MaxSeat": 11, "Description": "11 passengers", ApiVersion: 1 },
    { "ApiId": 26, "Name": "MAXI TAXI", "MaxSeat": 5, "Description": "5 passengers", ApiVersion: 1 },
    { "ApiId": 101, "Name": "MAXI TAXI", "MaxSeat": 1, "Description": "1 passenger", ApiVersion: 1 },
    { "ApiId": 102, "Name": "MAXI TAXI", "MaxSeat": 2, "Description": "2 passengers", ApiVersion: 1 },
    { "ApiId": 103, "Name": "MAXI TAXI", "MaxSeat": 3, "Description": "3 passengers", ApiVersion: 1 },
    { "ApiId": 104, "Name": "MAXI TAXI", "MaxSeat": 4, "Description": "4 passengers", ApiVersion: 1 }
];

/** Populates the default condition list in the store */
export function PopulateConditionListWithDefaultVehicles() {

    // Update the all vehicle condition list in store
    Dispatch.Condition.LoadAllRawV1Data(vehicleConditionList);

    // Retrieve metainfo
    const parsedLookupTables = ParseVehicleConditionHelper(vehicleConditionList);

    // Update the vehicle condition list in store
    Dispatch.Condition.RefreshConditions(parsedLookupTables);

    // Clear the suburbId   
    Dispatch.Condition.ConditionDataLoadStatus({ Status: DataLoadingStatus.Idle, LastInput: null });
}

/** Populates the default condition list in the store for GB */
export function PopulateConditionListWithDefaultVehiclesV2() {
    // Retrieve metainfo
    const parsedLookupTables = MakeVehicleLookupFromApiData(null);

    // Update the condition list in store
    Dispatch.Condition.RefreshConditionsV2(parsedLookupTables);

    // Clear the suburbId   
    Dispatch.Condition.ConditionDataLoadStatus({ Status: DataLoadingStatus.Idle, LastInput: null });

    ConsiderPopulateDefaultVehicle(appstore.getState().condition.ConditionList);
}

/** If a vehicle is not selected already, make the brand's primary vehicle the selected vehicle. */
export function ConsiderPopulateDefaultVehicle(vehicles: VehicleOption[]) {
    if (vehicles.length == 0) return;

    const selectedVehicle = appstore.getState().condition.SelectedCondition;

    // In initial state of store.Condition, Next Available (in which ApiVehicle is undefined) is defined as selected condition.
    // This if statement makes sure the SelectedCondition is an actual user selected vehicle and if not, proceed to replace with the brand's default vehicle.
    if (selectedVehicle.ApiVehicle) return;

    // select brand's primary vehicle by default.
    const defaultVehicle = vehicles.find(i => i.Service.IsDefault) || vehicles[0];
    Dispatch.Condition.SelectedCondition(defaultVehicle);
}

/** Default the vehicle selector to Parcel option if available (usually on select of Parcel booking widget) and lock the vehicle selector. */
export function SetSelectedConditionToParcel() {
    const allVehicles = appstore.getState().condition.ConditionList;

    // No vehicles loaded
    if (allVehicles.length === 0) return;

    const parcel = allVehicles.find(i => i.Service.kind === ServiceKind.Parcel);

    // What to do here? change back to the passenger booking form?
    if (!parcel) return;

    Dispatch.Condition.SelectedCondition(parcel);
    Dispatch.Condition.HideVehicleSelectorUI();

     //clear the pickup/drop-off name and number when user switch from passenger booking to parcel booking
     Dispatch.Verification.ClearContactNumberError();
     
     Dispatch.Booking.ClearPickupContactName();

     Dispatch.Booking.ClearPickupContactNumber();
     
     Dispatch.Booking.ClearDropoffContactName();

     Dispatch.Booking.ClearDropoffContactNumber();

    // clear all errors when user switch from passenger booking to parcel booking
    Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);
}

/** Set the vehicle selector to next available option*/
export function SetSelectedConditionToNextAvailable() {
    const vehicles = appstore.getState().condition.ConditionList;

    // select brand's primary vehicle by default.
    const defaultVehicle = vehicles.find(i => i.Service.IsDefault) || vehicles[0];
    Dispatch.Condition.SelectedCondition(defaultVehicle);   
   
}