import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { PreviewFeatureId } from "../../Features/FeatureEntities";
import { BookingFormTypeSelector } from "../BookingFormTypeSelector";
import { BookingFormStep } from "../FormSteps/BookingFormStep";
import { FormStepLabel } from "../FormSteps/FormStepLabel";

/**
 * Possibly renders the Parcel Mode selector in the booking form.
 * It only appears if you have access to the feature.
 */
const MaybeParcelModeSelectorCore: React.FC<ReduxProps> = (props) => {

    if (!props.CanUseParcelMode) return null;

    return (
        <BookingFormStep Label={FormStepLabel.SelectYourService}>
            <BookingFormTypeSelector />
        </BookingFormStep>
    );
}

// #region Redux

interface ReduxProps {

    /** Whether the current user has access to the parcel mode option */
    CanUseParcelMode: boolean;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        CanUseParcelMode: !!state.features.EnabledPreviews[PreviewFeatureId.ParcelDeliveryWidget]
    };
}

export const MaybeParcelModeSelector = connect(GetPropsFromRedux)(MaybeParcelModeSelectorCore);

// #endregion