import appstore from "../../appStore";
import { Config } from "../../Config/Config";
import { DropLeadingZero } from "../../utils/Formattingutil";
import { LogEvent } from "../../utils/LogEvent";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { CreateBookingWithoutSmsVerification } from "../Booking/CreateBookingCommon";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";
import { CustomErrorMessages, DescriptiveErrorMessages, WellKnownMessageKind } from "../Utils/ErrorMessages";
import { IsValidAustralianLandlineNumber, IsValidMobileNumber } from "../Utils/PhoneValidation";
import { StartSmsOutcome, StartSmsVerification } from "./StartSmsVerification";
import { CountryInfo, ContactNumberValidateOutcome } from "./VerificationEntities";

/** 
 * Verify a guest user's phone number just as they are making a booking.
 **/
export async function VerifyPhoneForBooking(attemptCount: number): Promise<ContactNumberValidateOutcome> {

    Dispatch.Auth.IsBookingNeededAfterVerification(true);

    // Validate and send verification code
    const result = await VerifyBookingPhone(attemptCount);

    // Show inline error message on the booking widget on validation failure
    if (result === ContactNumberValidateOutcome.ErrorFound || result === ContactNumberValidateOutcome.InvalidPhoneNumber) {
        Dispatch.Verification.ContactNumberErrorMessage(CustomErrorMessages.InvalidPhoneNumber);
    }
    else {
        Dispatch.Verification.ClearContactNumberError();
    }

    return result;
}

/**
 * Two categories of phone number are allowed for a booking:
 * 1) Mobile phone, with SMS verification
 * 2) Australian fixed line.
 */
async function VerifyBookingPhone(attemptCount: number): Promise<ContactNumberValidateOutcome> {

    // Default country information
    let contactCountryInfo: CountryInfo = Config.DefaultCountryInfo;

    const BookingPayload = appstore.getState().booking;

    const { CountryInfo, Contactnumber = "" } = BookingPayload.PickupAdditionalDetails.ContactNumber!;

    // Handle the error thrown by the phoneUtil library, if the length of the contact number is less than 3   
    if (Contactnumber.length < 3) return ContactNumberValidateOutcome.InvalidPhoneNumber;

    // Assign the country information from store
    if (CountryInfo && CountryInfo.CountryIsoCode) {
        contactCountryInfo = CountryInfo;
    }

    // Update the UserContactNumberInfo in store
    Dispatch.Verification.ContactNumber(Contactnumber);
    Dispatch.Verification.CountryInfo(contactCountryInfo);

    const contactNumber = contactCountryInfo.CountryCode + DropLeadingZero(Contactnumber);

    // Determine if this contact number is mobile
    if (IsValidMobileNumber(contactNumber)) {
        return await PerformMobileVerification(attemptCount, contactNumber);
    }

    // This is a potential landline, if valid then make a booking
    if (IsValidAustralianLandlineNumber(contactNumber, contactCountryInfo.CountryCode, Contactnumber)) {
        Dispatch.Verification.ShowLoaderInContactDetails();
        CreateBookingWithoutSmsVerification();
        Dispatch.Verification.HideLoaderInContactDetails();
        return ContactNumberValidateOutcome.ValidLandlineNumber;
    }

    // Invalid number, show inline error
    return ContactNumberValidateOutcome.InvalidPhoneNumber;
}

/**
 * Starts a new SMS Verification challenge for the specified phone number, then shows the Verification dialog.
 * The user journey resumes from the Verification dialog (awkward).
 */
async function PerformMobileVerification(attemptCount: number, phoneNumber: string): Promise<ContactNumberValidateOutcome> {

    Dispatch.Verification.ShowLoaderInContactDetails();
    const result = await StartSmsVerification(phoneNumber);
    Dispatch.Verification.HideLoaderInContactDetails();

    if (result.Outcome === StartSmsOutcome.Success) {
        LogEvent.SuccessfulPhoneVerification();

        Dispatch.Dialog.ShowDialog(DialogKind.Verification);
        return ContactNumberValidateOutcome.ValidMobileNumber;
    }
    else if (result.Outcome === StartSmsOutcome.InvalidPhoneNumber) {
        LogEvent.InvalidMobileNumberProvided();

        if (attemptCount === 3) {
            LogEvent.OnProvidingInvalidMobileNumberFourthTime();
            Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.InvalidPhone });
            Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            return ContactNumberValidateOutcome.ExceededMaxNumberOfAttempts;
        }

        return ContactNumberValidateOutcome.ErrorFound;
    }
    else {
        // Display the generic error message for timeouts and other unanticipated errors asking the user to call 132227
        LogEvent.SendingVerificationCodeFailure(result.ErrorMessage);
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
        return ContactNumberValidateOutcome.VerificationFailed;
    }
}