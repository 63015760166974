import { ServiceResult } from "./ServiceEntities";
import { GetManagementServiceRootUrlWithoutState, Post } from "./ServiceManager";
import { SmsChallengeFollowupRequest } from "./SmsVerificationService";
import { UserProfileV2 } from "./UserContractsV2";

/** Base URL for the UserV2Controller in the Booking Management Service. */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrlWithoutState()}UserV2/`;
}

/** Client for the UserV2Controller in the Management API */
class UserServiceV2Class {

    /** Loads user details from the API (MGMT database) */
    public GetMyProfile(): Promise<ServiceResult<UserProfileV2>> {
        const url = `${GetBaseUrl()}GetMyProfile`;
        return Post<UserProfileV2>(url, null);
    }

    /** Update the current user's phone number, given a successful SMS Verification challenge that demonstrates ownership of the number. 
     * The challengeId implies the new number. */
    public UpdatePhoneNumber(challengeId: string): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}UpdatePhoneNumber`;

        const request: SmsChallengeFollowupRequest = {
            ChallengeId: challengeId
        };

        return Post<void>(url, request);
    }
}

export const UserServiceV2 = new UserServiceV2Class();