import { DropLeadingZero, CheckAddPlus, FormatBusinessNumber } from '../../utils/Formattingutil';
import { CustomErrorMessages, DescriptiveErrorMessages, WellKnownMessageKind } from "../Utils/ErrorMessages";
import appstore from "../../appStore";
import { isErrorResult } from '../Utils/Typeguards';
import { DialogKind } from '../Dialog/DialogEntities';
import { Dispatch } from "../Dispatch";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { FormatPassengerContactNumber, CleanUserContactNumberInfo } from "../Utils/ContactNumberHelper";
import { CreateBookingV2 } from "../BookingV2/CreateBookingV2";
import { TrackBookingHelper, LoadMyRecentTrips } from "./BookingLoaders";
import { CreateBookingV1 } from "./CreateBookingV1";
import { ErrorResult } from "../Utils/ErrorEntities";
import { BookingWorkFlowState } from "./Redux/BookingState";
import { CreateBookingResponse } from "../../Services/MakeBookingContracts";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { BookingAuthority, BookingContactDetails } from '../../Services/BookingEntities';
import { HasLogInProperly } from '../Authentication/AuthHelper';
import { AddressAdditionalDetails } from './BookingEntities';

/** V1 vs V2 selector for CreateBooking. Make sure all callers use this method! */
export async function CreateBookingCommon(withSmsVerification: boolean): Promise<BookingAuthority | CreateBookingResponse | ErrorResult> {

    if (FeatureFlags.BookingApiV2) {
        return await CreateBookingV2();
    }
    else {
        return await CreateBookingV1(withSmsVerification);
    }
}

/**
 * The behaviour of the "Book" button; hopefully to successfully create a new booking.
 */
export async function CreateBookingWithoutSmsVerification(): Promise<boolean> {

    Dispatch.UILogicControl.BookingFormApiStart();
    Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);

    const CreateBookingResult = await CreateBookingCommon(false);

    /**
     * Handle the case when this function was invoked from "Contact details"
     */
    if (appstore.getState().dialog.topmostDialog === DialogKind.ContactDetails) {
        Dispatch.Verification.HideLoaderInContactDetails();
        Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);
    }

    if (!isErrorResult(CreateBookingResult)) {

        Dispatch.Dialog.ShowDialog(DialogKind.Confirmation);

        if (!FeatureFlags.BookingApiV2) {

            await TrackBookingHelper((<BookingAuthority>CreateBookingResult).bookingId, (<BookingAuthority>CreateBookingResult).hashCode);
        }

        // Get the list of recent trips alongwith the newly created booking
        LoadMyRecentTrips();

        CleanUserContactNumberInfo();

        return true;
    }

    if (CreateBookingResult.isTimeout) {
        appInsights.trackEvent("Booking creation timeout");
        Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingTimeout });
        Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
        return false;
    }

    appInsights.trackEvent("User booking failed");
    var errorMessage = { ...DescriptiveErrorMessages.CreateBookingFailed };

    /**
     * When an account booking made that addresses are not consistent with region which account belongs to,
     * ODI gives error message "The account number is not valid".
     * 
     * If this case, we show a specific error message.
     * 
     * Tips:
     * Some other scenario will still give error message "The account number is not valid" from ODI.
     * For example, incorrect account number etc.
     * PO confirmed that we only need this error message.
     */
    if (CreateBookingResult.errorMessage.trim() == CustomErrorMessages.InvalidAccountNumber) {
        errorMessage.MessageText = CreateBookingResult.errorMessage.trim();
    }

    // Specific error message for payment preauth failures.
    if (CreateBookingResult.errorMessage.trim() == CustomErrorMessages.PreAuthFailed) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.PaymentPreAuthFailed);
        return false;
    }

    Dispatch.Dialog.SetDescriptiveErrorMessage(errorMessage);
    Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);

    return false;
}

/** Compute driver notes (pickup) */
export function ComputeDriverNotes(booking: BookingWorkFlowState): string {

    let notes = '';

    // When booking on accounts, prefix the remarks with the predefined driver note(s)
    if(FeatureFlags.AccountsDriverNotePrefix && booking.AccountData && booking.AccountData.SelectedAccount) {           
        notes = '-- Do Not Ask For Cash. No Stops, Go Direct. -- ';
    }

    // Append the user's specified remarks
    if(booking.PickupNotesToDriver){
        return notes + booking.PickupNotesToDriver;
    }

    return notes;
}

/**
 * Gets the best contact details for a particular booking location (e.g. pickup / dropoff).
 * If details weren't specified in the UI, reuse the details from the booking contact.
 */
export function GetBestLocationContact(locationDetails: AddressAdditionalDetails): BookingContactDetails {

    // this is the fallback
    const bookingContact = GetOverallBookingContact();

    const name = locationDetails.ContactName ?? bookingContact.Name;
    const number = FormatPassengerContactNumber(locationDetails.ContactNumber) ?? bookingContact.PhoneNumber;

    return {
        Name: name,
        PhoneNumber: number,
    };
}

/**
 * Get the name and number for the overall contact person of the booking.
 * For logged in users, this typically comes from the user profile.
 * For guest bookings, it comes from SMS verification details.
 */
export function GetOverallBookingContact(): BookingContactDetails {

    const name = GetOverallContactName();
    const phoneNumber = GetOverallContactPhone();

    return {
        Name: name,
        PhoneNumber: phoneNumber,
    };
}

/**
 * Get the overall contact name for the booking. There are a few special cases.
 */
function GetOverallContactName(): string {

    const booking = appstore.getState().booking;

    // special case: DVA accounts
    if (booking.AccountData) {
        const { FileNumber, SelectedAccount } = booking.AccountData;

        if (SelectedAccount && SelectedAccount.IsDVA) {
            return FileNumber!;
        }
    }

    // logged in users
    const loggedInUser = appstore.getState().authentication.UserProfile;

    if (loggedInUser) {
        return loggedInUser.ContactName;
    }

    // guests: default from passenger
    return booking.PickupAdditionalDetails.ContactName ?? "";
}

/** 
 *  Generates the phone number of the overall booking contact.
 *  Returns an E.164 formatted string including leading "+" and country dial code prefix.
 */
function GetOverallContactPhone(): string {

    const isUserLoggedIn = HasLogInProperly(true);

    // Read from store
    const verification = appstore.getState().verification;
    const authFromStore = appstore.getState().authentication;
    const userV2 = appstore.getState().user2.UserProfile;

    // logged in user: get from profile
    if (isUserLoggedIn) {

        let formattedNumber = "";

        if (FeatureFlags.AuthV2) {
            // if the user is logged in with AuthV2, both user profile and phone number are non-null. i.e.: the user has to update the profile with a phone number otherwise the user can't proceed to make bookings and will be logged out automatically.
            formattedNumber = FormatBusinessNumber(userV2!.PhoneNumber!);
        }
        else {
            formattedNumber = FormatBusinessNumber(authFromStore.UserProfile!.ContactPhone);
        }        
        
        return CheckAddPlus(formattedNumber);
    }
    else {
        // guest: get from SMS verification
        const numberParts = verification.UserContactNumberInfo;

        const countryCode = numberParts.CountryInfo!.CountryCode;
        return countryCode + DropLeadingZero(numberParts.Contactnumber!);
    }
}