import appstore from "../../appStore";
import { GetValues } from "../../Config/MyAppConfig";
import { Vehicle } from "../../Services/MakeBookingContracts";
import { ServiceInfo, ServiceKind } from "../../utils/service-vehicles/ServiceMetadata";
import { VehicleTypeV2 } from "./Redux/ConditionEntities";

/** Return ServiceInfo for a given vehicle ID of V2 API. */
export function GetVehicleDetails(vehicleType: number): ServiceInfo | null {
    const allVehicles = appstore.getState().condition.AllVehicles;

    if (allVehicles.length == 0) return null;

    const vehicle = allVehicles.find(i => i.GlobalBookerVehicleId === vehicleType);

    if (vehicle) {
        return ConvertToServiceInfo(vehicle);
    }

    // TODO: Find a better solution for this (Next Available?)
    return ConvertToServiceInfo(allVehicles[0]);
}

/** Convert Vehicle API contract to ServiceInfo internal data model. */
export function ConvertToServiceInfo(vehicle: Vehicle): ServiceInfo {
    return {
        image: GetValues().StaticContentBaseUrl + vehicle.ThumbnailImagePath,
        displayName: vehicle.Name,
        blurb: vehicle.Description,
        short: vehicle.ShortDescription,
        isAny: false,
        supportSATSS: vehicle.CanUseWithSubsidyVouchers,
        isMaxiTaxi: false,
        kind: vehicle.GlobalBookerVehicleId === VehicleTypeV2.Parcel ? ServiceKind.Parcel : null,
        trackingImage: GetValues().StaticContentBaseUrl + vehicle.LargeImagePath,
        IsDefault: vehicle.IsPrimaryForBrand,
        StandardSortOrder: vehicle.GlobalBookerVehicleId,
    }
}