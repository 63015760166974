import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppInsights } from "applicationinsights-js";
import { HydrateConfig, GetValues } from './Config/MyAppConfig';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './Theme';

HydrateConfig();

AppInsights.downloadAndSetup!({ instrumentationKey: GetValues().AppInsightsKey });
AppInsights.trackEvent("Startup");

function Main() {
    
    const [hasMounted, setHasMounted] = React.useState(false);
    
    React.useEffect(() => {
      setHasMounted(true);
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles && jssStyles.parentElement) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
    }, []);

    if (!hasMounted) return null;
  
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    );
}

hydrate(<Main />,document.getElementById('root'));

if (module.hot) {
    module.hot.accept();
}
