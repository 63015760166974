import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { CanBookOnAccount } from "../../Utils/UserProfileValidation";
import { BookOnAccountToggle } from "../Account/BookOnAccountToggle";

/**
 * Possibly renders the Account toggle option in the booking form.
 * It only appears when the user has access to an account booking facility.
 * Supports mobile and desktop layout.
 */
const MaybeAccountOptionCore: React.FC<ReduxProps> = (props) => {

    if (!props.CanBookOnAccount) return null;

    if (props.IsMobile) {
        return (
            <div className="mobile-booking-on-account-wrapper">
                <BookOnAccountToggle />
            </div>
        );
    }
    else {
        return (
            <>
                <BookOnAccountToggle />
                <hr className="key-line" />
            </>
        );
    }
}

// #region Redux

interface ReduxProps {

    /** Whether the user is allowed to book on account. */
    CanBookOnAccount: boolean;

    /** True when the user is on a mobile device (layout) */
    IsMobile: boolean;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        CanBookOnAccount: CanBookOnAccount(state.authentication.UserProfile, false),
        IsMobile: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
    };
}

export const MaybeAccountOption = connect(GetPropsFromRedux)(MaybeAccountOptionCore);

// #endregion