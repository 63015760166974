import appstore from "../../appStore";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { Api } from "../../Services/Api";
import { ServiceResult } from "../../Services/ServiceEntities";
import { SetErrorMessages } from "../Utils/CommonHelpers";

/**
 * Respond to the current SMS Challenge with the specified code, then use the verified challenge to update the user's phone number.
 */
export async function ChangePhoneNumber(verificationCode: string): Promise<ChangePhoneResult> {

    // 1) complete SMS Challenge
    const smsChallengeId = appstore.getState().verification.SmsChallengeId!;

    const submitResult = await Api.SmsVerification.SubmitResponse(smsChallengeId, verificationCode);

    if (!submitResult.isSuccess) {
        const message: string = SetErrorMessages(submitResult).errorMessage;

        const failure: ChangePhoneFailure = {
            Outcome: ChangePhoneOutcome.CodeFailed,
            ErrorMessage: message,
        };

        return failure;
    }

    // 2) change phone number
    let updateResult: ServiceResult<void>;

    if (FeatureFlags.AuthV2) {
        updateResult = await Api.UserV2.UpdatePhoneNumber(smsChallengeId);
    }
    else {
        updateResult = await Api.SmsVerification.UpdatePhoneNumber(smsChallengeId);
    }

    if (!updateResult.isSuccess) {

        const message: string = SetErrorMessages(updateResult).errorMessage;

        const failure: ChangePhoneFailure = {
            Outcome: ChangePhoneOutcome.UpdateFailed,
            ErrorMessage: message,
        };

        return failure;
    }

    // OK!
    const success: ChangePhoneSuccess = {
        Outcome: ChangePhoneOutcome.Success,
    };

    return success;
}

/** The overall result of ChangePhoneNumber; Success or Failure */
export type ChangePhoneResult = ChangePhoneSuccess | ChangePhoneFailure;

/** Successful result of ChangePhoneNumber. No payload. */
interface ChangePhoneSuccess {
    Outcome: ChangePhoneOutcome.Success;
}

/** Failure result for ChangePhoneNumber. Includes the step at which failure occurred and the error message. */
interface ChangePhoneFailure {
    Outcome: ChangePhoneOutcome.CodeFailed | ChangePhoneOutcome.UpdateFailed;
    ErrorMessage: string;
}

/** Distriminator for ChangePhoneResult and to pick the different error cases. */
export enum ChangePhoneOutcome {
    Success = 0,
    CodeFailed = 1,
    UpdateFailed = 2,
}