import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import BookingInputStyling from "./BookingInputStyling";
import { FormStepLabel } from "./FormStepLabel";
import StepControl from "./StepControl";

/**
 * A layout-aware step heading in the booking form.
 * It creates a heading label similar to "Step 1 of 3: Account Details".
 * The content to go under this step should be nested as JSX children.
 * This supports both mobile and desktop layout modes. 
 */
const BookingFormStepCore: React.FC<JsxProps & ReduxProps> = (props) => {

    if (props.IsMobile) {
        return (
            <BookingInputStyling Title={props.Label}>
                {props.children}
            </BookingInputStyling>
        );
    }
    else {
        return (
            <>
                {props.Label && <StepControl StepKind={props.Label} />}
                {props.children}
            </>
        );
    }
}

interface JsxProps {
    /** 
     *  Display label for this section of the booking form content.
     *  It is almost always populated, except for the timing section, which doesn't use a label.
     */
    Label?: FormStepLabel;
}

// #region Redux

interface ReduxProps {

    /** True when the user is on a mobile device (layout) */
    IsMobile: boolean;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        IsMobile: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
    };
}

export const BookingFormStep = connect(GetPropsFromRedux)(BookingFormStepCore);

// #endregion