import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../../appState'
import { DriverNotesType } from '../../AddressPoints/Redux/AddressPointsEntities'
import { BookingWidgetModeKind, ContactKind } from '../../BookingTemplate/BookingTemplateEntities'
import { SetSelectedConditionToParcel } from '../../Condition/PopulateConditions'
import BookingConditionManager from '../../Condition/UI/BookingConditionManager'
import BookingAddressComponent from '../BookingAddressComponent'
import BookingDateTime from '../BookingDateTime'
import { FormStepLabel } from '../FormSteps/FormStepLabel'
import { MaybeAccountDetails } from '../OptionalParts/MaybeAccountDetails'
import { MaybeCardSelector } from '../OptionalParts/MaybeCardSelector'
import { MaybeDeliveryOptions } from '../OptionalParts/MaybeDeliveryOptions'
import { BookingLocationContactName } from '../Widget/BookingLocationContactName'
import { BookingLocationContactNumber } from '../Widget/BookingLocationContactNumber'
import BookingUseCompanyDetails from '../Widget/BookingUseCompanyDetails'
import NotesToDriver from '../Widget/NotesToDriver'
import StepControl from '../FormSteps/StepControl'

export interface ParcelBookingFormProps {
    BookingWidgetMode: BookingWidgetModeKind;
}

/** This component is to capture Parcel booking related input where the user can enter pickup details, drop-off details, date and time and delivery instructions. */
const ParcelBookingForm: React.FC<ParcelBookingFormProps> = (props) => {

    useEffect(() => SetSelectedConditionToParcel(), []);

    return (
        <Fragment>
            <MaybeAccountDetails />

            <StepControl StepKind={FormStepLabel.PickupDetails} />
            <BookingAddressComponent isPickup />
            <NotesToDriver NotesType={DriverNotesType.Pickup} />
            <BookingUseCompanyDetails IsPickup />
            <BookingLocationContactName ContactType={ContactKind.PickUp} />
            <BookingLocationContactNumber ContactType={ContactKind.PickUp} />

            <StepControl StepKind={FormStepLabel.DropOffDetails} />
            <BookingAddressComponent isPickup={false} />
            <NotesToDriver NotesType={DriverNotesType.Dropoff} />
            <BookingUseCompanyDetails IsPickup={false} />
            <BookingLocationContactName ContactType={ContactKind.DropOff} />
            <BookingLocationContactNumber ContactType={ContactKind.DropOff} />

            {props.BookingWidgetMode === BookingWidgetModeKind.Booking && <BookingDateTime />
            }

            <BookingConditionManager />
            <MaybeCardSelector />
            <MaybeDeliveryOptions />
        </Fragment>
    )
}

function mapStateToProps(state: ApplicationState): ParcelBookingFormProps {
    return {
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
    }
}

export default connect(mapStateToProps)(ParcelBookingForm);
