
/** 
 *  URL segments used in routing.
 *  By defining these as named constants, we can make sure that any links match the route defined in App.tsx.
 */
export const RouteUrls = {

    /** This URL triggers the silent login / signup process. */
    SilentLogin: "/login",

    /** The URL Auth0 will redirect to after a login. */
    LoginCallbackV2: "/loginreturnv2",

    /** 
     *  Azure B2C login redirect URL.
     *  Careful: this URL can't be changed because it is part of Azure B2C config.
     */
    B2CLoginReturn: "/b2cloginreturn",

    /** Terms and conditions statement. */
    TermsAndConditions: "/Terms",

    /** Privacy policy statement */
    PrivacyPolicy: "/Privacy",

    /** Contact details and the form to submit any inquiries. */
    ContactUs: "/contact-us",

    /* Payment wallet list */
    PaymentWallet: "/payment",
    
    /** User personal details */
    PersonalDetails: "/personal",

    /** User Profile */
    UserProfile: "/userprofile",

    /** What's New */
    WhatIsNew: "/what-is-new",
    
    /** user's saved addresses */
    AddressPoints: "/saved-addresses",

    /** Opens the Fare estimate widget with capability to convert it to the booking form. */
    FareEstimate: "/fare-estimate",

    /** Open the Booking Form. */
    BookingWidget: "/booking"

}