import appstore from "../../appStore";
import { ServiceCheckStatus } from "../Booking/BookingEntities";
import { VehicleOption } from "./Redux/ConditionEntities";
import { canBookWithFixedFare } from "../Fare/FareHelper";
import { ApplicationState } from "../../appState";

/** Decide whether fare estimation/fixed price should be displayed. 
 *  Fare can be available even though addresses are empty (from previous request) in which case the user will not see the fare.
 * */
export function ShouldDisplayFare(state: ApplicationState): boolean {

    const { PickupServiceCheck } = state.booking;
    const pickup = state.booking.PickupV2;
    const dropoff = state.booking.DropoffV2;

    if (!pickup) return false;
    if (!dropoff) return false;
    if (PickupServiceCheck.status !== ServiceCheckStatus.KnownGood) return false;

    if (state.uiLogicControl.BookingForm.IsInvalidConditionForSatss) return false;
    if (!state.uiLogicControl.BookingForm.IsSelectedVehicleServiceable) return false;

    return true;
}

/** Decides if Fixed fare should be shown based on the selected condition. */
export function ShouldShowFixedFare(vehicle?: VehicleOption) {
    const state = appstore.getState();
    const selectedCondition = vehicle || state.condition.SelectedCondition;

    // Fare is not refreshed, but the address is cleared.
    if (!ShouldDisplayFare(state)) return false;
    if (!canBookWithFixedFare()) return false;

    // Fixed fare can be undefined if the specified location is not in a fixed fare available area.
    if (!selectedCondition.FixedFare) return false;

    return true;
}

/**
 * Decides whether to show fixed or estimated fare
 */
export function CanProvidedConditionShowFixedFare(isPriceGuaranteeSelected: boolean, vehicle?: VehicleOption): boolean {

    if (!vehicle) return false;
    
    if (!ShouldShowFixedFare(vehicle)) return false;

    if (!isPriceGuaranteeSelected) return false;

    return true;
}