import React from 'react'
import { connect } from 'react-redux';
import { ApplicationState } from '../../appState';
import { BookingFormKind } from '../UILogicControl/UILogicControlEntities';
import { BrandedImage, GetBrandedUrl } from '../Utils/BrandedContentUrls';

interface TaxiIconProps {
	ActiveForm: BookingFormKind;
}

/** Component contains parcel icon image*/
const TaxiIcon: React.FC<TaxiIconProps> = (props) => {

	const taxiIconImage = props.ActiveForm === BookingFormKind.PassengerBooking ? GetBrandedUrl(BrandedImage.TaxiIconWhiteSvg) : GetBrandedUrl(BrandedImage.TaxiIconBlackSvg);

	return (
		<div style={{paddingTop: 4,  paddingRight: 5, width: 24, height: 25}}>
			<img src={taxiIconImage} alt="Taxi Icon" ></img>
		</div>
	)
}

function mapStateToProps(state: ApplicationState): TaxiIconProps {
	return {
		ActiveForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
	}
}

export default connect(mapStateToProps)(TaxiIcon);