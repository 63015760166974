
// Wrappers for MSAL operations that stop us having to do annoying JavaScript promise error handling.
// managing objects that describe success or failure is way easier.

import { AuthError, SilentRequest } from "@azure/msal-browser";
import { AccountInfo, AuthenticationResult } from "@azure/msal-common";
import appstore from "../../../appStore";
import { RouteUrls } from "../../../RouteUrls";
import { GetMsalApp } from "./MsalConfig";
import { AuthV2Failure, AuthV2Result, AuthV2Success } from "./MsalEntities";

/** The account may have been stored in local storage from a previous session. */
export function RestoreLoginAccount(): AccountInfo | null {

    const msalApp = GetMsalApp();
    return msalApp.getActiveAccount();
}

/** Show a Login UI. */
export async function LoginWithUI(): Promise<AuthV2Result> {

    const msalApp = GetMsalApp();

    try {
        const result: AuthenticationResult = await msalApp.loginPopup();
        msalApp.setActiveAccount(result.account);

        const success: AuthV2Success = {
            IsSuccess: true,
            Auth: result,
        };

        return success;
    }
    catch (error: unknown) {

        // this is an assumption from the MSAL source code
        const msalError = error as AuthError;

        const failure: AuthV2Failure = {
            IsSuccess: false,
            Error: msalError,
        };

        return failure;
    }
}

export async function Logout() {

    const msalApp = GetMsalApp();
    const account = appstore.getState().auth2.Account;

    const safeOrigin = window?.location.origin ?? ""; // SSR safe
    const redirectUrl = `${safeOrigin}${RouteUrls.B2CLoginReturn}`;

    if (account) {
        await msalApp.logoutPopup({
            account: account,
            postLogoutRedirectUri: redirectUrl,
        });
    }
}

/** Refresh token silently */
export async function TokenRefresh(): Promise<AuthV2Result> {

    const msalApp = GetMsalApp();

    try {
        const refreshPayload: SilentRequest = {
            scopes: ["openid", "profile"] // same scope configured in the B2C user flow
        };

        const result: AuthenticationResult = await msalApp.acquireTokenSilent(refreshPayload);

        const success: AuthV2Success = {
            IsSuccess: true,
            Auth: result,
        };

        return success;
    }
    catch (error: unknown) {

        // this is an assumption from the MSAL source code
        const msalError = error as AuthError;

        const failure: AuthV2Failure = {
            IsSuccess: false,
            Error: msalError,
        };

        return failure;
    }
}