import React, { Component } from 'react';
import { connect } from "react-redux";
import '../Login/SignupLogin.scss';
import '../../Booking/Widget/BookingContactNumber.scss';
import { ApplicationState } from "../../../appState";
import { Dispatch } from '../../Dispatch';
import PhoneInput from "react-intl-tel-input";
import { Config } from '../../../Config/Config';
import { CustomErrorMessages } from '../../Utils/ErrorMessages';
import { CountryInfo } from '../../Verification/VerificationEntities';
import { ValidateContactNumber } from '../../Utils/PhoneValidation';
import { TextFieldEndIcon } from '../../Booking/Widget/TextFieldEndIcon';
import { TextField } from '@material-ui/core';
import { CheckAddPlus } from '../../../utils/Formattingutil';

interface CredentialEmailState {
    errorMessage: string | null;
    contactNumber: string;
    isFocused: boolean;
}

interface PropsFromStore {
    ContactNumber: string | undefined;
    CountryInfo: CountryInfo;
}

/**
 * Renders the phone number input in the signup dialog
 */
class ResponsiveNumberInput extends Component<PropsFromStore, CredentialEmailState> {
    private phoneInputRef: React.RefObject<any>;
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: PropsFromStore) {
        super(props);
        this.inputRef = React.createRef();
        this.phoneInputRef = React.createRef();
        
        this.state = {
            errorMessage: null,
            contactNumber: "",
            isFocused: false
        }

        this.onUpdateNumber = this.onUpdateNumber.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
        this.clearContactNumber = this.clearContactNumber.bind(this);
    }

    /**
     * Initialse contact number here, if there is a value in Redux
     */
    componentDidMount() {
        if (!!this.props.ContactNumber) this.setState({ contactNumber: this.props.ContactNumber })
    }

    /**
     * This function is triggered when onBlur of number input.
     */
    onUpdateNumber() {
        if (ValidateContactNumber(this.state.contactNumber, this.props.CountryInfo.CountryCode).IsValid) {
            this.setState({ errorMessage: null })
            Dispatch.Auth.SignupPhoneNumber(this.state.contactNumber);
        }
        else {
            this.setState({ errorMessage: CustomErrorMessages.CredentialContactNumberErrorMessage })
            Dispatch.Auth.ClearSignupPhoneNumber();
        }
    }

    clearContactNumber() {
        Dispatch.Auth.ClearSignupPhoneNumber();
        this.setState({ errorMessage: null, contactNumber: "" });
    }

    /** Only numeric values are allowed in the phone number input field. Check this on every input value change. */
    AllowOnlyNumericValue = () => {
        const numberOnlyRegex = /^[0-9\b]+$/, input = this.inputRef.current!.value;

        if (input === '' || numberOnlyRegex.test(input)) {
            this.setState({ contactNumber: input })
        }
    }

    /** 
     * Update the store on change of the country code from the dropdown and validate the combination of the selected code and the input.
     */
    onCountryCodeChanged = (status: any, value: any) => {
        const contactNumber = this.state.contactNumber, countryCode = CheckAddPlus(value.dialCode);
        Dispatch.Auth.SignupPhoneCountry({ CountryCode: countryCode, CountryName: value.name, CountryIsoCode: value.iso2 });

        // Validate the contact number if the country is changed after populating the number field.
        if (!contactNumber) return;

        if (ValidateContactNumber(contactNumber, countryCode).IsValid) {
            this.setState({ errorMessage: null });
        }
        else {
            this.setState({ errorMessage: CustomErrorMessages.CredentialContactNumberErrorMessage });
        }      
    };

    onFocusChange(isFocused: boolean) {
        this.setState({ isFocused: isFocused });

        if (!isFocused) this.onUpdateNumber();
    }

    render() {

        const mobileValidityClass = !!this.state.errorMessage ? "phone-number-group contact-number-error" : "phone-number-group"; 
        
        const showClearTextIcon = !this.state.isFocused && !!this.state.contactNumber;   

        const canShrinkLabel = this.state.isFocused || !!this.state.contactNumber;

        return(
            <div className="contact-number-panel">
                <div className="contact-number-container">
                    <div className={mobileValidityClass}>
                        <div className="country-code-section">
                            <PhoneInput
                                defaultCountry={this.props.CountryInfo.CountryIsoCode}
                                css={ ['intl-tel-input', 'form-control'] }
                                separateDialCode="true"
                                fieldId={"phoneInputAuth0"}
                                localization="false"
                                value={this.props.CountryInfo.CountryCode}
                                onSelectFlag={this.onCountryCodeChanged}
                                preferredCountries={""}
                                ref={this.phoneInputRef}                  
                            />
                        </div>
                    </div>

                    <TextField
                        type="tel"
                        fullWidth={true}
                        label={"Your number"}
                        variant="outlined"
                        value={this.state.contactNumber}
                        error={!!this.state.errorMessage}
                        inputRef={this.inputRef}
                        className="simple-textfield"
                        onChange={this.AllowOnlyNumericValue}
                        InputLabelProps={{shrink: canShrinkLabel}}
                        onBlur={() => this.onFocusChange(false)}
                        onFocus={() => this.onFocusChange(true)}
                        inputProps={{ maxLength: Config.ContactNumberMaxLength }}
                        InputProps={{ endAdornment: <TextFieldEndIcon showClearTextIcon={showClearTextIcon} showErrorIcon={!!this.state.errorMessage} onClick={this.clearContactNumber} /> }}
                    />
                </div>

                {this.state.errorMessage &&<div className="auth-input-error-message">{this.state.errorMessage}</div>}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        ContactNumber: state.authentication.Credentials.ContactNumber,
        CountryInfo: state.authentication.Credentials.CountryInfo
    };
}

export default connect(mapStateToProps)(ResponsiveNumberInput);