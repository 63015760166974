import React, { Component } from 'react';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';
import './selected-vehicle-bar.scss';
import { ServiceCheckStatus, PickupServiceCheckState } from "../../Booking/BookingEntities";
import { VehicleOption, DataLoadingStatus } from '../Redux/ConditionEntities';
import ConditionView from './ConditionView';
import { ApplicationState } from '../../../appState';
import { connect } from 'react-redux';
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import Loading from '../../../widgets/loading/loading';
import './booking-condition-manager.scss';
import ConditionSelector from './ConditionSelector';
import ConditionInfoView from './ConditionInfoView';
import TransparentBlackBg from '../../../widgets/transparent-black-background/transparent-black-background';
import { Dispatch } from '../../Dispatch';
import { listConditionsWithFareByPickUp } from '../PopulateConditions';
import { DeriveServiceErrorMessage } from '../ProcessAndValidateSelectedCondition';
import { BookingFormKind, UILayoutMode } from '../../UILogicControl/UILogicControlEntities';
import PriceGuaranteeToggle from '../../Fare/PriceGuaranteeToggle';
import { GetValues } from '../../../Config/MyAppConfig';
import { CanShowPriceGuaranteePanel } from '../../Fare/PriceGuaranteeToggleHandler';
import { PreviewFeatureId } from '../../Features/FeatureEntities';

interface PropsFromStore {
    SelectedCondition: VehicleOption;
    PickupServiceCheckState: PickupServiceCheckState;
    BookingWidgetMode: BookingWidgetModeKind;
    fareLoadStatus: DataLoadingStatus;
    IsVehicleSelectorActive: boolean;
    ConditionLoadState: DataLoadingStatus;
    IsBookingForNow: boolean;
    ShowServiceInfo: boolean;
    ServiceErrorMessage: string | null;
    UnderMobileMode: boolean;
    CanShowPriceGuaranteeToggle: boolean;
    VehiclesList: VehicleOption[];
    IsNewParcelFeatureEnabled: boolean;
    ActiveBookingForm: BookingFormKind;
}

/** Component to display the Service selector widget and list of services (based on the open/close status) in the booking form. */
class BookingConditionManager extends Component<PropsFromStore> {
    constructor(props: PropsFromStore) {
        super(props)

        this.ToggleVehicleSelector = this.ToggleVehicleSelector.bind(this);
    }

    /**
     * Opens (expand) the service selector by default (on page load) unless a service is selected already. 
     * Avoid expanding for silver service brand.
     */
    componentDidMount() {
        
        if (GetValues().DefaultToSilverservice) return;

        if (this.props.SelectedCondition.ApiVehicle) return;

        listConditionsWithFareByPickUp();
    }

    /** Toggle vehicle selector on click of the 'Select your service' label area. */
    ToggleVehicleSelector() {

        // No vehicles loaded from the API (either the API call is failed or no vehicles defined for the brand yet).
        // Only applicable to the V2 API
        if (this.props.VehiclesList.length === 0) return;

        // Currently closed. Populate the list and open.
        if (!this.props.IsVehicleSelectorActive) {
            listConditionsWithFareByPickUp();
            return;
        }

        // Currently opened.
        Dispatch.Condition.HideVehicleSelectorUI();
    }

    /** Derive error message for vehicle selector. */
    GetErrorMessage() {
        if (this.props.IsVehicleSelectorActive) return null;

        return this.props.ServiceErrorMessage;
    }

    GetSelectorLabel() {
        if (this.props.IsNewParcelFeatureEnabled) {
             return "Select vehicle type";
        } 
        
        return "Select your service";
    }

    render() {
        const invalidVehicleContainerClass = this.GetErrorMessage() !== null && "invalid-vehicle-container";
        const isLoadInProgress = (this.props.ConditionLoadState === DataLoadingStatus.InProgress) || (this.props.PickupServiceCheckState.status === ServiceCheckStatus.CheckInProgress);

        const arrowUrl = this.props.IsVehicleSelectorActive ? getContentUrl(ContentURL.images.arrows.ArrowUpBlack) : getContentUrl(ContentURL.images.arrows.ArrowDownBlack);
        const isLoadingShown = this.props.IsVehicleSelectorActive && isLoadInProgress;

        // When Book Now is selected, reduce the margin-top  
        const vehicleWrapperClass = this.props.IsBookingForNow ? "booking-condition-wrapper-panel reduce-margin-top" : "booking-condition-wrapper-panel";

        const marginTopCssClass = !this.props.CanShowPriceGuaranteeToggle ? "service-section-marginTop" : "";

        const selectedVehicleCssClass = this.props.ActiveBookingForm === BookingFormKind.ParcelBooking ? "parcel-selected-vehicle-bar-panel" : "selected-vehicle-bar-panel"

        return (
            <>
                <div className={vehicleWrapperClass}>
                    <div className={selectedVehicleCssClass} onClick={this.ToggleVehicleSelector}>
                        {this.props.ActiveBookingForm === BookingFormKind.PassengerBooking && <div className="vehicle-selector-label-area">
                            <span>{ this.GetSelectorLabel() }</span>
                            <span><img src={arrowUrl} /></span>
                        </div>
                        } 
                        {this.props.CanShowPriceGuaranteeToggle && <PriceGuaranteeToggle />}
                        <hr className={`service-section-separator ${marginTopCssClass}`} />

                        {!this.props.IsVehicleSelectorActive && <div className={`${invalidVehicleContainerClass}`}>
                            <ConditionView
                                ServiceDetails={this.props.SelectedCondition}
                                IsTemplateModeOn={this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking}
                                FareLoadStatus={this.props.fareLoadStatus}
                                ClickHandler={listConditionsWithFareByPickUp}
                            />
                        </div>}
                    </div>
                    {isLoadingShown && <Loading CanShowPriceGuaranteeToggle={this.props.CanShowPriceGuaranteeToggle} />}
                    {
                        this.GetErrorMessage() && (
                            <div className="booking-form-error-message">
                                {this.GetErrorMessage()}
                            </div>
                        )
                    }
                </div>
                {this.props.IsVehicleSelectorActive && <ConditionSelector />}
                {
                    this.props.UnderMobileMode && this.props.ShowServiceInfo &&
                    <>
                        <ConditionInfoView />
                        <TransparentBlackBg />
                    </>
                }
            </>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        SelectedCondition: state.condition.SelectedCondition,
        PickupServiceCheckState: state.booking.PickupServiceCheck,
        fareLoadStatus: state.condition.FareLoadStatus.Status,
        IsVehicleSelectorActive: state.condition.IsVehicleSelectionActive,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        ConditionLoadState: state.condition.LoadingStatus.Status,
        IsBookingForNow: state.booking.BookingTimeV2.IsImmediate,
        ShowServiceInfo: state.condition.ShowServiceInfo,
        ServiceErrorMessage: DeriveServiceErrorMessage(state),
        UnderMobileMode: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        CanShowPriceGuaranteeToggle: CanShowPriceGuaranteePanel(state),
        VehiclesList: state.condition.ConditionList,
        IsNewParcelFeatureEnabled: !!state.features.EnabledPreviews[PreviewFeatureId.ParcelDeliveryWidget],
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
    }
}

export default connect(mapStateToProps)(BookingConditionManager);