import React from 'react';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { SignUpLink }from './SignUpLink';
import AcknowledgeFeatureButton from './AcknowledgeFeatureButton';
import { Typography } from '@material-ui/core';


export const MultipleTracking = (props: FeatureCardProps) => {

    return (
        <React.Fragment key={FeatureId.MultipleTracking}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>21.09.2020</span>
                </div>
                <Typography variant="h6" color="primary">TRACK MULTIPLE TRIPS</Typography>
                <Typography variant="body2" gutterBottom>Track multiple bookings at once. See the live status of cabs, in detail on maps.</Typography>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.MultipleTracking)} alt="Track Trips" />
                </div>
                    <SignUpLink />
                    <AcknowledgeFeatureButton {...props} />
            </div>
        </React.Fragment>
    );
}