import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { CheckAddPlus } from "../../../utils/Formattingutil";
import { ContactKind } from "../../BookingTemplate/BookingTemplateEntities";
import { Dispatch } from "../../Dispatch";
import { BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import { AddressAdditionalDetails } from "../BookingEntities";
import BookingContactNumber from "./BookingContactNumber";

interface AddressDetailsProps {
    ContactType: ContactKind;
}

interface PropsFromStore {
    PickupDetails: AddressAdditionalDetails;
    DropoffDetails: AddressAdditionalDetails;
    ActiveBookingForm: BookingFormKind;
}

/** Component is wrapper over 'BookingContactNumber' component to handle both pickup contact and dropoff contact input. 
 * Injecting value, label, isOptional flag, onchange handlers for country code and contact number to BookingContactNumber .
*/
const BookingLocationContactNumberCore: React.FC<AddressDetailsProps & PropsFromStore> = (props) => {
    
    /** Update corresponding store slice on phone number change. */
    function updateContactNumber(number: string) {

        if (props.ContactType === ContactKind.PickUp) {

            const { ErrorMessage, ...NumberPart } = props.PickupDetails.ContactNumber!;

            Dispatch.Booking.PickupContactNumber({ ...NumberPart, Contactnumber: number });
        }
        else {

            const { ErrorMessage, ...NumberPart } = props.DropoffDetails.ContactNumber!;

            Dispatch.Booking.DropoffContactNumber(
                {
                    ...NumberPart,
                    Contactnumber: number,
                }
            );
        }
    }

    /** 
     * Update the store on change of the country flag
     */
    function OnCountryCodeChanged(value: any): void {

        const countryInfo = {
            CountryCode: CheckAddPlus(value.dialCode), CountryName: value.name, CountryIsoCode: value.iso2
        }

        if (props.ContactType === ContactKind.PickUp) {
            Dispatch.Booking.PickupContactNumber({
                ...props.PickupDetails.ContactNumber,
                CountryInfo: countryInfo
            });
        }
        else {
            Dispatch.Booking.DropoffContactNumber({
                ...props.DropoffDetails.ContactNumber,
                CountryInfo: countryInfo
            });
        }
    };

    let IsOptional = props.ContactType === ContactKind.DropOff;
    let contactNumber = props.PickupDetails.ContactNumber ?? null;

    if (props.ContactType === ContactKind.DropOff) contactNumber = props.DropoffDetails.ContactNumber ?? null;

    let label = "Contact number";

    if (props.ActiveBookingForm === BookingFormKind.ParcelBooking) {
        
        //Pickup & Drop-off contact number are optional when new parcel booking widget enable
        IsOptional = true; 

        if (props.ContactType === ContactKind.DropOff) { 
            label = "Pickup contact number"; 
        } else {
            label = "Drop-off contact number";
        }
        
    }

    return (
        <BookingContactNumber
            OnCountryCodeChangeEvent={OnCountryCodeChanged}
            OnNumberChangeEvent={updateContactNumber}
            IsOptional={IsOptional}
            ContactNumberValue={contactNumber}
            Label={label}
        />
    );
}

function MapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        PickupDetails: state.booking.PickupAdditionalDetails,
        DropoffDetails: state.booking.DropoffAdditionalDetails,
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm
    }
}

export const BookingLocationContactNumber = connect(MapStateToProps)(BookingLocationContactNumberCore);