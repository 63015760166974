import React, { Component } from 'react';
import './BookingInputStyling.scss';
import StepControl from "./StepControl";
import { FormStepLabel } from './FormStepLabel';

interface BookingInputStylingProps {
    Title?: FormStepLabel;
}

/**
 * Wrapper for widget on booking form to have uniform styles
 */
class BookingInputStyling extends Component<BookingInputStylingProps> {
    render() {
        return(
            <div className="booking-widget-wrapper">
                {this.props.Title && <div className="booking-widget-wrapper-title">
                    <StepControl StepKind={this.props.Title} />
                </div>}
                <div>{this.props.children}</div>
            </div>
        );
    }
}

export default BookingInputStyling;