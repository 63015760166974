
import { FormatBusinessNumber } from "../../../utils/Formattingutil";
import { Dispatch } from "../../Dispatch";
import { ParseContactNumber } from "../../Utils/PhoneValidation";
import { ParseAndStoreContactNumber } from "../NewBookingTabHelper";

export function ParsePhoneNumber(phoneNumber: string) {
    // Handle legacy users with 1300 and 1800 numbers. Users registered on the Desktop website already have +61.
    phoneNumber = FormatBusinessNumber(phoneNumber);

    const contactNumber = ParseContactNumber(phoneNumber);

    return contactNumber;
} 

function ParseAndStoreDropoffContactNumber(phoneNumber: string) : void {

    const contactNumber = ParsePhoneNumber(phoneNumber);

    // Extract the region code and contact number
    const CountryIsoCode = contactNumber.IsoRegionCode ? contactNumber.IsoRegionCode.toLowerCase() : "";

    Dispatch.Booking.DropoffContactNumber({
        CountryInfo: { CountryIsoCode, CountryCode: contactNumber.CountryCode },
        Contactnumber: contactNumber.NumberPart
    });
}

export function UpdatePickupContactAndName(contactName: string, contactPhone: string) {
    Dispatch.Booking.PickupContactName(contactName);
	ParseAndStoreContactNumber(contactPhone);
}

export function ClearPickupContactAndName() {
   
    Dispatch.Booking.ClearPickupContactName();
   
    Dispatch.Booking.ClearPickupContactNumber();
}

export function UpdateDropoffContactAndName(contactName: string, contactPhone: string) {
    
    Dispatch.Booking.DropoffContactName(contactName);
	ParseAndStoreDropoffContactNumber(contactPhone)
}

export function ClearDropoffContactAndName() {
 
    Dispatch.Booking.ClearDropoffContactName()
 
    Dispatch.Booking.ClearDropoffContactNumber();
}