import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import CardSelector from "../../Cards/CardSelector";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import { CalculateIsBookingOnAccount } from "../NewBookingForm";
import { BookingFormStep } from "../FormSteps/BookingFormStep";

/**
 * Possibly renders the Payment Selection dropdown in the booking form.
 * This only appears when not booking on account.
 */
const MaybeCardSelectorCore: React.FC<ReduxProps> = (props) => {

    if (props.IsBookingOnAccount) return null;

    return (
        <BookingFormStep Label={FormStepLabel.PaymentMethod}>
            <CardSelector />
        </BookingFormStep>
    );
}

// #region Redux

interface ReduxProps {

    /** Whether the user has toggled the "Book on Account" option on. */
    IsBookingOnAccount: boolean;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        IsBookingOnAccount: CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile),
    };
}

export const MaybeCardSelector = connect(GetPropsFromRedux)(MaybeCardSelectorCore);

// #endregion